import { useState } from 'react'
import { InputChangeEventHandler, RFC } from '../../../types/propTypes'
import { useLazyGetPresignedUploadUrlQuery } from '../../../services/FileService'
import axios from 'axios'
import { BinIcon, CameraIcon } from '../../../assets/icons/icons'
import { WHMAsyncImage, WHMAsyncVideo, WHMButton } from '../atoms'
import { FOLDER_TYPE } from '../../../assets/data/enums'

type WHMUploadInputProps = {
    type:     'image' | 'video'
    source:   string
    folder:   FOLDER_TYPE
    onUpload: (file: string | undefined) => void
}

const WHMUploadInput:RFC<WHMUploadInputProps> = ({ 
    type, 
    source, 
    folder, 
    onUpload 
}) => {
    const [ isUploading, setIsUploading ] = useState(false)
    const [ loaded, setLoaded ] = useState(0)
    const [ isUpoadError, setIsUploadError ] = useState(false)
    const [ getPresignedUpload ] = useLazyGetPresignedUploadUrlQuery()

    const handleUpload: InputChangeEventHandler = async ({ target }) => {
        try {
            onUpload('')
            setIsUploadError(false)
            setIsUploading(true)
            const files = target.files
            if (!files) return
            const file = files[0]

            const { data } = await getPresignedUpload({
              fileName: file.name,
              folder,
              rootFolder: type === 'image' ? 'images' : 'videos'
            })
            if (!data) return

            const uploading = await axios.put(data.url, file, {
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress: e => {
                    setLoaded(parseInt(((e.loaded * 100) / e.total).toFixed()))
                    if (e.loaded === e.total) setIsUploading(false)
                }
            })
            if (uploading.status !== 200) throw Error
            onUpload(data.fileName)
        } catch (error) {
            setIsUploadError(true)
        } finally {
            setIsUploading(false)
        }
    }

    const renderThumbnail = () => {
        if (!source) return (
            <div 
                className={`w-full aspect-video rounded-lg shadow-md mb-3 
                bg-WHMDarkWhite h-full relative flex justify-center items-center
                hover:bg-WHMGrey transition-all duration-200 z-0`}
            >   
                {loaded > 0 && loaded < 100 ? (
                    <div 
                        className='absolute text-WHMBlue font-medium text-xl'
                    >
                        {loaded}%
                    </div>
                ) : (
                <>
                    <input 
                        type='file'
                        accept={`{type}/*`}
                        className='absolute w-[100px] z-10 cursor-pointer 
                        file:cursor-pointer opacity-0 peer'
                        onChange={handleUpload}
                        disabled={isUploading}
                    />
                    <WHMButton 
                        text='Upload'
                        className='small-purple-button peer-hover:scale-105'
                        rightIcon={<CameraIcon />}
                        onSubmit={() => {}}
                    />
                </>
                )}
                {loaded < 100 && (
                    <span 
                        className='absolute left-0 h-full bg-WHMPurple rounded-lg -z-10
                        bg-gradient-to-r from-WHMPurple to-WHMLightGrey bg-lg
                        bg-left animate-waving-bg transition-all duration-200'
                        style={{width: `${loaded}%`}}
                    ></span>
                )}
            </div>
        )
        else return (
            <div className='w-full aspect-video rounded-lg shadow-md mb-3'>
                {type === 'image' && (
                    <WHMAsyncImage 
                        fileName={source} 
                        width={330.47}
                        height={185.88}
                    />
                )}
                {type === 'video' && (
                    <WHMAsyncVideo fileName={source} />
                )}
            </div>
        )
    }

    return (
      <div>
        {renderThumbnail()}
        {source && (
          <div className="flex items-center">
            <div className="relative mr-2 hover:scale-105 transition-all duration-200">
              <input
                type="file"
                accept={`${type}/*`}
                className="absolute inset-0 opacity-0 z-50 cursor-pointer file:cursor-pointer"
                onChange={handleUpload}
                disabled={isUploading}
              />
              <WHMButton
                text="Upload"
                className="small-purple-button"
                rightIcon={<CameraIcon />}
                onSubmit={() => {}}
              />
            </div>
            <WHMButton
              text="Delete"
              className="small-light-red-button"
              rightIcon={<BinIcon />}
              onSubmit={() => {
                setLoaded(0);
                onUpload("");
              }}
            />
          </div>
        )}
        {isUpoadError && <div>Failed to upload image</div>}
      </div>
    )
}

export default WHMUploadInput