import { RFC } from '../../../types/propTypes'
import { Listbox, Transition } from '@headlessui/react'
import { DropdownArrow } from '../../../assets/icons/icons'

type WHMFilterDropdownProps = {
    value:   string
    list:    string[]
    onClick: (option: string) => void
}

const WHMFilterDropdown:RFC<WHMFilterDropdownProps> = ({
    value,
    list,
    onClick
}) => {
    
  return (
    <div>
        <Listbox
            value={value}
            onChange={onClick}
        >
        {({ open }) => (
        <div className='px-2 my-2'>
            <div 
            className='w-full relative flex bg-WHMDarkWhite rounded-lg px-3 py-[6px]
            border-WHMBorder border-[1px] border-solid shadow-sm'
            >
                <p className='text-WHMDarkPurple pr-2 border-WHMBorder border-r-[1px] border-solid'>
                    Filter
                </p>
                <Listbox.Button
                    className={`w-full text-start pl-2 ${value === 'Select'
                    ? 'text-WHMDarkGrey'
                    : 'text-WHMDarkPurple'}`
                    }
                >
                    {value}
                </Listbox.Button>
                <DropdownArrow 
                    className={`absolute right-1 transition-all duration-300
                    ${open ? 'rotate-180' : ''}`}
                />
            </div>
            <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Listbox.Options
                    className='border-WHMBorder border-[1px] border-solid rounded-lg p-1'
                    static
                >
                {list.map((item, index) => (
                    <Listbox.Option
                        key={index}
                        value={item}
                    >
                        {({ active, selected }) => (
                            <div
                                className={`px-3 py-1 cursor-pointer rounded-lg ${selected 
                                ? 'bg-WHMPurple text-WHMWhite' 
                                : 'hover:bg-WHMDarkWhite'
                                }`}
                            >
                                {item}
                            </div>
                        )}
                    </Listbox.Option>
                ))}
                </Listbox.Options>
            </Transition>
        </div>
        )}
        </Listbox>
    </div>
  )
}

export default WHMFilterDropdown