import { RFC } from '../../types/propTypes'
import { ExerciseGroup } from '../../types/stateTypes'
import { useWHMDispatch } from '../../app/hooks'
import { 
  updateDefaultReps, updateDefaultSets, updateDeloadingReps, 
  updateDeloadingSets, updateRestTrans, updateTempo, updateWorkOn 
} from '../../features/Training/Workouts/exerciseDetailSlice'
import { alphabet } from '../../assets/data/arrays'
import { WorkoutExerciseCard } from '../atoms/atoms'
import HomeAlternativeDetail from './HomeAlternativeDetail'

type WorkoutExerciseDetailProps = {
    exerciseGroup: ExerciseGroup
}

const WorkoutExerciseDetail:RFC<WorkoutExerciseDetailProps> = ({ exerciseGroup }) => {
  const { exerciseGroupInformation, orderIndex } = exerciseGroup
  const dispatch = useWHMDispatch()

  return (
    <div 
      className='w-full h-screen overflow-y-auto overflow-x-hidden
      px-2 pb-[17em] bg-[#EEE8F2] rounded-[10px]'
    >
      {exerciseGroupInformation.map((exercise, index) => (
      <div 
        className='px-2'
        key={index}
      >
        <div className='flex items-center py-4 text-2xl text-WHMDarkPurple'>
          <p className='font-bold mr-4'>
          {alphabet[orderIndex]}
          {exerciseGroupInformation.length > 1 ? index + 1 : ''} 
          </p>
          <p className='font-medium'>
          {exercise.exercise.title}
          </p>
        </div>
        <WorkoutExerciseCard 
          title='Default'
          desc='Default or the original WHM recommended sets &amp; reps for Gym and Home. (Repetition: e.g. 10 or 8-10)'
          values={exercise}
          onChangeDefaultSets={(text) => dispatch(updateDefaultSets({ value: text, exercise }))}
          onChangeDefaultReps={(text) => dispatch(updateDefaultReps({ value: text, exercise }))}
        />
        <WorkoutExerciseCard 
          title='Deloading'
          desc='Deloading or Rest settings are set for when users turn on either R&amp;R mode or Deloading. (Repetition: e.g. 10 or 8-10)'
          values={exercise}
          onChangeDeloadingSets={(text) => dispatch(updateDeloadingSets({ value: text, exercise }))}
          onchangeDeloadingReps={(text) => dispatch(updateDeloadingReps({ value: text, exercise }))}
        />
        <WorkoutExerciseCard 
          title='Work, Transition and Tempo'
          desc='Set the work and transition time periods for this exercise. This is set in seconds.'
          values={exercise}
          onChangeWork={(text) => dispatch(updateWorkOn({ value: text, exercise }))}
          onChangeRest={(text) => dispatch(updateRestTrans({ value: text, exercise }))}
          onChangeTempo={(text) => dispatch(updateTempo({ value: text, exercise }))}
        />
        <HomeAlternativeDetail 
          exerciseId={exercise.id}
          homeAlternative={exercise?.exerciseHomeAlternative}
        />
      </div>
      ))}
    </div>
  )
}

export default WorkoutExerciseDetail