import { RFC } from '../../types/propTypes'

type DashboardDetailsProps = {
  template: string
  children: JSX.Element
}

const DashboardDetails:RFC<DashboardDetailsProps> = ({ template, children }) => {
  return (
    <div
      className={`w-full h-screen grid ${template} pb-32 `}
    >
      { children }
    </div>
  )
}

export default DashboardDetails