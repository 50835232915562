import { RFC } from '../../../types/propTypes'

type WHMStatsBlockProps = {
    title: string
    children: JSX.Element
}

const WHMStatsBlock:RFC<WHMStatsBlockProps> = ({ title, children }) => {
    return (
        <div>
            <p className='font-bold'>
                {title}
            </p>
            <div className='w-full max-w-[20em] border-WHMBorder border-[1px] border-solid p-2 rounded-[10px]'>
                { children }
            </div>
        </div>
    )
}

export default WHMStatsBlock