import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import FeedbackUsers from '../../../features/Notice/ProgramFeedback/FeedbackUsers'

const ProgramFeedbackList = () => {
    const [ searchText, setSearchText ] = useState('')
    const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

    return (
        <>
            <WHMTitleBlock title='Program Feedback' />
            <WHMSearchbar onChange={onChangeText} />
            <FeedbackUsers searchText={searchText} />
        </>
    )
}

export default ProgramFeedbackList