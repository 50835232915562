import { RFC } from '../../../types/propTypes'
import WHMLoading from '../WHMLoading'

type StatsCardProps = {
    label:         string
    unit?:         string
    resultTitle?:  string
    resultNumber?: string | number
    isLoading:     boolean
}

const StatsCard:RFC<StatsCardProps> = ({
    label,
    unit,
    resultTitle,
    resultNumber,
    isLoading,
}) => {

    return (
        <div 
            className='w-[12em] h-[10em] border-WHMBorder border-solid border-[1px] rounded-[10px]
            flex flex-col items-center justify-center text-center px-3 pb-4 mr-3'
        >
            {isLoading ? (
                <WHMLoading shape='circle'/>
            ) : (
                <p 
                    className={`text-WHMDarkPurple/80
                    ${resultTitle ? 'font-bold text-[1.6em]' : 'font-block text-[3em]'}`}
                >
                    {resultTitle ?? resultNumber}
                    {unit && unit}
                </p>
            )}
            <p className='text-lg max-h-8'>
                {label}
            </p>
        </div>
    )
}

export default StatsCard
