import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialUsersState } from '../../../assets/data/InitialState'
import { UsersData } from '../../../types/serviceTypes'

export type UsersSliceType = {
    users: UsersData
}

export const usersSlice = createSlice({
    name: 'users',
    initialState: InitialUsersState,
    reducers: {
        clearUsers: (state) => {
            state.users.data = []
        },
        setInitialUsers: (state, action:PayloadAction<UsersSliceType>) => {
            state.users.data = action.payload.users.data
        },
        setUsers: (state, action:PayloadAction<UsersSliceType>) => {
            const { data: storedUsers } = state.users
            const { data: newUsers } = action.payload.users
            const userIds = storedUsers.map(user => user.id)
            const filteredDuplicates = newUsers.filter(user => !userIds.includes(user.id))
            filteredDuplicates.map(user => state.users.data.push(user)) 
        }
    }
})

export const usersSelector = (state: RootState) => state.users
export const {
    clearUsers,
    setInitialUsers,
    setUsers,
} = usersSlice.actions
export default usersSlice.reducer