import { useEffect } from 'react'
import { RFC } from '../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../app/hooks'
import { addAlternativeExercise, exerciseSelector } from '../../features/Training/Exercises/exerciseSlice'
import { useLazyGetExerciseQuery } from '../../services/ExerciseService'
import { ExerciseAlternative } from '../../types/stateTypes'
import { WHMButton } from '../atoms/atoms'
import { DraggableBinIcon } from '../../assets/icons/icons'
import { InputSkeleton } from './molecules'

type NonDndListFieldProps = {
  list: ExerciseAlternative[] | undefined
  removeItem: (exerciseId: string) => void
  openModal:  () => void
}

const NonDndListField:RFC<NonDndListFieldProps> = ({
  list,
  removeItem,
  openModal
}) => {
  const { alternatives } = useWHMSelector(exerciseSelector)
  const dispatch = useWHMDispatch()
  const [ getExercise, { isLoading } ] = useLazyGetExerciseQuery()

  useEffect(() => {
    if (!list) return
    list.map(alternative => getExerciseAlternative(alternative))
  },[list])

  const getExerciseAlternative = async (alternative:ExerciseAlternative) => {
    const data = await getExercise(alternative.alternativeExerciseId).unwrap()
    if (!data) return
    dispatch(addAlternativeExercise(data))
  }

  return (
    <div className='w-full'>
      <div className='mb-3'>
        {alternatives?.map((item, index) => isLoading ? (
        <InputSkeleton key={index}/>
        ) : (
        <div className='flex items-center justify-between' key={index}>
          <div className='flex w-full items-center'>
            <p className='w-[1em] text-2xl font-semibold text-WHMDarkPurple italic'>
              {index + 1}
            </p>
            <div
              key={index}
              className='w-full bg-WHMDarkPurple/40 rounded-[5px] text-WHMWhite
              px-2 py-[6px] my-1'
            >
              {item.title}
            </div>
          </div>
          <DraggableBinIcon 
            className='cursor-pointer'
            onClick={() => removeItem(item.id)}
          />
        </div>
        ))}
      </div>
      <WHMButton 
        text='Add Alternative'
        className='blue-button'
        onSubmit={openModal}
      />
    </div>
  )
}

export default NonDndListField