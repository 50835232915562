import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { reportSelector, selectReport } from '../../../features/Community/Reports/reportSlice'
import { reportsSelector } from '../../../features/Community/Reports/reportsSlice'
import { domAnimation, LazyMotion } from 'framer-motion'
import { CardError, CardLogo, TitleCard } from '../../atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type ReportTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

const ReportTitles:RFC<ReportTitlesProps> = ({
    isLoading,
    isError,
}) => {
    const reportState = useWHMSelector(reportSelector)
    const reportsState = useWHMSelector(reportsSelector)
    const dispatch = useWHMDispatch()

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!reportsState.reports.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Report
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='report_card'>
            {reportsState.reports.data.map((item, index) => (
                <TitleCard 
                    key={index}
                    isSelected={
                        (reportState.report.postId === item.postId) && 
                        (reportState.report.userId === item.userId) 
                    }
                    onClick={() => dispatch(selectReport({ id: item.postId, report: item }))}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <p className='truncate'>
                                <span className='font-semibold'>
                                    Reporter ● &nbsp;
                                </span>
                                <span className='opacity-60'>
                                    {item.user.firstName} {item.user.lastName}
                                </span>
                            </p>
                            <p className='line-clamp-2 font-bold text-xl my-1'>
                                {item.post.description}
                            </p>
                            <p className='truncate'>
                                <span className='font-semibold opacity-100'>
                                    Subject ● &nbsp;
                                </span>
                                <span className='opacity-60'>
                                    {item.reportName}
                                </span>
                            </p>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default ReportTitles