import { useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { AvatarPlaceholder, ErrorIcon } from '../../../assets/icons/icons'

type WHMAsyncAvatarProps = {
  fileName: string | undefined
}

const WHMAsyncAvatar: RFC<WHMAsyncAvatarProps> = ({ fileName }) => {
    const [ isAvatarError, setIsAvatarError ] = useState(false)

    if (!fileName) return (
      <AvatarPlaceholder
        width={48}
        height={48}
        className={`overflow-hidden relative w-10 h-10 bg-gray-100 
        rounded-full dark:bg-gray-300 shadow-lg`}
      />
    )

    if (isAvatarError) return (
      <ErrorIcon width={'30px'} height={'30px'} />
    )
    
    return (
        <img 
            className='w-10 h-10 rounded-full'
            src={fileName}
            alt='avatar'
            onError={() => setIsAvatarError(true)}
        />
    )
}

export default WHMAsyncAvatar