const ImageSkeleton = () => {
    return (
        <div
            role='status'
            className='w-full animate-pulse'
        >
            <div className='h-40 bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey w-full'></div>
        </div>
    )
}

export default ImageSkeleton