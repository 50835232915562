import { WHMApi } from '../app/api'
import { FileDownloadArgs, FileDownloadData, FileResizeDownloadArgs, FileUploadArgs, FileUploadData } from '../types/serviceTypes'

export const fileApi = WHMApi.injectEndpoints({
    endpoints: (builder) => (({
        getPresignedUploadUrl: builder.query<FileUploadData, FileUploadArgs>({
            query: ({ fileName, folder, rootFolder }) => ({
                url: `files/upload/${rootFolder}`,
                params: { fileName, folder }
            })
        }),
        getPresignedResizeDownloadURL: builder.query<FileDownloadData, FileResizeDownloadArgs>({
            query: ({ fileName, width, height }) => ({
                url: '/files/download/resize',
                params: { fileName, width, height }
            })
        }),
        getPresignedDownloadURL: builder.query<FileDownloadData, FileDownloadArgs>({
            query: ({ fileName }) => ({
                url: '/files/download',
                params: { fileName }
            })
        })
    }))
})

export const {
    useLazyGetPresignedUploadUrlQuery,
    useLazyGetPresignedResizeDownloadURLQuery,
    useLazyGetPresignedDownloadURLQuery,
} = fileApi