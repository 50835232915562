import { RFC } from '../../../types/propTypes'
import { Course } from '../../../types/stateTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { courseSelector, selectCourse } from '../../../features/Resources/Courses/courseSlice'
import { CardError, TitleCard, WHMStatus, CardLogo } from '../../atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { coursesSelector } from '../../../features/Resources/Courses/coursesSlice'
import { domAnimation, LazyMotion } from 'framer-motion'

type CourseTitlesProps = {
    isLoading: boolean
    isError:   boolean
    onRemoveNew: () => void
}

const CourseTitles:RFC<CourseTitlesProps> = ({ 
    isLoading,
    isError, 
    onRemoveNew
}) => {
    const courseState = useWHMSelector(courseSelector)
    const coursesState = useWHMSelector(coursesSelector)
    const dispatch = useWHMDispatch()

    const handleClickCard = (item: Course) => {
        onRemoveNew()
        dispatch(selectCourse({ id: item.id, course: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!coursesState.courses.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Course
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='course_card'>
            {coursesState.courses.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={courseState.id === item?.id}
                    onClick={() => handleClickCard(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <p className='truncate text-2xl font-bold'>{item.title}</p>
                            <div className='flex items-center'>
                                <WHMStatus status={item.isActive}/>
                                <p className='truncate '>{item.focus}</p>
                            </div>
                        </div>
                        <ArrowRightIcon 
                        width={18}
                        height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default CourseTitles