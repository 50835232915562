import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialAlternativesState } from '../../../assets/data/InitialState'
import { ExercisesData } from '../../../types/serviceTypes'

export type AlternativesSliceType = {
    exercises: ExercisesData
}

export const alternativesSlice = createSlice({
    name: 'exercises',
    initialState: InitialAlternativesState,
    reducers: {
        setInitialAlternatives: (state, action:PayloadAction<AlternativesSliceType>) => {
            state.exercises.data = action.payload.exercises.data
        },
        setAlternatives: (state, action:PayloadAction<AlternativesSliceType>) => {
            const { data: storedExercises } = state.exercises
            const { data: newExercises } = action.payload.exercises
            const exerciseIds = storedExercises.map(exercise => exercise.id)
            const filteredDuplicates = newExercises.filter(exercise => !exerciseIds.includes(exercise.id))
            filteredDuplicates.map(exercise => state.exercises.data.push(exercise))
        }
    }
})

export const alternativesSelector = (state: RootState) => state.alternatives
export const {
    setInitialAlternatives,
    setAlternatives,
} = alternativesSlice.actions
export default alternativesSlice.reducer