import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearProgram, programSelector } from './programSlice'
import { useEffect } from 'react'
import { NewCard, WHMStatus } from '../../../components/atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { AnimatePresence } from 'framer-motion'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'

type ProgramsTitlesProps = {
  isNewProgram: boolean
  searchText:   string
  onRemoveNew:  () => void
}

const ProgramsTitles:RFC<ProgramsTitlesProps> = ({
  isNewProgram,
  searchText,
  onRemoveNew
}) => {
  const programState = useWHMSelector(programSelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    dispatch(clearProgram())
  },[isNewProgram])

  const renderAddNewCard = () => {
    return (
      <NewCard>
        <div className='flex items-center justify-between'>
          <div className='max-w-[90%]'>
            <p className='truncate text-2xl font-bold'>{programState.program.title ?? 'New Program'}</p>
            <div className='flex items-center'>
              <WHMStatus status={false}/>
              <div className='truncate '>
                {!programState.program.programSuggestGoal?.length ? (<p>{'NA'}</p>) : (
                  programState?.program?.programSuggestGoal?.map((goal, index) => (
                    <p key={index}>
                      {goal.suggestGoalName ?? 'NA'}
                    </p>
                  ))
                )}
              </div>
            </div>
          </div>
          <ArrowRightIcon 
            width={18}
            height={18}
          />
        </div>
      </NewCard>
    )
  }

  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
        { isNewProgram && renderAddNewCard() }
        <InfiniteScrollList 
          key='infinite_scroll'
          page={ALL_TITLES.PROGRAM}
          searchText={searchText}
          onRemoveNew={onRemoveNew}
        />
      </AnimatePresence>
    </div>
  )
}

export default ProgramsTitles