import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialUserState } from '../../../assets/data/InitialState'
import { UserData } from '../../../types/serviceTypes'

export type UserSliceType = {
    id:         string
    currentTab: string
    user:       UserData
}

export const userSlice = createSlice({
    name: 'user',
    initialState: InitialUserState,
    reducers: {
        jumpToUserAbout: (state, action:PayloadAction<string>) => {
            state.id         = action.payload
            state.currentTab = 'About'
        },
        jumpToUserFeedBack: (state, action:PayloadAction<string>) => {
            state.id         = action.payload
            state.currentTab = 'Feedback'
        },
        jumpToUserTickets: (state, action:PayloadAction<string>) => {
            state.id = action.payload
            state.currentTab = 'Tickets'
        },
        selectUser: (state, action:PayloadAction<UserSliceType>) => {
            state.id         = action.payload.id
            state.currentTab = action.payload.currentTab
            state.user       = action.payload.user
        },
        clearUser: (state) => {
            state.id         = ''
            state.currentTab = 'About'
            state.user       = { id: '' }
        },
        selectTab: (state, action:PayloadAction<string>) => {
            state.currentTab = action.payload
        }
    }
})

export const userSelector = (state: RootState) => state.user
export const {
    jumpToUserAbout,
    jumpToUserFeedBack,
    jumpToUserTickets,
    selectUser,
    clearUser,
    selectTab,
} = userSlice.actions
export default userSlice.reducer