import { Dialog } from '@headlessui/react'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { ArrowLeftIcon } from '../../../assets/icons/icons'
import { closeModal, modalSelector } from '../../../features/Modal/modalSlice'
import { WHMButton } from '../../atoms/atoms'

const DeleteModal = () => {
  const { deleteId, deleteFn } = useWHMSelector(modalSelector)
  const dispatch = useWHMDispatch()

  return (
    <div className='w-[23em]'>
      <Dialog.Title className='w-full flex items-center justify-between'>
        <div
          className='w-full flex items-center justify-center relative'
        >
          <div
            className='absolute left-0 cursor-pointer hover:scale-110 transition-all duration-200'
            onClick={() => dispatch(closeModal())}
          >
            <ArrowLeftIcon />
          </div>
          <p className='w-full text-2xl font-bold text-center'>
          Confirm Delete
          </p>
        </div>
      </Dialog.Title>
      <hr className='whm-divider'/>
      <Dialog.Description>
        <p className='text-lg m-3'>
        Are you sure you want to delete this from WHM? Once this has been deleted, it can not be undone.
        </p>
      </Dialog.Description>
      <WHMButton 
        text='Yes, delete this'
        className='blue-button'
        onSubmit={() => deleteFn && deleteFn(deleteId ?? '')}
      />
      <WHMButton 
        text='No, go back'
        className='red-outlined-button mt-3'
        onSubmit={() => dispatch(closeModal())}
      />
    </div>
  )
}

export default DeleteModal