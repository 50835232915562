import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import GroupsTitles from '../../../features/Community/Groups/GroupsTitles'

type GroupsListProps = {
  isNewGroup: boolean
  setIsNewGroup: (isOpen: boolean) => void
}

const GroupsList:RFC<GroupsListProps> = ({
  isNewGroup,
  setIsNewGroup
}) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock 
        title='Groups'
        btnTitle='New'
        onClick={() => setIsNewGroup(!isNewGroup)}
      />
      <WHMSearchbar onChange={onChangeText}/>
      <GroupsTitles 
        isNewGroup={isNewGroup}
        searchText={searchText}
        onRemoveNew={() => setIsNewGroup(false)}
      />
    </>
  )
}

export default GroupsList