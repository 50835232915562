import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearExercise, exerciseSelector } from './exerciseSlice'
import { AnimatePresence } from 'framer-motion'
import { ALL_TITLES } from '../../../assets/data/enums'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { NewCard } from '../../../components/atoms/atoms'
import { InfiniteScrollList } from '../../../components/organisms/organisms'

type ExercisesTitlesProps = {
  isNewExercise: boolean
  searchText:    string
  selectedFocus: string
  onRemoveNew:   () => void
}

const ExercisesTitles:RFC<ExercisesTitlesProps> = ({
  isNewExercise,
  searchText,
  selectedFocus,
  onRemoveNew
}) => {
  const exerciseState = useWHMSelector(exerciseSelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    dispatch(clearExercise())
  },[isNewExercise])

  const renderAddNewCard = () => {
    return (
      <NewCard>
        <div className="flex items-center justify-between">
          <div className="max-w-[90%]">
            <p className="truncate text-2xl font-bold">
              {exerciseState.exercise.title ?? 'New Exercise'}
            </p>
            <div className="flex items-center">
              <p>Focus</p>
              <div className='flex items-center placeholder:truncate truncate'>
                {!exerciseState.focus?.length ? (
                <p className='mx-1'>none</p>
                ) : (
                exerciseState.focus.map((focus, index) => (
                  <div key={index} className='flex'>
                    <p className='mx-1'>
                        {focus}
                    </p>
                  </div>
                )))}
              </div>
            </div>
          </div>
          <ArrowRightIcon 
            width={18} 
            height={18} 
          />
        </div>
      </NewCard>
    )
  }

  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
        { isNewExercise && renderAddNewCard() }
        <InfiniteScrollList 
          key='infinite_scroll'
          page={ALL_TITLES.EXERCISES}
          searchText={searchText}
          category={selectedFocus}
          pb={true}
          onRemoveNew={onRemoveNew}
        />
      </AnimatePresence>
    </div>
  )
}

export default ExercisesTitles