import moment from 'moment'
import { RFC } from '../../types/propTypes'
import WHMRating from '../atoms/WHMRating'

type FeedbackFieldProps = {
    title:               string | undefined
    completedAt:         Date   | undefined
    programRate:         number | undefined
    programFeedback:     string | undefined
    consistentRate:      number | undefined
    challengingFeedback: string | undefined
    trainingFeedback:    string | undefined
}

const FeedbackField:RFC<FeedbackFieldProps> = ({
    title,
    completedAt,
    programRate,         
    programFeedback,     
    consistentRate,      
    challengingFeedback, 
    trainingFeedback,    
}) => {
    const completedDate = moment(completedAt).format('DD-MM-YYYY')
    
    return (
      <div
        className="border-[1px] border-solid border-WHMBorder 
      rounded-[10px] py-2 px-3 "
      >
        <div className="w-full mb-3">
          <p className="text-WHMDarkPurple/80 text-xl font-bold">{title}</p>
          <p className="flex items-center font-medium">
            Completed on:
            <span className="ml-3 text-WHMDark/90">{completedDate}</span>
          </p>
        </div>
        <div className="w-full mb-5">
          <p className="font-semibold mb-1">
            Q1 How would you rate this program?
          </p>
          <WHMRating rating={programRate} />
        </div>
        <div className="w-full mb-5">
          <p className="font-semibold mb-1">
            Q2 What did you enjoy most about this training program?
          </p>
          <p className="text-WHMDark/90">{programFeedback}</p>
        </div>
        <div className="w-full mb-5">
          <p className="font-semibold mb-1">
            Q3 How consistent and compliant to the exact training program were
            you?
          </p>
          <WHMRating rating={consistentRate} />
        </div>
        <div className="w-full mb-5">
          <p className="font-semibold mb-1">
            Q4 Is there anything you found overly challenging?
          </p>
          <p className="text-WHMDark/90">{challengingFeedback}</p>
        </div>
        <div className="w-full mb-5">
          <p className="font-semibold mb-1">
            Q5 What did you enjoy the least about the program?
          </p>
          <p className="text-WHMDark/90">{trainingFeedback}</p>
        </div>
      </div>
    )
}

export default FeedbackField