import { DashboardType, RFC } from '../../types/propTypes'
import { CardError, MenuCard, WHMTitleBlock } from '../atoms/atoms'

type DashboardTitlesProps = Pick<DashboardType, 'menu'> & {
    title:       string
    currentPage: string
    onClick:     (titlePage: string) => void
}

const DashboardTitles:RFC<DashboardTitlesProps> = ({ 
    title, 
    menu, 
    currentPage,
    onClick 
}) => {

    const renderBoardTitles = () => {
        if (!menu) return <CardError />
        return (
            <div className='w-full'>
                {menu?.map((m, index) => (
                    <MenuCard 
                        key={index}
                        title={m.title}
                        isSelected={currentPage === m.titlePage}
                        onClick={() => onClick(m.titlePage)}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            <WHMTitleBlock title={title}/>
            { renderBoardTitles() }
        </>
    )
}

export default DashboardTitles