import { RFC } from '../../types/propTypes'
import { MarkedStarIcon, UnmarkedStarIcon } from '../../assets/icons/icons'

type WHMRatingProps = {
    rating: number | undefined
}

const WHMRating:RFC<WHMRatingProps> = ({ rating }) => {

    if (!rating) return (
        <div>No Rates</div>
    )

    const renderMarkedStars = () => {
        const marked = rating
        return Array(marked).fill('').map((_, index) => (
            <MarkedStarIcon key={index}/>
        ))
    }

    const renderUnmarkedStars = () => {
        const unmarked = 5 - rating
        return Array(unmarked).fill('').map((_, index) => (
            <UnmarkedStarIcon key={index}/>
        ))
    }

    return (
        <div className='flex items-center justify-between w-[8em]'>
            { renderMarkedStars() }
            { renderUnmarkedStars() }
        </div>
    )
}

export default WHMRating