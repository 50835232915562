import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { selectUser, userSelector } from '../../../features/Users/Users/userSlice'
import { usersSelector } from '../../../features/Users/Users/usersSlice'
import { CardError, CardLogo, TitleCard, WHMAsyncAvatar } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type UserTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

const UserTitles:RFC<UserTitlesProps> = ({
    isLoading,
    isError,
}) => {
    const { id, currentTab } = useWHMSelector(userSelector)
    const { users } = useWHMSelector(usersSelector)
    const dispatch = useWHMDispatch()

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!users.data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No User
    </div>
    )
    
    return (
        <LazyMotion features={domAnimation} key='user_card'>
            {users.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={id === item.id}
                    onClick={() => dispatch(selectUser({ id: item.id, currentTab, user: item }))}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <WHMAsyncAvatar fileName={item.avatar} />
                            <div>
                            <p className="text-xl font-bold">
                                {item.firstName}{' '}
                                {item.lastName}
                            </p>
                            <p>
                                {item.userSubscriptionInformation.subscriptionId 
                                ? 'Subscribed'
                                : 'Not Subscribed'}
                            </p>
                            </div>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default UserTitles
