import { RFC } from '../../../types/propTypes'

type WHMDetailTitleProps = {
    title:       string
    details:     string
    margin?:     string
    isRequired?: boolean
}

const WHMDetailTitle:RFC<WHMDetailTitleProps> = ({ 
    title, 
    details, 
    margin, 
    isRequired 
}) => {
    return (
        <div className={`w-full ${margin}`}>
            <p className='font-semibold text-WHMBlack'>
                {title}
                {isRequired && (
                    <span className='text-WHMRed'>*</span>
                )}
            </p>
            <p className='text-[0.9em] text-WHMBlack/80'>
                {details}
            </p>
        </div>
    )
}

export default WHMDetailTitle