import { WHMApi } from '../app/api'
import { HelpTicketsData, PaginationPayload, ReplyTicketArgs } from '../types/serviceTypes'
import { HelpTicket } from '../types/stateTypes'

export const helpTicketApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getHelpTickets: builder.query<HelpTicketsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/supports',
                params: { currentPage, perPage, filter, isReplied: 0 }
            }),
            providesTags: ['Help']
        }),
        replyTicket: builder.mutation<HelpTicket, ReplyTicketArgs>({
            query: ({ userSupportId, reply }) => ({
                url: `/admin/supports/${userSupportId}`,
                method: 'PUT',
                body: { reply }
            })
        })
    })
})

export const {
    useLazyGetHelpTicketsQuery,
    useReplyTicketMutation,
} = helpTicketApi