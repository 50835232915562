import { RFC } from '../../types/propTypes'
import { useNavigate } from 'react-router-dom'
import { useWHMDispatch } from '../../app/hooks'
import { navbarTabs } from '../../assets/data/arrays'
import { PurpleLogo } from '../../assets/images/images'
import { LogoutIcon } from '../../assets/icons/icons'
import { logout } from '../../features/Auth/authSlice'

type NavbarProps = {
    page: 'notice' | 'training' | 'resources' | 'community' | 'users'
}

const Navbar:RFC<NavbarProps> = ({ page }) => {
    const navigate = useNavigate()
    const dispatch = useWHMDispatch()

    const renderNavbarIcon = () => {
        return (
            <>
                {navbarTabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`navbar-icon-box 
                            ${page === tab.page ? 'navbar-icon-selected' : ''}`
                        }
                        onClick={() => navigate(tab.link)}
                    >
                        <tab.icon 
                            width='30'
                            height='30'
                        />
                    </div>
                ))}
            </>
        )
    }

    const handleLogout = () => {
        // dispatch(logout())
        localStorage.removeItem('WHMAdminToken')
        navigate('/login')
    }

    return (
        <div 
            className='w-screen h-[5em] bg-WHMLightPurple 
            flex justify-between items-center'
        >
            <div className='ml-8'>
                <img src={PurpleLogo} alt='navbar' className='w-auto h-auto max-h-[100px] max-w-[70px]'/>
            </div>
            <div className='w-full h-full relative flex flex-row justify-center items-center'>
                { renderNavbarIcon() }
            </div>
            <div 
                className='flex items-center font-bold text-WHMDarkPurple text-lg 
                mr-4 p-4 rounded-lg cursor-pointer fill-[#5e257d] hover:fill-WHMWhite
                hover:bg-WHMDarkPurple/80 hover:text-WHMWhite transition-all duration-300'
                onClick={handleLogout}
            >
                Logout
                <LogoutIcon className='ml-2'/>
            </div>
        </div>
    )
}

export default Navbar