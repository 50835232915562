import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('WHMAdminToken')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 })

export const WHMApi = createApi({
    reducerPath: 'WHMApi',
    baseQuery: baseQueryWithRetry,
    tagTypes: [ 
        'Feedback', 'Help', 'Program', 'Workout', 'Exercise', 
        'Course', 'Lesson', 'Report', 'Group', 'User'
    ],
    endpoints: () => ({})
})