import { useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { AddIcon, RemoveIcon } from '../../../assets/icons/icons'

type WHMExerciseInputProps = {
    type:        string
    list:        string[] | undefined
    placeholder: string
    onAddNewItem: (name: string) => void
    onRemoveItem: (name: string) => void
}

const WHMExerciseInput:RFC<WHMExerciseInputProps> = ({
    type,
    list,
    placeholder,
    onAddNewItem,
    onRemoveItem
}) => {
    const [ exerciseTitle, setExerciseTitle ] = useState('')

    return (
      <div className="w-full">
        <div className="w-full">
          {list?.map((title, index) => (
            <div
              key={index}
              className="w-full flex items-center justify-between rounded-[5px] font-medium
                        bg-WHMDarkPurple/40 text-WHMWhite pl-3 pr-1 py-1 my-1 italic"
            >
              <div className="flex">
                {type === "cue" && (
                    <p className='mr-1 h-full'>{index + 1}.</p>
                )}
                <p className="break-words">
                  {title}
                </p>
              </div>
              <div
                className="mr-1 cursor-pointer"
                onClick={() => onRemoveItem(title)}
              >
                <RemoveIcon />
              </div>
            </div>
          ))}
        </div>

        <div
          className="flex items-center justify-between h-9 w-full 
                drop-shadow-sm border-[1px] border-WHMBorder border-solid
                rounded-[5px]"
        >
          <input
            type="text"
            value={exerciseTitle}
            placeholder={placeholder}
            onChange={(e) => setExerciseTitle(e.target.value)}
            className="w-full h-full outline-none rounded-[5px] 
                    px-2 py-4 italic text-WHMDarkPurple"
          />
          <div
            className="mr-1 cursor-pointer"
            onClick={() => {
              exerciseTitle && onAddNewItem(exerciseTitle);
              setExerciseTitle("");
            }}
          >
            <AddIcon />
          </div>
        </div>
      </div>
    );
}

export default WHMExerciseInput