import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { programSelector, updateProgramDescription, updateProgramImage, updateProgramTitle } from '../../../features/Training/Programs/programSlice'
import { AnimatePresence, motion } from 'framer-motion'
import DashboardDetails from '../../templates/DashboardDetails'
import { WHMDetailInput, WHMDetailTextArea, WHMDetailTitle, WHMUploadInput, WHMStatsBlock } from '../../atoms/atoms'
import { ImageSkeleton, InputSkeleton, TextAreaSkeleton } from '../../molecules/molecules'
import { FOLDER_TYPE } from '../../../assets/data/enums'
import ProgramStats from '../../../features/Training/Programs/ProgramStats'

type AboutProgramTabProps = {
  isNewProgram: boolean | undefined
  isLoading:    boolean
}

const AboutProgramTab:RFC<AboutProgramTabProps> = ({
  isNewProgram,
  isLoading
}) => {
  const programState = useWHMSelector(programSelector)
  const { id, title, description, image } = programState.program
  const dispatch = useWHMDispatch()

  return (
    <AnimatePresence>
      <motion.div
        key='About'
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <DashboardDetails template='grid-cols-3sm'>
        <>
          {/* Program About Tab Left Side */}
          <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4 mt-2'>
            <div className='pb-4'>
              <WHMDetailTitle 
                title='Title'
                details='Add a title that describes your program.'
                margin='mb-1'
                isRequired={isNewProgram ? true : false}
              />
              {isLoading ? (<InputSkeleton />) : (
                <WHMDetailInput 
                  placeholder='Title'
                  value={title ?? ''}
                  onChange={(text) => dispatch(updateProgramTitle(text))}
                />
              )}
            </div>

            <div className='pb-4'>
              <WHMDetailTitle 
                title='Description'
                details='Add a description to this program to help users understand what this program will achieve.'
                margin='mb-1'
                isRequired={isNewProgram ? true : false}
              />
              {isLoading ? (<TextAreaSkeleton />) : (
                <WHMDetailTextArea 
                  placeholder='Add Description'
                  value={description ?? ''}
                  onChange={(text) => dispatch(updateProgramDescription(text))}
                />
              )}
            </div>
          </div>

          {/* Program About Middle */}
          <div className='h-full px-3 pb-4 mt-2'>
            <WHMDetailTitle 
              title='Image'
              details='Upload an image for this program’s thumbnail card.'
              margin='mb-1'
            />
            {isLoading ? (<ImageSkeleton />) : (
              <WHMUploadInput 
                type='image'
                source={image ?? ''}
                folder={FOLDER_TYPE.PROGRAM_IMAGE}
                onUpload={(fileName) => dispatch(updateProgramImage(fileName ?? ''))}
              />
            )}
          </div>

          {/* Program About Right Side */}
          <WHMStatsBlock title='Program Stats'>
            <ProgramStats programId={id ?? ''}/>
          </WHMStatsBlock>
        </>
        </DashboardDetails>
      </motion.div>
    </AnimatePresence>
  )
}

export default AboutProgramTab