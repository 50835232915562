import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../app/store'
import { InitialGroupState } from '../../../assets/data/InitialState'
import { PostsData } from '../../../types/serviceTypes'
import { Group, Post } from '../../../types/stateTypes'

export type GroupSliceType = {
    group: Group
    posts: PostsData
    post:  Post
}

export const groupSlice = createSlice({
    name: 'group',
    initialState: InitialGroupState,
    reducers: {
        selectGroup: (state, action:PayloadAction<Group>) => {
            state.group = action.payload
        },
        clearGroup: (state) => {
            state.group = {
                isActive: 0,
                name:     '',
            }
            state.posts = {
                data:       [],
                pagination: {}
            }
        },
        clearPosts: (state) => {
            state.posts = {
                data:       [],
                pagination: {}
            }
        },
        clearGroupPost: (state) => {
            state.post = {
                author: {
                    avatar:               '',
                    birthday:             new Date(),
                    createdAt:            new Date(),
                    deletedUserAccountId: '',
                    email:                '',
                    facebook:             '',
                    firstName:            '',
                    id:                   '',
                    instagram:            '',
                    lastName:             '',
                    role:                 '',
                    tiktok:               '',
                    userMobileId:         ''
                },
                authorId:            '',
                commentCount:        0,
                createdAt:           new Date(),
                description:         '',
                id:                  '',
                likeCount:           0,
                postGroup:           [],
                userWorkoutRecord:   '',
                userWorkoutRecordId: '',
            }
        },
        updateGroupActive: (state, action:PayloadAction<number>) => {
            state.group.isActive = action.payload
        },
        clearGroupPosts: (state) => {
            state.posts.data = []
        },
        setInitialGroupPosts: (state, action:PayloadAction<PostsData>) => {
            const updatedOrders = action.payload.data.slice().sort((a, b) =>
                parseInt(moment(b.createdAt).format('X')) - parseInt(moment(a.createdAt).format('X'))
            )
            state.posts.data = updatedOrders
        },
        setGroupPosts: (state, action:PayloadAction<PostsData>) => {
            const { data: storedPosts } = state.posts
            const { data: newPosts } = action.payload
            const postIds = storedPosts.map(post => post.id)
            const filteredDuplicates = newPosts.filter(post => !postIds.includes(post.id))
            const updatedOrders = filteredDuplicates.slice().sort((a, b) => 
                parseInt(moment(b.createdAt).format('X')) - parseInt(moment(a.createdAt).format('X'))
            )
            updatedOrders.map(post => state.posts.data.push(post))
        },
        setGroupPost: (state, action:PayloadAction<Post>) => {
            state.post = action.payload
        },
        deleteGroupPostWithId: (state, action:PayloadAction<string>) => {
            const filteredGroupPosts = state.posts.data.filter(post => post.id !== action.payload)
            state.posts.data = filteredGroupPosts
        }
    }
})

export const groupSelector = (state: RootState) => state.group
export const {
    selectGroup,
    clearGroup,
    clearPosts,
    clearGroupPost,
    updateGroupActive,
    clearGroupPosts,
    setInitialGroupPosts,
    setGroupPosts,
    setGroupPost,
    deleteGroupPostWithId,
} = groupSlice.actions
export default groupSlice.reducer