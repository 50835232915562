const dropdownArrow = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
          id="expand_more_black_24dp" 
          xmlns="http://www.w3.org/2000/svg" 
          width={width ?? '24'}
          height={height ?? '24'}
          viewBox="0 0 24 24"
        >
          <path id="Path_781" data-name="Path 781" d="M24,24H0V0H24Z" fill="none" opacity="0.87"/>
          <path id="Path_782" data-name="Path 782" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" fill="#5e257d"/>
        </svg>
      </div>
    )
  }
  
  export default dropdownArrow