import { RFC } from '../../../types/propTypes'
import { Post } from '../../../types/stateTypes'
import { WHMAsyncAvatar } from '../atoms'
import { CommentIcon, FavouriteIcon } from '../../../assets/icons/icons'
import { motion } from 'framer-motion'

type PostCardProps = {
    post:        Post
    hoverable?:  boolean
    isSelected?: boolean
}

const PostCard:RFC<PostCardProps> = ({ post, hoverable, isSelected }) => {
    return (
        <motion.div
            initial={{ x: -150, opacity: 0, scale: 0 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            exit={{ x: 150, opacity: 0 }}
            transition={{ stiffness: 80 }}
            className={`w-full p-2 rounded-[10px] border-solid border-[1px] 
            transition-all duration-200 cursor-pointer 
            ${hoverable && 'hover:bg-WHMLightPurple hover:border-WHMDarkPurple'}
            ${isSelected ? 'bg-WHMLightPurple border-WHMDarkPurple' : 'border-WHMBorder'}`}
        >
            <div className='flex items-center'>
                <WHMAsyncAvatar fileName={post.author.avatar} />
                <div className='ml-3'>
                    <div className='flex font-bold text-xl text-WHMDarkPurple/80'>
                        {post.author.firstName} {post.author.lastName}
                    </div>
                    <div className='font-light text-sm flex'>
                        Posted in: &nbsp;
                        {post.postGroup.map((group, index) => (
                        <div key={index}>
                            <span
                                key={index}
                                className='text-WHMDarkPurple/80 font-semibold mr-2'
                            >
                                {group.groupName}
                            </span>
                            {post.postGroup.length - 1 !== index && (
                            <span className='text-WHMDarkPurple/80 font-semibold mr-2'>
                                /
                            </span>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='px-2 pt-1 pb-2'>
                {post.description}
            </div>
            <div className='flex items-center px-2'>
                <div className='flex items-center mr-5'>
                    <FavouriteIcon />
                    <p className='text-WHMDarkPurple font-medium ml-1'>
                        {post.likeCount}
                    </p>
                </div>
                <div className='flex items-center mr-5'>
                    <CommentIcon />
                    <p className='text-WHMDarkPurple font-medium ml-1'>
                        {post.commentCount}
                    </p>
                    
                </div>
            </div>
        </motion.div>
    )
}

export default PostCard