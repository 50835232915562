import { RFC } from '../../../types/propTypes'
import { motion, AnimatePresence } from 'framer-motion'
import { WHMDetailInput, WHMDetailTitle, WHMDropdown, WHMDetailTextArea } from '../../atoms/atoms'
import { DashboardDetails } from '../../templates/templates'
import { InputSkeleton, TextAreaSkeleton } from '../../molecules/molecules'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { updateWorkoutDescription, updateWorkoutFocus, updateWorkoutTitle, workoutSelector } from '../../../features/Training/Workouts/workoutSlice'
import { workoutFocusList } from '../../../assets/data/arrays'

type AboutWorkoutTabType = {
  isNewWorkout: boolean | undefined
  isLoading:    boolean
}

const AboutWorkoutTab:RFC<AboutWorkoutTabType> = ({ 
  isNewWorkout, 
  isLoading,
}) => {
  const workoutState = useWHMSelector(workoutSelector)
  const { title, description, focus } = workoutState.workout
  const dispatch = useWHMDispatch()

  return (
    <AnimatePresence>
      <motion.div
        key='About'
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <DashboardDetails template='grid-cols-2sm'>
        <>
          {/* Workout About Tab Left Side */}
          <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4 mt-2'>
            <div className='pb-4'>
              <WHMDetailTitle 
                title='Title'
                details='Add a title that describes your program.'
                margin='mb-1'
                isRequired={isNewWorkout ? true : false}
              />
              {isLoading ? (<InputSkeleton />) : (
                <WHMDetailInput 
                  placeholder='Title'
                  value={title ?? ''}
                  onChange={(text) => dispatch(updateWorkoutTitle(text))}
                />
              )}
            </div>
            <div className='pb-4'>
              <WHMDetailTitle 
                title='Description'
                details='Add a description to this program to help users understand what this program will achieve.'
                margin='mb-1'
                isRequired={isNewWorkout ? true : false}
              />
              {isLoading ? (<TextAreaSkeleton />) : (
                <WHMDetailTextArea 
                  placeholder='Add Description'
                  value={description ?? ''}
                  onChange={(text) => dispatch(updateWorkoutDescription(text))}
                />
              )}
            </div>
          </div>

          {/* Workout About Tab Right Side */}
          <div className='h-full max-w-[20em] px-3 pb-4 mt-2'>
            <div className='pb-4'>
              <WHMDetailTitle 
                title='Focus'
                details='Select the focus of this program. This should reflect what workouts and exercises are in this program.'
                margin='mb-1'
                isRequired={isNewWorkout ? true : false}
              />
              {isLoading ? (<InputSkeleton />) : (
                <WHMDropdown 
                  value={focus ?? 'Focus'}
                  list={workoutFocusList}
                  onClick={(option) => dispatch(updateWorkoutFocus(option))}
                />
              )}
            </div>
          </div>
        </>
        </DashboardDetails>
      </motion.div>
    </AnimatePresence>
  )
}

export default AboutWorkoutTab