import { useState } from 'react'
import { useWHMSelector } from '../../app/hooks'
import { communityBoardTitles } from '../../assets/data/arrays'
import { COMMUNITY_BOARD_TITLES } from '../../assets/data/enums'
import { WHMEmptyDetailBox } from '../../components/atoms/atoms'
import { GroupDetail, GroupsList, ReportDetail, ReportsList } from '../../components/organisms/organisms'
import { groupSelector } from '../../features/Community/Groups/groupSlice'
import { reportSelector } from '../../features/Community/Reports/reportSlice'
import { DashboardLayout, DashboardTitles } from '../../components/templates/templates'

const Community = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(COMMUNITY_BOARD_TITLES.REPORTS)
  const reportState = useWHMSelector(reportSelector)
  const groupState = useWHMSelector(groupSelector)
  const [ isNewGroup, setIsNewGroup ] = useState(false)

  const renderList = () => {
    switch (currentPage) {
      case COMMUNITY_BOARD_TITLES.REPORTS:
        return <ReportsList />
      case COMMUNITY_BOARD_TITLES.GROUPS:
        return <GroupsList 
          isNewGroup={isNewGroup}
          setIsNewGroup={setIsNewGroup}
        />
      default:
        return
    }
  }

  const renderDetails = () => {
    switch (currentPage) {
      case COMMUNITY_BOARD_TITLES.REPORTS:
        if (!reportState.id)
          return <WHMEmptyDetailBox title='report' />
        else
          return <ReportDetail />
      case COMMUNITY_BOARD_TITLES.GROUPS:
        if (isNewGroup)
          return <GroupDetail 
            isNewGroup={isNewGroup}
            setIsNewGroup={setIsNewGroup}
          />
        if (!groupState.group.name)
          return <WHMEmptyDetailBox title='group'/>
        else
          return <GroupDetail />
      default:
        return
    }
  }

  return (
    <DashboardLayout page='community'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Community'
            menu={communityBoardTitles}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
        <div className='dashboard-title-box'>
          { renderList() }
        </div>
        <div className='dashboard-detail-box'>
          { renderDetails() }
        </div>
      </>
    </DashboardLayout>
  )
}

export default Community