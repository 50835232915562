const markedStarIcon = ({
  width,
  height,
  color,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? '24'}
        height={height ?? '24'}
        viewBox="0 0 20.681 20.681"
      >
        <path
          id="Path_918"
          data-name="Path 918"
          d="M0,0H20.681V20.681H0Z"
          fill="none"
        />
        <path
          id="Union_3"
          data-name="Union 3"
          d="M8.617,13.158,3.291,16.373,4.7,10.315,0,6.238l6.195-.525L8.617,0l2.422,5.712,6.194.525-4.7,4.077,1.413,6.058Z"
          transform="translate(1.724 1.723)"
          fill="#5e257d"
        />
      </svg>
    </div>
  )
}

export default markedStarIcon