import { RFC } from '../../../types/propTypes'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../../../assets/styles/quill.css'

type WHMTextAreaProps = {
  text: string
  onChange: (text: string) => void
}

const WHMTextArea:RFC<WHMTextAreaProps> = ({ text, onChange }) => {

  const modules = {
    toolbar: [
      [{ 'list': 'bullet' }],
      [ 'italic', 'bold' ],
    ],
  }

  return (
    <ReactQuill 
      value={text}
      onChange={onChange}
      modules={modules}
    />
  )
}

export default WHMTextArea