import { ReplyButtonIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'

type WHMReplyInputProps = {
    replyText: string
    setReplyText: (text: string) => void
    onSubmit: (text: string) => void
}

const WHMReplyInput:RFC<WHMReplyInputProps> = ({
    replyText,
    setReplyText,
    onSubmit,
}) => {
    return (
        <div className='flex last:items-end bg-WHMDarkWhite rounded-lg my-2'>
            <textarea 
                rows={4}
                className='w-full h-[115px] bg-WHMDarkWhite rounded-l-lg
                px-3 py-2 outline-none resize-none'
                placeholder='Write a reply'
                onChange={(e) => setReplyText(e.target.value)}
            />
            <button
                type='submit' 
                data-mdb-ripple="true" 
                data-mdb-ripple-color="light" 
                data-mdb-ripple-duration="1s"
                className='w-12 h-10 mx-2 mb-2 flex justify-center items-center
                bg-WHMDarkPurple/90 rounded-[4px] disabled:bg-WHMDarkPurple/50
                disabled:cursor-not-allowed hover:bg-WHMDarkPurple transition-all duration-300'
                disabled={!replyText}
                onClick={() => onSubmit(replyText)}
            >
                <ReplyButtonIcon />
            </button>
        </div>
    )
}

export default WHMReplyInput