import { WHMApi } from '../app/api'
import { 
    CommentsData, DeleteReport, PaginationPayload, 
    PaginationPayloadWithPostId, PostReportData, 
    ReportData, ReportsData 
} from '../types/serviceTypes'
import { Post, Report } from '../types/stateTypes'

export const reportApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getReports: builder.query<ReportsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/reports/posts',
                params: { currentPage, perPage, filter }
            }),
            providesTags: ['Report']
        }),
        getReport: builder.query<ReportData, PostReportData>({
            query: ({ postId, userId }) => `/admin/reports/posts/${postId}/users/${userId}`,
            providesTags: ['Report']
        }),
        getPost: builder.query<Post, string>({
            query: (postId) => `/posts/${postId}`,
            providesTags: ['Report']
        }),
        getComments: builder.query<CommentsData, PaginationPayloadWithPostId>({
            query: ({ postId, currentPage, perPage }) => ({
                url: `/posts/${postId}/comments`,
                params: { currentPage, perPage }
            }),
            providesTags: ['Report']
        }),
        removeReport: builder.mutation<Report, DeleteReport>({
            query: ({ postId, userId }) => ({
                url: `/admin/reports/posts/${postId}/users/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Report']
        }),
        removePost: builder.mutation<Post, string>({
            query: (postId) => ({
                url: `/admin/posts/${postId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Report']
        })
    })
})

export const {
    useLazyGetReportsQuery,
    useLazyGetReportQuery,
    useLazyGetPostQuery,
    useLazyGetCommentsQuery,
    useRemoveReportMutation,
    useRemovePostMutation,
} = reportApi