import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialStatisticsState } from '../../../assets/data/InitialState'
import { CourseStatistic, ProgramStatisctics, UserStatistics } from '../../../types/serviceTypes'

export type StatisticsSliceType = {
    userStats?:    UserStatistics
    programStats?: ProgramStatisctics
    courseStats?:  CourseStatistic
}

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState: InitialStatisticsState,
    reducers: {
        setUserStats: (state, action:PayloadAction<StatisticsSliceType>) => {
            state.userStats = action.payload.userStats
        },
        setProgramStats: (state, action:PayloadAction<StatisticsSliceType>) => {
            state.programStats = action.payload.programStats
        },
        setCourseStats: (state, action:PayloadAction<StatisticsSliceType>) => {
            state.courseStats = action.payload.courseStats
        }
    }
})
export const statisticsSelector = (state: RootState) => state.statistics
export const {
    setUserStats,
    setProgramStats,
    setCourseStats,
} = statisticsSlice.actions
export default statisticsSlice.reducer