import { useWHMDispatch, useWHMSelector } from "../../../app/hooks"
import { ErrorIcon } from "../../../assets/icons/icons"
import { closeModal, modalSelector } from "../../../features/Modal/modalSlice"
import { WHMButton } from "../../atoms/atoms"

const FailCourseModal = () => {
    const modalState = useWHMSelector(modalSelector)
    const dispatch = useWHMDispatch()

    return (
        <div className='w-[23em] flex flex-col justify-center items-center'>
            <ErrorIcon width={'70'} height={'70'}/>
            <p className='text-3xl font-bold text-WHMDarkPurple my-3 text-center'>
                {modalState.title}
            </p>
            <p className='w-full text-center text-lg mb-7'>
                {modalState.body}
            </p>
            <WHMButton 
                text='CLOSE'
                className='modal-purple-button'
                onSubmit={() => dispatch(closeModal())}
            />
        </div>
    )
}

export default FailCourseModal