import { useEffect } from 'react'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { selectUser, userSelector } from '../../../features/Users/Users/userSlice'
import { useLazyGetUserQuery, useLazyGetUserStatisticsQuery } from '../../../services/UserService'
import moment from 'moment'
import { DashboardDetails } from '../../templates/templates'
import { WHMAsyncAvatar, WHMUserDetailText } from '../../atoms/atoms'
import { FacebookIcon, InstagramIcon, TicktokIcon } from '../../../assets/icons/icons'
import { UserCardSkeleton, UserDetailSkeleton } from '../../molecules/molecules'
import { 
  converStatsToString, currentPaymentPeriod, currentPaymentPrice, linkMediaPage, 
  nextPaymentDate, programProgressionFormatter, userAddressFormatter, userSubscribePlanFormatter 
} from '../../../utils/userInfoFormatter'
import { UserData } from '../../../types/serviceTypes'
import { UserStatisticsData } from '../../../types/stateTypes'
import { SubscriptionsList } from '../organisms'

const AboutUserTab = () => {
  const userState = useWHMSelector(userSelector).user
  const dispatch = useWHMDispatch()

  const [ getUser, { data: userData, isLoading: userLoading, isFetching: userFetching } ] = useLazyGetUserQuery()
  const [ getUserStatistics, { data: statsData, isLoading: statsLoading, isFetching: statsFetching } ] = useLazyGetUserStatisticsQuery()

  useEffect( () => {
    if (!userState.id) return
    Promise.all([
      getUser(userState.id),
      getUserStatistics(userState.id)
    ]) 
  },[userState.id])

  useEffect(() => {
    userData && dispatch(selectUser({ id: userData.user.id, currentTab: 'About', user: userData.user }))
  }, [userData])

  const renderUserStats = (statsData: UserStatisticsData | undefined) => {
    if (statsLoading || statsFetching) return <UserDetailSkeleton />
    if (!statsData)                    return null
    return (
      <>
        <p className='font-semibold text-WHMBlack mt-2'>
          User Stats
        </p>
        <div className='rounded-[10px] px-4 py-3 border-WHMBorder border-solid border-[1px]'>
          <WHMUserDetailText 
            title='Days Subscribed' 
            value={converStatsToString(statsData?.daysSubscribed)}
          />
          <WHMUserDetailText 
            title='Current Program' 
            value={statsData?.programStats?.userProgram?.program?.title ?? '---'}
          />
          <WHMUserDetailText 
            title='Program Progression' 
            value={programProgressionFormatter(statsData?.programStats?.userProgram)}
          />
          <WHMUserDetailText 
            title='Completed Programs' 
            value={converStatsToString(statsData?.programStats?.countProgramsCompleted)}
          />
          <WHMUserDetailText 
            title='Average Program Progression' 
            value={'---'}
          />
          <WHMUserDetailText 
            title='Completed Courses' 
            value={converStatsToString(statsData?.CourseStats?.countCourseFinished)}
          />
          <WHMUserDetailText 
            title='Average Course Progression' 
            value={'---'}
          />
        </div> 
      </>
    )
  }

  const renderCurrentPlan = (subscription: UserData | undefined) => {
    if (userLoading || userFetching)         return <UserCardSkeleton />
    if (!subscription?.subscriptionDetails || 
      !subscription.userSubscriptionInformation?.subscriptionId) 
      return null
    const subscriptionType = subscription.userSubscriptionInformation.type
    
    return (
      <>
        <p className='font-semibold text-WHMBlack mt-2'>
          Users Current Plan
        </p>
        <div 
          className='w-full flex items-center bg-WHMDarkPurple rounded-[10px]
          text-WHMWhite font-semibold text-3xl italic px-4 py-3'
        >
          {currentPaymentPrice(subscriptionType, subscription)}
          <span className='not-italic text-base text-WHMWhite/80 ml-2'>
          {currentPaymentPeriod(subscriptionType, subscription)}
          </span>
        </div>
        <div 
          className='w-full border-WHMBorder border-solid border-[1px] 
          rounded-[10px] px-4 py-2 mt-3'
        >
          Next Payment:
          <span className='ml-2 text-WHMDarkPurple/80 font-semibold'>
            {nextPaymentDate(subscriptionType, subscription)}
          </span>
        </div>
      </>
    )
  }

  return (
    <>
      <DashboardDetails template="grid-cols-2lg">
        <>
          {/* About User */}
          <div className="w-full h-screen overflow-y-auto overflow-x-hidden p-3 pb-[13em]">
            <p className="font-semibold text-WHMBlack">About User</p>
            {userLoading && userFetching ? (
              <UserDetailSkeleton />
            ) : (
              <div className="rounded-[10px] px-4 py-3 border-WHMBorder border-solid border-[1px]">
                <div className="flex items-center mb-2">
                  <WHMAsyncAvatar fileName={userState.avatar} />
                  <div className="ml-3 flex-col items-center">
                    <div className="flex items-end font-bold text-xl text-WHMDarkPurple/80">
                      {userState.firstName} {userState.lastName}
                    </div>
                    <div className="text-WHMBlack/60 texxt-[0.9em] leading-3">
                      Joined: {moment(userState.createdAt).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </div>
                <WHMUserDetailText
                  title="Phone"
                  value={userState.userMobile?.number ?? "-"}
                />
                <WHMUserDetailText
                  title="Email"
                  value={userState.email ?? "-"}
                />
                <hr className="bg-WHMBorder h-[1px] my-2 mx-5" />
                <WHMUserDetailText
                  title="Location"
                  value={userAddressFormatter(
                    userState.userAddress?.state,
                    userState.userAddress?.country
                  )}
                />
                <WHMUserDetailText
                  title="Plan"
                  value={userSubscribePlanFormatter(
                    userState.userSubscriptionInformation?.type ?? "",
                    userState
                  )}
                />
                <div className="flex items-center mt-2">
                  {userState.facebook && (
                    <FacebookIcon
                      className="cursor-pointer mr-3"
                      onClick={() => linkMediaPage(userState.facebook)}
                    />
                  )}
                  {userState.instagram && (
                    <InstagramIcon
                      className="cursor-pointer mr-3"
                      onClick={() => linkMediaPage(userState.instagram)}
                    />
                  )}
                  {userState.tiktok && (
                    <TicktokIcon
                      className="cursor-pointer mr-3"
                      onClick={() => linkMediaPage(userState.tiktok)}
                    />
                  )}
                </div>
              </div>
            )}

            {renderUserStats(statsData)}
            {renderCurrentPlan(userState)}
          </div>

          {/* User Subscription Timeline */}
          <div className="w-full p-3">
            <p className="font-semibold text-WHMBlack">
              User Subscription Timeline
            </p>
            <SubscriptionsList
              userId={userState.id} 
              paymentDetails={userState}
            />
          </div>
        </>
      </DashboardDetails>
    </>
  );
}

export default AboutUserTab