import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialLessonState } from '../../../assets/data/InitialState'
import { LessonData } from '../../../types/serviceTypes'

export type LessonSliceType = {
    id: string
    lesson: LessonData
}

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState: InitialLessonState,
    reducers: {
        selectLesson: (state, action:PayloadAction<LessonSliceType>) => {
            state.id     = action.payload.id
            state.lesson = action.payload.lesson
        },
        clearLesson: (state) => {
            state.id     = ''
            state.lesson = { id: '' }
        },
        updateLessonTitle: (state, action:PayloadAction<string>) => {
            state.lesson.title = action.payload
        },
        updateLessonVideo: (state, action:PayloadAction<string>) => {
            state.lesson.video = action.payload
        },
        updateLessonImage: (state, action:PayloadAction<string>) => {
            state.lesson.image = action.payload
        },
        refreshLessonMedia: (state) => {
            state.lesson.video = ''
            state.lesson.image = ''
        },
        updateLessonOverview: (state, action:PayloadAction<string>) => {
            state.lesson.overview = action.payload
        }
    }
})

export const lessonIdSelector = (state: RootState) => state.lesson.id
export const lessonSelector   = (state: RootState) => state.lesson.lesson
export const {
    selectLesson,
    clearLesson,
    updateLessonTitle,
    updateLessonVideo,
    updateLessonImage,
    refreshLessonMedia,
    updateLessonOverview,
} = lessonSlice.actions
export default lessonSlice.reducer