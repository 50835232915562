import { RFC } from '../../../types/propTypes'
import { motion } from 'framer-motion'

type NewCardProps = {
    children: JSX.Element
}

const cardAnimations = {
    initial: { x: -150, opacity: 0, scale: 0 },
    animate: { x: 0, opacity: 1, scale: 1 },
    exit: { x: 150, opacity: 0 },
    transition:{ stiffness: 80 }
}

const NewCard:RFC<NewCardProps> = ({ children }) => {
    return (
        <motion.div
            key='new_card'
            {...cardAnimations}
            className='bg-WHMPurple flex flex-col m-2 p-2 rounded-[5px] 
            text-WHMWhite fill-WHMWhite shadow-md'
        >
            { children }
        </motion.div>
    )
}

export default NewCard