import { CreateExerciseGroupDetails, UpdateExerciseGroupDetails } from '../types/serviceTypes'
import { ExerciseGroup } from '../types/stateTypes'

export const formatCreateExerciseGroup = (exerciseGroups: ExerciseGroup[]) => {
    let exerciseGroup:CreateExerciseGroupDetails[] = []
    exerciseGroups.forEach(group => {
        const exerciseDetail = group.exerciseGroupInformation.map(exercise => {
            const homeAlternativeObj = {
                defaultReps:   exercise.exerciseHomeAlternative?.defaultReps,
                deloadingReps: exercise.exerciseHomeAlternative?.deloadingReps,
                exerciseId:    exercise.exerciseHomeAlternative?.exerciseId,
                defaultSets:   exercise.exerciseHomeAlternative?.defaultSets,
                deloadingSets: exercise.exerciseHomeAlternative?.deloadingSets,
                workTimeS:     exercise.exerciseHomeAlternative?.workTimeS,
                restTimeS:     exercise.exerciseHomeAlternative?.restTimeS,
                tempoS:        exercise.exerciseHomeAlternative?.tempoS
            }
            return {
                exerciseId:              exercise.exerciseId,
                defaultSets:             exercise.defaultSets,
                defaultReps:             exercise.defaultReps,
                deloadingSets:           exercise.deloadingSets,
                deloadingReps:           exercise.deloadingReps,
                workTimeS:               exercise.workTimeS,
                restTimeS:               exercise.restTimeS,
                tempoS:                  exercise.tempoS,
                exerciseHomeAlternative: !exercise.exerciseHomeAlternative ? null : homeAlternativeObj
            }
        })
        if (exerciseDetail.length) {
            exerciseGroup = [...exerciseGroup, {
                exerciseSettingsList: exerciseDetail
            }]
        }
    })
    return exerciseGroup
}

export const formatUpdateExerciseGroup = (
    exerciseGroups: ExerciseGroup[] | undefined,
    initialGroups:  ExerciseGroup[] | undefined
) => {
    let exerciseGroupList: UpdateExerciseGroupDetails[] = []
    let deletedExerciseGroupIds: string[]               = []

    initialGroups?.forEach(initialGroup => {
        const newGroupIds = exerciseGroups?.map(newGroup => newGroup.id)
        if (!newGroupIds?.includes(initialGroup.id))
            deletedExerciseGroupIds.push(initialGroup.id)
    })

    exerciseGroups?.forEach(group => {
        const isNewGroup = group.id.split(':')[0] === 'groupId'
        const exerciseDetail = group.exerciseGroupInformation.map(exercise => {
            const homeAlternativeObj = {
                defaultReps:   exercise.exerciseHomeAlternative?.defaultReps,
                deloadingReps: exercise.exerciseHomeAlternative?.deloadingReps,
                exerciseId:    exercise.exerciseHomeAlternative?.exerciseId,
                defaultSets:   exercise.exerciseHomeAlternative?.defaultSets,
                deloadingSets: exercise.exerciseHomeAlternative?.deloadingSets,
                workTimeS:     exercise.exerciseHomeAlternative?.workTimeS,
                restTimeS:     exercise.exerciseHomeAlternative?.restTimeS,
                tempoS:        exercise.exerciseHomeAlternative?.tempoS
            }
            
            return {
                exerciseId:              exercise.exerciseId,
                defaultSets:             exercise.defaultSets,
                defaultReps:             exercise.defaultReps,
                deloadingSets:           exercise.deloadingSets,
                deloadingReps:           exercise.deloadingReps,
                workTimeS:               exercise.workTimeS,
                restTimeS:               exercise.restTimeS,
                tempoS:                  exercise.tempoS,
                exerciseHomeAlternative: !exercise.exerciseHomeAlternative ? null : homeAlternativeObj
            }
        })
        if (exerciseDetail.length) {
            exerciseGroupList = [...exerciseGroupList, {
                exerciseGroupId: isNewGroup ? null : group.id,
                exerciseSettingsList: exerciseDetail
            }]
        }
    })

    return { exerciseGroupList, deletedExerciseGroupIds }
}