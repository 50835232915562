import { RFC } from '../../../types/propTypes'

type WHMFocusButtonsProps = {
    list:         string[]
    selectedList: string[] | undefined
    onSelect:     (focus: string) => void
}

const WHMFocusButtons:RFC<WHMFocusButtonsProps> = ({
    list,
    selectedList,
    onSelect
}) => {
    return (
        <div className='w-full flex flex-wrap'>
            {list?.map((focus, index) => {
                const isSelected = selectedList?.includes(focus)
                if (focus === 'All') return <div key={index}></div>
                return (
                    <div
                        key={index}
                        className={`rounded-xl border-solid border-[2px]
                        px-3 py-2 my-1 mr-1 cursor-pointer font-semibold
                        transition-all duration-200 shadow-sm
                        ${isSelected ? 'bg-WHMLightGold' : 'bg-WHMWhite'}
                        ${isSelected ? 'text-WHMGold' : 'text-WHMBorder'}
                        ${isSelected ? 'border-WHMLightGold' : 'border-WHMBorder'}
                        ${isSelected ? 'hover:text-WHMGold' : 'hover:text-WHMDarkPurple'}
                        ${isSelected ? 'hover:border-WHMLightGold' : 'hover:border-WHMDarkPurple'}`}
                        onClick={() => onSelect(focus)}
                    >
                        {focus}
                    </div>
                )})}
        </div>
    )
}

export default WHMFocusButtons