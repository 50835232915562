import { useWHMSelector } from '../../app/hooks'
import { overlaySelector } from '../../features/Modal/overlaySlice'
import { WHMLoading } from './atoms'

const WHMOverlay = () => {
    const overlayState = useWHMSelector(overlaySelector)
    return (
        <div className='absolute inset-0 bg-WHMDark/80 backdrop-blur-sm z-50'>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[8em] flex flex-col justify-between text-center'>
                <WHMLoading shape='spinner'/>
                <p className='text-WHMWhite text-3xl'>
                    ... {overlayState.text}
                </p>
            </div>
        </div>
    )
}

export default WHMOverlay