import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import HelpUsers from '../../../features/Notice/HelpTickets/HelpUsers'

const HelpTicketsList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock title="Help Tickets" />
      <WHMSearchbar onChange={onChangeText} />
      <HelpUsers searchText={searchText} />
    </>
  )
}

export default HelpTicketsList