import { RFC } from '../../../types/propTypes'
import { motion } from 'framer-motion'

type TitleCardProps = {
  isSelected: boolean
  children: JSX.Element
  onClick: () => void
}

const cardAnimations = {
  initial:    { x: -150, opacity: 0, scale: 0 },
  animate:    { x: 0, opacity: 1, scale: 1 },
  exit:       { x: 150, opacity: 0 },
  transition: { stiffness: 80 }
}

const TitleCard:RFC<TitleCardProps> = ({ isSelected, children, onClick }) => {
  return (
    <motion.div 
      key='title_card'
      {...cardAnimations}
      className={`flex flex-col m-2 p-2 rounded-[5px]
      ${isSelected 
        ? 'text-WHMWhite bg-WHMPurple border-none fill-WHMWhite'
        : 'text-WHMDarkPurple bg-WHMWhite border-WHMBorder fill-WHMDarkPurple border-[1px] border-solid'
      }
      hover:fill-WHMWhite hover:bg-WHMPurple hover:text-WHMWhite cursor-pointer
      transition-all duration-300 shadow-md`
      }
      onClick={onClick}
    >
      { children }
    </motion.div>
  )
}

export default TitleCard