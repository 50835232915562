import moment from 'moment'
import { UserData } from '../types/serviceTypes'
import { PaypalDetails, StripeDetails, UserProgramStats } from '../types/stateTypes'

export const userTypeFormatter = (type: string | undefined) => {
    switch (type) {
        case 'All':        return 
        case 'Subscribed': return 1
        default:           return
    }
}

export const userAddressFormatter = (state: string | undefined, country: string | undefined) => {
    if (!state && !country) return '---'
    if (!state && country)  return country 
    if (state && !country)  return state
    if (state && country)   return `${state}, ${country}`
}

export const userSubscribePlanFormatter = (type: string, subscription: UserData) => {
    if (!subscription.subscriptionDetails?.id) return 'Not Subscribed'
    if (type === 'PAYPAL') {
        const subscriptionDetails = subscription.subscriptionDetails as PaypalDetails
        if (!subscriptionDetails.billing_info.next_billing_time || 
            !subscriptionDetails.billing_info.last_payment) return 'Not Subscribed'
            const period = subscriptionPeriodCalculator(
                subscriptionDetails.billing_info.next_billing_time,
                subscriptionDetails.billing_info.last_payment.time
            )
            return `Subscribed, ${period}`
    } else {
        const subscriptionDetails = subscription.subscriptionDetails as StripeDetails
        const capitalize = subscriptionDetails.plan.interval.charAt(0).toUpperCase()
        const restOfWords = subscriptionDetails.plan.interval.slice(1).concat('ly')
        return `Subscribed, ${capitalize.concat(restOfWords)}`
    }
}

export const linkMediaPage = (link: string | undefined) => {
    if (!link) return
    window.open(link)
}

export const programProgressionFormatter = (userProgram: UserProgramStats | undefined) => {
    if (!userProgram) return '---'
    const { progress, currentPhase } = userProgram
    if (!progress || !currentPhase.phase?.orderIndex) return '---'
    const progressWeek = `Week ${Math.floor(progress)}`
    const phase = `Phase ${currentPhase.phase?.orderIndex + 1}`
    return `${progressWeek} ${phase}`
}

export const converStatsToString = (value: number | undefined) => {
    if (!value) return '---'
    const result = value.toString()
    return result
}

export const currentPaymentPrice = (type: string, subscription: UserData) => {
    if (!subscription.subscriptionDetails?.id) return '---'
    if (type === 'PAYPAL') {

        const subscriptionDetails = subscription.subscriptionDetails as PaypalDetails
        if (!subscriptionDetails.billing_info.last_payment) return '---'
        const formattedPrice = parseFloat(subscriptionDetails.billing_info.last_payment.amount.value).toFixed(2)
        return `$${formattedPrice}`
    } else {
        const subscriptionDetails = subscription.subscriptionDetails as StripeDetails
        const paypalPlanPrice = (subscriptionDetails.items.data[0].plan.amount / 100).toFixed(2)
        return `$${paypalPlanPrice}`
    }
}

export const currentPaymentPeriod = (type: string, subscription: UserData) => {
    if (!subscription.subscriptionDetails?.id) return '---'
    if (type === 'PAYPAL') {
        const subscriptionDetails = subscription.subscriptionDetails as PaypalDetails
        if (!subscriptionDetails.billing_info.last_payment) return '---'
            const period = subscriptionPeriodCalculator(
                subscriptionDetails.billing_info.next_billing_time,
                subscriptionDetails.billing_info.last_payment.time
            )
            return period
    } else {
        const subscriptionDetails = subscription.subscriptionDetails as StripeDetails
        const capitalize = subscriptionDetails.items.data[0].plan.interval.charAt(0).toUpperCase()
        const restOfWords = subscriptionDetails.items.data[0].plan.interval.slice(1).concat('ly')
        return capitalize.concat(restOfWords)
    }
}

export const nextPaymentDate = (type: string, subscription: UserData) => {
    if (!subscription.subscriptionDetails?.id) return '---'
    if (type === 'PAYPAL') {
        const subscriptionDetails = subscription.subscriptionDetails as PaypalDetails
        return moment(subscriptionDetails.billing_info.next_billing_time).format('DD-MM-YYYY')
    } else {
        const subscriptionDetails = subscription.subscriptionDetails as StripeDetails
        return moment.unix(subscriptionDetails.current_period_start).format('DD-MM-YYYY')

    }
}

const subscriptionPeriodCalculator = (next: Date, last: Date) => {
    const difference = moment(next).diff(last, 'days')
    if (difference <= 7) return 'Weekly'
    if (difference > 8 && difference < 32) return 'Monthly'
    if (difference >= 32) return 'Yearly'
}

export const subscriptionPeriodFormatter = (period: string | undefined) => {
    if (!period) return 'New'
    const capitalize = period.charAt(0).toUpperCase()
    const restOfWords = period.slice(1).concat('ly')
    return capitalize.concat(restOfWords)
}

export const calculateStripePrice = (price: number | undefined) => {
    if (!price) return '---'
    const amount = (price / 100).toFixed(2)
    return amount
}

export const convertSubscriptionDate = (timestamp: number | undefined) => {
    if (!timestamp) return
    const subscriptionDate = moment.unix(timestamp).format('DD-MM-YYYY')
    return subscriptionDate
}