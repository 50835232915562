import { RFC } from '../../types/propTypes'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { DraggableBinIcon, DraggableIcon } from '../../assets/icons/icons'
import { WHMButton } from '../atoms/atoms'
import { ProgramPhaseWorkout, Workout } from '../../types/stateTypes'

type ProgramDnDListFieldProps = {
  list: ProgramPhaseWorkout[] | undefined
  sortItems: (items: ProgramPhaseWorkout[]) => void
  removeItem: (item: Workout) => void
  openModal: () => void
}

const ProgramDnDListField:RFC<ProgramDnDListFieldProps> = ({
  list,
  sortItems,
  removeItem,
  openModal,
}) => {

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination || !list) return
    const items = Array.from(list)
    const [ reorderedItem ] = items.splice(result.source.index, 1)
    items.splice(result.destination?.index, 0, reorderedItem)
    sortItems(items)
  }

  return(
    <div className='max-w-[22em] h-fit'>
      {!list?.length ? (
        <div className='w-full flex flex-col items-center text-center
        border-WHMBorder border-[1px] rounded-[5px] py-4 mb-3'>
          <p className='font-bold text-lg'>No workout yet …</p>
          <p>Add workout to this program to construct a full program.</p>
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='list'>
          {(provided) => (
            <div
              {...provided.droppableProps} 
              ref={provided.innerRef}
              className='w-full'
            >
              {list?.map((item, index) => (
                <div className='flex items-center my-2' key={index}>
                  <p className='w-[1em] text-WHMDarkPurple font-semibold text-2xl'>
                    {index + 1}
                  </p>
                  <Draggable 
                    key={item.workoutId} 
                    draggableId={item.workoutId} 
                    index={index}
                  >
                  {(provided) => (
                  <>
                    <div
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className='item-title-with-bin'
                    >
                      {item.workout.title}
                    <DraggableIcon />
                    </div>
                  </>
                  )}
                  </Draggable>
                  <DraggableBinIcon 
                    className='cursor-pointer'
                    width={'40'}
                    height={'40'}
                    onClick={() => removeItem(item.workout)}
                  />
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      )}
      
      <WHMButton 
        text='Add Workout'
        className='blue-button'
        onSubmit={openModal}
      />

      {list?.length ? (
        <div className='w-full flex items-center justify-center mt-4 text-lg'>
          <p className='w-fit border-WHMBorder border-solid border-[1px] px-2 py-1
          rounded-[5px] text-WHMPurple'>
            { list.length === 1 ? 'Lesson' : 'Lessons' } 
            <span className='ml-2 text-WHMDarkPurple'>{ list.length } / 7</span>
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default ProgramDnDListField