import { 
    DashboardTitleType, 
    ExerciseFocusTypes, 
    WorkoutFocusTypes, 
    CourseFocusTypes, 
    UserTypes, 
} from '../../types/propTypes'
import { 
    NoticeIcon, 
    TrainingIcon, 
    ResourcesIcon, 
    CommunityIcon,
    UsersIcon
} from '../icons/icons'
import { 
    NOTICE_BOARD_TITLES, 
    TRAINING_BOARD_TITLES, 
    RESOURCES_BOARD_TITLES, 
    COMMUNITY_BOARD_TITLES, 
    USERS_BOARD_TITLES 
} from './enums'

// Nav Tabs
export const navbarTabs = [
    {
        icon: NoticeIcon,
        page: 'notice',
        link: '/notice-board',
    },
    {
        icon: TrainingIcon,
        page: 'training',
        link: '/training-board',
    },
    {
        icon: ResourcesIcon,
        page: 'resources',
        link: '/resources-board',
    },
    {
        icon: CommunityIcon,
        page: 'community',
        link: '/community-board',
    },
    {
        icon: UsersIcon,
        page: 'users',
        link: '/users-board',
    },
]

// Board Titles
export const noticeBoardTitles: DashboardTitleType[] = [
    {
        title:      'Program Feedback',
        titlePage:  NOTICE_BOARD_TITLES.PROGRAM_FEEDBACK
    },
    {
        title:      'Help Tickets',
        titlePage:  NOTICE_BOARD_TITLES.HELP_TICKETS
    }
]

export const trainingBoardTitles: DashboardTitleType[] = [
    {
        title:      'Programs',
        titlePage:  TRAINING_BOARD_TITLES.PROGRAM
    },
    {
        title:      'Workouts',
        titlePage:  TRAINING_BOARD_TITLES.WORKOUTS
    },
    {
        title:      'Exercises',
        titlePage:  TRAINING_BOARD_TITLES.EXERCISES
    }
]

export const resourcesBoardTitles: DashboardTitleType[] = [
    {
        title:      'Courses',
        titlePage:  RESOURCES_BOARD_TITLES.COURSES
    },
    {
        title:      'Lessons',
        titlePage:  RESOURCES_BOARD_TITLES.LESSONS
    }
]

export const communityBoardTitles: DashboardTitleType[] = [
    {
        title:      'Reports',
        titlePage:  COMMUNITY_BOARD_TITLES.REPORTS
    },
    {
        title:      'Groups',
        titlePage:  COMMUNITY_BOARD_TITLES.GROUPS
    }
]

export const usersBoardTitles: DashboardTitleType[] = [
    {
        title:      'All Users',
        titlePage:  USERS_BOARD_TITLES.ALL_USERS
    },
    {
        title:      'User Statistics',
        titlePage:  USERS_BOARD_TITLES.USER_STATISTICS
    }
]

// Detail Tabs
export const programTabs = [ 'About', 'Phases', 'Suggest' ]

export const phaseTabs   = [ 'Phase 1', 'Phase 2', 'Phase 3' ]

export const workoutTabs = [ 'About', 'Exercises' ]

export const userTabs    = [ 'About', 'Feedback', 'Tickets' ]

// Program Suggest
export const programGoals      = [ 'Glute Focused', 'Muscle Building', 'Strength', 'Foundation & Skill' ]

export const programExperiences = [ '<1 Year', '1-3 Years', '3+ Years' ]

export const programTrainings   = [ '2-3 Sessions', '3-4 Sessions', '4-5 Sessions' ]

// Focus Lists
export const workoutFocusList: WorkoutFocusTypes[] = [
    'Glute Focused',
    'Muscle Building',
    'Strength',
    'Foundation & Skill'
]

export const exerciseFocusList: ExerciseFocusTypes[] = [
    'All',
    'Shoulders',
    'Back',
    'Chest',
    'Arms',
    'Core',
    'Quads',
    'Hamstrings',
    'Glutes',
    'Calves',
    'Adductors'
]

export const courseFocusList: CourseFocusTypes[] = [
    'Nutrition',
    'Training',
    'Mindset',
    'Womens Health',
]

export const alphabet = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
]

export const userTypeList: UserTypes[] = [
    'All',
    'Subscribed',
]