const DnDListSkeleton = () => {
    return (
        <>
            {Array(6).fill('').map((_,i) => (
            <div
            key={i}
            role='status'
            className='w-full my-2 animate-pulse'
            >
                <div className='h-12 w-[60%] bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey'></div>
            </div>
            ))}
        </>
    )
}

export default DnDListSkeleton