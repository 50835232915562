import { WHMApi } from '../app/api'
import { 
    GetWorkoutsArgs, SingleWorkoutData, 
    WorkoutCreateArgs, WorkoutsData, WorkoutUpdateArgs 
} from '../types/serviceTypes'
import { Workout } from '../types/stateTypes'
import { formatCreateExerciseGroup, formatUpdateExerciseGroup } from '../utils/formatHomeAlternative'

export const workoutApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkouts: builder.query<WorkoutsData, GetWorkoutsArgs>({
            query: ({ currentPage, perPage, filter, filterByFocus }) => ({
                url: '/admin/workouts',
                params: { currentPage, perPage, filter, filterByFocus, orderUpdatedAt: 'desc' }
            }),
            providesTags: ['Workout']
        }),
        getWorkout: builder.query<SingleWorkoutData, string>({
            query: (id) => `/admin/workouts/${id}`,
            providesTags: ['Workout']
        }),
        createWorkout: builder.mutation<Workout, WorkoutCreateArgs>({
            query: ({ title, description, focus, exerciseGroups }) => {
                const exerciseGroup = formatCreateExerciseGroup(exerciseGroups)
                return ({
                url: '/admin/workouts',
                method: 'POST',
                body: {
                    title,
                    description,
                    focus,
                    exerciseGroup
                }
            })},
            invalidatesTags: ['Workout']
        }),
        updateWorkout: builder.mutation<Workout, WorkoutUpdateArgs>({
            query: ({ id, title, description, focus, exerciseGroups, initialGroups }) => {
                const { exerciseGroupList, deletedExerciseGroupIds } = formatUpdateExerciseGroup(exerciseGroups, initialGroups)
                return ({
                    url: `/admin/workouts/${id}`,
                    method: 'PATCH',
                    body: {
                        title,
                        description,
                        focus,
                        exerciseGroupList,
                        deletedExerciseGroupIds
                    }
                })
            },
            invalidatesTags: ['Workout']
        }),
        deleteWorkout: builder.mutation<Workout, string>({
            query: (id) => ({
                url: `/admin/workouts/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Workout']
        })
    })
})

export const {
    useLazyGetWorkoutsQuery,
    useLazyGetWorkoutQuery,
    useCreateWorkoutMutation,
    useUpdateWorkoutMutation,
    useDeleteWorkoutMutation,
} = workoutApi