const draggableBinIcon = ({ height, width, color, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
      <svg 
          id="delete_black_24dp_1_" 
          xmlns="http://www.w3.org/2000/svg" 
          width={width ?? '33.707'} 
          height={height ?? '33.707'}
          viewBox="0 0 33.707 33.707"
          className={className}
          onClick={onClick}
      >
          <path id="Path_105" data-name="Path 105" d="M0,0H33.707V33.707H0Z" fill="none"/>
          <path id="Path_106" data-name="Path 106" d="M8,9H19.236V23.045H8Z" transform="translate(3.236 3.64)" fill="#d6a156" opacity="0.3"/>
          <path id="Path_107" data-name="Path 107" d="M19.747,4.4,18.342,3H11.32l-1.4,1.4H5V7.213H24.662V4.4ZM6.4,25.471A2.817,2.817,0,0,0,9.213,28.28H20.449a2.817,2.817,0,0,0,2.809-2.809V8.618H6.4ZM9.213,11.427H20.449V25.471H9.213Z" transform="translate(2.022 1.213)" fill="#5e257d"/>
      </svg>
    )
}
  
export default draggableBinIcon