import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { useCreateProgramMutation, useDeleteProgramMutation, useLazyGetProgramQuery, useUpdateProgramMutation } from '../../../services/ProgramService'
import { clearProgram, programSelector, selectProgram, updateProgramActive, updateProgramImage } from '../../../features/Training/Programs/programSlice'
import { WHMDetailTabs, WHMTitleBlock } from '../../atoms/atoms'
import { InputSkeleton, StatusButtons } from '../../molecules/molecules'
import { programTabs } from '../../../assets/data/arrays'
import { AboutProgramTab, PhasesTab, SuggestTab } from '../organisms'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { addProgram, deleteProgramWithId, updateProgramInfo } from '../../../features/Training/Programs/programsSlice'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'

type ProgramDetailsProps = {
  isNewProgram?:    boolean
  setIsNewProgram?: (close: boolean) => void
}

const ProgramDetails:RFC<ProgramDetailsProps> = ({
  isNewProgram,
  setIsNewProgram
}) => {
  const [ programTab, setProgramTab ] = useState('About')
  // Global State
  const programState = useWHMSelector(programSelector)
  const { 
    id, title, description, image, phase, isActive, 
    programSuggestGoal, programSuggestExperience, programSuggestTraining
  } = programState.program
  const dispatch = useWHMDispatch()

  // Service
  const [ getProgram, { data: programData, isLoading: programLoading } ] = useLazyGetProgramQuery()
  const [ createProgram ] = useCreateProgramMutation()
  const [ updateProgram ] = useUpdateProgramMutation()
  const [ deleteProgram ] = useDeleteProgramMutation()

  useEffect(() => {
    if (id) {
      dispatch(updateProgramImage(''))
      getProgram(id)
    }
  },[id])

  useEffect(() => {
    if (!programData) return
    dispatch(selectProgram({ id: programData.id, program: programData, phaseTab: 'Phase 1' }))
  },[programData])

  const createNewProgram = async () => {
    try {
      dispatch(openOverlay({ text: 'Creating a new program' }))
      const [ data ] = await Promise.all([
        createProgram({
          title: title ?? '',
          description: description ?? '',
          image: image ?? undefined,
          phaseList: phase ?? [],
          goalSuggest: programSuggestGoal ?? [],
          experienceSuggest: programSuggestExperience ?? [],
          trainingSuggest: programSuggestTraining ?? [],
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data || !setIsNewProgram) throw Error
      setIsNewProgram(false)
      dispatch(addProgram(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Program Created',
        body: 'You have successfully created a new program!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create program',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const saveProgram = async () => {
    try {
      dispatch(openOverlay({ text: 'Saving program details' }))
      const [ data ] = await Promise.all([
        updateProgram({
          id,
          title,
          description,
          image,
          isActive,
          phaseList: phase,
          goalSuggest: programSuggestGoal,
          experienceSuggest: programSuggestExperience,
          trainingSuggest: programSuggestTraining,
        }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(updateProgramInfo(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Program Updated',
        body: 'Your changes have been successfully saved!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update program',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const clearNewProgram = () => {
    if (!setIsNewProgram) return
    setIsNewProgram(false)
  }

  const removeProgram = async (programId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this program' }))
      const [ data ] = await Promise.all([
        deleteProgram(programId).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(clearProgram())
      dispatch(deleteProgramWithId(id))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'PROGRAM DELETED',
        body: 'You have successfully deleted the program!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete program',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const renderProgramDetails = (programTab: string) => {
    switch (programTab) {
      case 'About': return (<AboutProgramTab 
          isNewProgram={isNewProgram}
          isLoading={programLoading}
        />)
      case 'Phases': return (<PhasesTab 
          isNewProgram={isNewProgram}
          isLoading={programLoading}
        />)
      case 'Suggest': return (<SuggestTab 
          isNewProgram={isNewProgram}
          isLoading={programLoading}
        />)
      default: return <></>
    }
  }

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <WHMTitleBlock title={isNewProgram ? 'New Program' : `${title}`}/>
          {programLoading ? (<InputSkeleton />) : (
              <StatusButtons 
                  type='d-as-s'
                  isActive={isActive ?? false}
                  onSave={isNewProgram ? createNewProgram : saveProgram}
                  onDelete={isNewProgram 
                    ? clearNewProgram 
                    : () => dispatch(openModal({
                      modalType: MODAL_TYPES.DELETE,
                      deleteId: id,
                      deleteFn: removeProgram
                    }))
                  }
                  onChangeStatus={() => dispatch(updateProgramActive(!isActive))}
              />
          )}
      </div>
      <WHMDetailTabs 
          tabs={programTabs}
          selectedTab={programTab}
          onSelectTab={(tab) => setProgramTab(tab)}
      />
      { renderProgramDetails(programTab) }
    </>
  )
}

export default ProgramDetails