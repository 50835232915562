import { RFC } from '../../../types/propTypes'

type WHMUserDetailTextProps = {
    title: string
    value: string | undefined
}

const WHMUserDetailText:RFC<WHMUserDetailTextProps> = ({
    title,
    value
}) => {

    return (
        <div className='flex items-center mb-1'>
            <p className='text-WHMDarkPurple/80 font-bold'>
                {title}
            </p>
            <p className='ml-2 text-black/80'>
                {value}
            </p>
        </div>
    )
}

export default WHMUserDetailText