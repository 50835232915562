import { RFC } from '../../../types/propTypes'
import { WHMAsyncAvatar, WHMButton } from '../atoms'

type UserCardProps = {
    avatar:    string | undefined
    firstName: string | undefined
    lastName:  string | undefined
    btnFn:    () => void
}

const UserCard:RFC<UserCardProps> = ({
    avatar,
    firstName,
    lastName,
    btnFn
}) => {
    return (
      <div
        className="w-max flex items-center justify-between 
        mx-2 px-4 py-2 rounded-xl border-[1px] border-solid border-WHMBorder"
      >
        <div className="max-w-sm flex items-center mr-6">
          <WHMAsyncAvatar fileName={avatar} />
          <p className="text-WHMDarkPurple/80 text-2xl font-bold ml-4 truncate">
            {firstName}
          </p>
          <p className="text-WHMDarkPurple/80 text-2xl font-bold ml-2 truncate">
            {lastName}
          </p>
        </div>
        <WHMButton
          text="View User"
          className="user-profile-button"
          onSubmit={btnFn}
        />
      </div>
    )
}

export default UserCard