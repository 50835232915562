import { RFC } from '../../../types/propTypes'
import { AnimatePresence, motion } from 'framer-motion'
import { WHMDetailTitle } from '../../atoms/atoms'
import { DnDListSkeleton, MultiDnDLists } from '../../molecules/molecules'
import { DashboardDetails } from '../../templates/templates'

type PhasesTabProps = {
  isNewProgram: boolean | undefined
  isLoading:    boolean
}

const PhasesTab:RFC<PhasesTabProps> = ({
  isNewProgram,
  isLoading
}) => {
  return (
    <AnimatePresence>
      <motion.div
        key='program_phases'
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className='px-3 py-2 max-w-[40em]'>
          <WHMDetailTitle 
            title='Program Phases'
            details='Add workouts to each phase to construct a full program. These passes will cycle in 4 week periods.'
            margin='mb-1'
            isRequired={isNewProgram ? true : false}
          />
        </div>
        <DashboardDetails template='grid-col-1lg'>
          {isLoading 
          ? ( <DnDListSkeleton /> ) 
          : ( <MultiDnDLists /> )}
        </DashboardDetails>
      </motion.div>
    </AnimatePresence>
  )
}

export default PhasesTab