const addIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"}
            height={height ?? "24"}
            viewBox="0 0 24 24"
        >
            <path id="Path_57" data-name="Path 57" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_58" data-name="Path 58" d="M12,4a8,8,0,1,0,8,8A8.011,8.011,0,0,0,12,4Zm5,9H13v4H11V13H7V11h4V7h2v4h4Z" fill="#d6a156" opacity="0.3"/>
            <path id="Path_59" data-name="Path 59" d="M13,7H11v4H7v2h4v4h2V13h4V11H13ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z" fill="#5e257d"/>
        </svg>
      </div>
    )
  }
  
  export default addIcon