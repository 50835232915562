import { configureStore } from '@reduxjs/toolkit'
import { WHMApi } from './api'
import SanckbarReducer from '../features/Snackbar/snackbarSlice'
import ModalReducer from '../features/Modal/modalSlice'
import OverlayReducer from '../features/Modal/overlaySlice'
import AuthReducer from '../features/Auth/authSlice'
import FeedbackReducer from '../features/Notice/ProgramFeedback/feedbackSlice'
import FeedbacksReducer from '../features/Notice/ProgramFeedback/feedbacksSlice'
import HelpTicketReducer from '../features/Notice/HelpTickets/helpTicketSlice'
import HelpTicketsReducer from '../features/Notice/HelpTickets/helpTicketsSlice'
import ProgramReducer from '../features/Training/Programs/programSlice'
import ProgramsReducer from '../features/Training/Programs/programsSlice'
import WorkoutReducer from '../features/Training/Workouts/workoutSlice'
import WorkoutsReducer from '../features/Training/Workouts/workoutsSlice'
import ProgramWorkoutsReducer from '../features/Training/Workouts/programWorkoutsSlice'
import ExerciseDetailReducer from '../features/Training/Workouts/exerciseDetailSlice'
import ExerciseReducer from '../features/Training/Exercises/exerciseSlice'
import ExercisesReducer from '../features/Training/Exercises/exercisesSlice'
import AlternativesReducer from '../features/Training/Exercises/alternativesSlice'
import WorkoutExercisesReducer from '../features/Training/Exercises/workoutExercisesSlice'
import CourseReducer from '../features/Resources/Courses/courseSlice'
import CoursesReducer from '../features/Resources/Courses/coursesSlice'
import LessonReducer from '../features/Resources/Lessons/lessonSlice'
import LessonsReducer from '../features/Resources/Lessons/lessonsSlice'
import CourseLessonsReducer from '../features/Resources/Lessons/courseLessonsSlice'
import ReportReducer from '../features/Community/Reports/reportSlice'
import ReportsReducer from '../features/Community/Reports/reportsSlice'
import ReportPostReducer from '../features/Community/Reports/reportPostSlice'
import GroupReducer from '../features/Community/Groups/groupSlice'
import GroupsReducer from '../features/Community/Groups/groupsSlice'
import UserReducer from '../features/Users/Users/userSlice'
import UsersReducer from '../features/Users/Users/usersSlice'
import StripeReducer from '../features/Users/Users/stripeSlice'
import UserFeedbacksReducer from '../features/Users/Users/userFeedbacksSlice'
import UserHelpticketsReducer from '../features/Users/Users/userHelpTicketsSlice'
import StatisticsReducer from '../features/Users/Statistics/statisticsSlice'

export const store = configureStore({
    reducer: {
        [WHMApi.reducerPath]: WHMApi.reducer,
        snackbar:         SanckbarReducer,
        modal:            ModalReducer,
        overlay:          OverlayReducer,
        auth:             AuthReducer,
        feedback:         FeedbackReducer,
        feedbacks:        FeedbacksReducer,
        helpTicket:       HelpTicketReducer,
        helpTickets:      HelpTicketsReducer,
        program:          ProgramReducer,
        programs:         ProgramsReducer,
        workout:          WorkoutReducer,
        workouts:         WorkoutsReducer,
        programWorkouts:  ProgramWorkoutsReducer,
        exerciseDetail:   ExerciseDetailReducer,
        exercise:         ExerciseReducer,
        exercises:        ExercisesReducer,
        alternatives:     AlternativesReducer,
        workoutExercises: WorkoutExercisesReducer,
        course:           CourseReducer,
        courses:          CoursesReducer,
        lesson:           LessonReducer,
        lessons:          LessonsReducer,
        courseLessons:    CourseLessonsReducer,
        report:           ReportReducer,
        reports:          ReportsReducer,
        reportPost:       ReportPostReducer,
        group:            GroupReducer,
        groups:           GroupsReducer,
        user:             UserReducer,
        users:            UsersReducer,
        userStripe:       StripeReducer,
        userFeedbacks:    UserFeedbacksReducer,
        userHelpTickets:  UserHelpticketsReducer,
        statistics:       StatisticsReducer,
    },
    middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ serializableCheck: false}).concat(WHMApi.middleware),
    devTools: true
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>