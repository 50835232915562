import { WHMApi } from '../app/api'
import { ExerciseSliceType } from '../features/Training/Exercises/exerciseSlice'
import { ExerciseData, ExercisesData, GetExercisesArgs } from '../types/serviceTypes'
import { Exercise } from '../types/stateTypes'

export const exerciseApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getExercises: builder.query<ExercisesData, GetExercisesArgs>({
            query: ({ currentPage, perPage, filter, filterByFocus }) => {
                const formattedFocus = filterByFocus === 'All' ? '' : filterByFocus
                return ({
                    url: '/admin/exercises',
                    params: { currentPage, perPage, filter, filterByFocus: formattedFocus, orderUpdatedAt: 'desc' }
                })
            },
            providesTags: ['Exercise']
        }),
        getExercise: builder.query<ExerciseData, string>({
            query: (id) => `/exercises/${id}`,
            providesTags: ['Exercise']
        }),
        getHomeAlternative: builder.query<ExerciseData, string | null | undefined>({
            query: (id) => `/exercises/${id}`,
            providesTags: ['Exercise']
        }),
        createExercise: builder.mutation<Exercise, ExerciseSliceType>({
            query: ({ exercise, alternatives, focus, equipment, cues }) => {
                const exerciseAlternativeIdList = alternatives?.map(alternative => alternative.id)
                return ({
                    url: '/admin/exercises',
                    method: 'POST',
                    body: {
                        exerciseAlternativeIdList,
                        title: exercise.title ?? '',
                        video: exercise.video ?? '',
                        image: exercise.image ?? '',
                        focusList:     focus ?? [],
                        equipmentList: equipment ?? [],
                        cuesList:      cues ?? []
                    }
                })
            },
            invalidatesTags: ['Exercise']
        }),
        updateExercise: builder.mutation<Exercise, ExerciseSliceType>({
            query: ({ id, exercise, alternatives, focus, equipment, cues }) => {
                const exerciseAlternativeIdList = alternatives?.map(alternative => alternative.id)
                return ({
                    url: `/admin/exercises/${id}`,
                    method: 'PATCH',
                    body: {
                        exerciseAlternativeIdList,
                        title: exercise.title,
                        video: exercise.video,
                        image: exercise.image,
                        focusList:     focus ?? [],
                        equipmentList: equipment ?? [],
                        cuesList:      cues ?? []
                    }
                })
            },
            invalidatesTags: ['Exercise']
        }),
        deleteExercise: builder.mutation<Exercise, string>({
            query: (id) => ({
                url: `/admin/exercises/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Exercise']
        })
    })
})

export const {
    useLazyGetExercisesQuery,
    useLazyGetExerciseQuery,
    useLazyGetHomeAlternativeQuery,
    useCreateExerciseMutation,
    useUpdateExerciseMutation,
    useDeleteExerciseMutation,
} = exerciseApi