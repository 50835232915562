import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { selectWorkout, workoutSelector } from '../../../features/Training/Workouts/workoutSlice'
import { RFC } from '../../../types/propTypes'
import { workoutsSelector } from '../../../features/Training/Workouts/workoutsSlice'
import { Workout } from '../../../types/stateTypes'
import { CardError, CardLogo, TitleCard } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type WorkoutTitlesProps = {
    isLoading:   boolean
    isError:     boolean
    onRemoveNew: () => void
}

const WorkoutTitles:RFC<WorkoutTitlesProps> = ({
    isLoading,
    isError,
    onRemoveNew
}) => {
    const workoutState = useWHMSelector(workoutSelector)
    const workoutsState = useWHMSelector(workoutsSelector)
    const dispatch = useWHMDispatch()

    const handleClickCard = (item: Workout) => {
        onRemoveNew()
        dispatch(selectWorkout({ id: item.id, workout: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!workoutsState.workouts.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Workout
        </div>
    )
        
    return (
        <LazyMotion features={domAnimation} key='workout-card'>
            {workoutsState.workouts.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={workoutState.id === item.id}
                    onClick={() => handleClickCard(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <div className='flex'>
                                <p>Program</p>
                                <p className='mx-1'>●</p>
                                <p>{item.phaseWorkout?.phase.program.title ?? 'none'}</p>
                            </div>
                            <p className='truncate text-2xl font-bold'>
                                {item.title}
                            </p>
                            <div className='flex'>
                                <p>
                                    {item.focus}
                                </p>
                                <p className='mx-1'>●</p>
                                <p>
                                    {item.exerciseCount}&nbsp;
                                    {item.exerciseCount === undefined ||
                                     item.exerciseCount > 1
                                     ? 'Exercises' : 'Exercise'
                                    }
                                </p>
                            </div>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default WorkoutTitles