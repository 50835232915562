import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialWorkoutsState } from '../../../assets/data/InitialState'
import { WorkoutsData } from '../../../types/serviceTypes'
import { Workout } from '../../../types/stateTypes'

export type WorkoutsSliceType = {
    workouts: WorkoutsData
}

export const workoutsSlice = createSlice({
    name: 'workout',
    initialState: InitialWorkoutsState,
    reducers: {
        clearWorkouts: (state) => {
            state.workouts.data = []
        },
        setInitialWorkouts: (state, action:PayloadAction<WorkoutsSliceType>) => {
            state.workouts.data = action.payload.workouts.data
        },
        setWorkouts: (state, action:PayloadAction<WorkoutsSliceType>) => {
            const { data: storedWorkouts } = state.workouts
            const { data: newWorkouts } = action.payload.workouts
            const workoutIds = storedWorkouts.map(workout => workout.id)
            const filteredDuplicates = newWorkouts.filter(workout => !workoutIds.includes(workout.id))
            filteredDuplicates.map(workout => state.workouts.data.push(workout))
        },
        addWorkout: (state, action:PayloadAction<Workout>) => {
            state.workouts.data.unshift(action.payload)
        },
        updateWorkoutInfo: (state, action:PayloadAction<Workout>) => {
            const filteredWorkouts = state.workouts.data.filter(workout => workout.id !== action.payload.id)
            state.workouts.data = [ action.payload, ...filteredWorkouts ]
        },
        deleteWorkoutWithId: (state, action:PayloadAction<string>) => {
            const filteredWorkouts = state.workouts.data.filter(workout => workout.id !== action.payload)
            state.workouts.data = filteredWorkouts
        }
    }
})

export const workoutsSelector = (state: RootState) => state.workouts
export const {
    clearWorkouts,
    setInitialWorkouts,
    setWorkouts,
    addWorkout,
    updateWorkoutInfo,
    deleteWorkoutWithId,
} = workoutsSlice.actions
export default workoutsSlice.reducer