import { RFC } from '../../../types/propTypes'
import { useGetProgramStatisticQuery } from '../../../services/ProgramService'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import WHMLoading from '../../../components/atoms/WHMLoading'
import { ErrorIcon } from '../../../assets/icons/icons'

type ProgramStatsProps = {
    programId: string
}

const ProgramStats:RFC<ProgramStatsProps> = ({ programId }) => {
    const { data, isLoading, isFetching, isError } = useGetProgramStatisticQuery({ programId } ?? skipToken)
    
    const renderProgramStats = () => {
        const renderCompletedCount = () => {
            if (!programId) return <p className='ml-2'>0</p>
            if (isLoading || isFetching) return <div className='ml-4'><WHMLoading shape='circle'/></div>
            if (isError || !data) return <div className='ml-4'><ErrorIcon /></div>
            else return <p className='ml-2'>{data.finishCount}</p>
        }

        const renderCompletedRate = () => {
            if (!programId) return <p className='ml-2'>0%</p>
            if (isLoading || isFetching) return <div className='ml-4'><WHMLoading shape='circle'/></div>
            if (isError || !data) return <div className='ml-4'><ErrorIcon /></div>
            else {
                let rate
                if (data.startCount === 0 && data.finishCount === 0)
                    rate = '0'
                else
                    rate = ((data.finishCount / data.startCount) * 100).toString()
                return <p className='ml-2'>{rate === 'NaN' ? '0' : parseFloat(rate).toFixed(1)}%</p>
            }
        }

        return (
            <>
                <div className='flex'>
                    <p className='font-semibold text-WHMDarkPurple'>Times Completed</p>
                    { renderCompletedCount() }
                </div>
                <div className='flex'>
                    <p className='font-semibold text-WHMDarkPurple'>Average Completion Rate</p>
                    { renderCompletedRate() }
                </div>
            </>
        )
    }

    return (
        <>
        { renderProgramStats() }
        </>
    )
}

export default ProgramStats