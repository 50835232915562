const favouriteIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'} 
            height={height ?? '24'} 
            viewBox="0 0 24 24"
        >
        <path id="Path_122" data-name="Path 122" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_123" data-name="Path 123" d="M16.5,5a3.885,3.885,0,0,0-3.56,2.36H11.07A3.909,3.909,0,0,0,7.5,5,3.418,3.418,0,0,0,4,8.5c0,2.89,3.14,5.74,7.9,10.05l.1.1.1-.1C16.86,14.24,20,11.39,20,8.5A3.418,3.418,0,0,0,16.5,5Z" fill="#d6a156" opacity="0.3"/>
        <path id="Path_124" data-name="Path 124" d="M16.5,3A5.988,5.988,0,0,0,12,5.09,5.988,5.988,0,0,0,7.5,3,5.447,5.447,0,0,0,2,8.5c0,3.78,3.4,6.86,8.55,11.54L12,21.35l1.45-1.32C18.6,15.36,22,12.28,22,8.5A5.447,5.447,0,0,0,16.5,3ZM12.1,18.55l-.1.1-.1-.1C7.14,14.24,4,11.39,4,8.5A3.418,3.418,0,0,1,7.5,5a3.909,3.909,0,0,1,3.57,2.36h1.87A3.885,3.885,0,0,1,16.5,5,3.418,3.418,0,0,1,20,8.5C20,11.39,16.86,14.24,12.1,18.55Z" fill="#5e257d"/>
        </svg>
      </div>
    )
  }
  
  export default favouriteIcon