import { useEffect, useState } from 'react' 
import { RFC } from '../../../types/propTypes' 
import { useLazyGetPresignedResizeDownloadURLQuery } from '../../../services/FileService' 
import WHMLoading from '../WHMLoading' 

type WHMAsyncImageProps = {
  fileName: string | undefined
  width:    number
  height:   number
}

const WHMAsyncImage:RFC<WHMAsyncImageProps> = ({ 
  fileName,
  width,
  height,
}) => {
  const [ isImageError, setIsImageError ] = useState(false)
  const [ getPresignedDownloadUrl, { data, isLoading, isError } ] = useLazyGetPresignedResizeDownloadURLQuery()

  useEffect(() => {
    if (!fileName) return
    getPresignedDownloadUrl({ fileName, width, height })
  }, [fileName])

  if (isLoading) return (
    <div className='h-full w-full flex justify-center items-center rounded-lg'>
      <WHMLoading shape='circle' />
    </div>
  )
  if (!data?.url || isError || isImageError ) return (
    <img
      className='h-min w-min aspect-video flex justify-center items-center rounded-lg'
      src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'
      alt='error'
    />
  )

  return (
    <img 
      className='h-full w-full rounded-lg object-contain'
      src={data?.url}
      alt='thumnail'
      onError={() => setIsImageError(true)}
    />
  )
}

export default WHMAsyncImage