import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import CommentsTitles from '../../../features/Community/Reports/CommentsTitles'
import { reportPostSelector, setOriginalPost } from '../../../features/Community/Reports/reportPostSlice'
import { clearReport, reportSelector, selectReport } from '../../../features/Community/Reports/reportSlice'
import { deleteReportWithIds } from '../../../features/Community/Reports/reportsSlice'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { jumpToUserAbout } from '../../../features/Users/Users/userSlice'
import { useLazyGetPostQuery, useLazyGetReportQuery, useRemovePostMutation, useRemoveReportMutation } from '../../../services/ReportService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { PostCard, UserCard, WHMButton, WHMDetailTitle, WHMTitleBlock } from '../../atoms/atoms'
import { InputSkeleton, UserCardSkeleton } from '../../molecules/molecules'
import { DashboardDetails } from '../../templates/templates'

const ReportDetail = () => {
  const navigate = useNavigate()
  const reportState = useWHMSelector(reportSelector)
  const reportPostState = useWHMSelector(reportPostSelector)
  const { postId, user, userId, _count:count } = reportState.report
  const dispatch = useWHMDispatch()

  const [ getReport, { data: reportData, isLoading: reportLoading } ] = useLazyGetReportQuery()
  const [ getPost, { data: postData, isLoading: postLoading } ] = useLazyGetPostQuery()
  const [ keepPost ] = useRemoveReportMutation()
  const [ deletePost ] = useRemovePostMutation()

  useEffect(() => {
    if (!postId) return
    getReport({ postId: postId, userId: userId })
    getPost(postId)
  },[postId, userId])

  useEffect(() => {
    reportData && dispatch(selectReport({ id: reportData.postId, report: reportData }))
    postData && dispatch(setOriginalPost(postData))
  },[reportData, postData])

  const keepReportedPost = async (combinedIds: string) => {
    const splitPostId = combinedIds.split(',')[0]
    const splitUserId = combinedIds.split(',')[1]
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Keeping this post' }))
      const [ data ] = await Promise.all([
        keepPost({ postId: splitPostId, userId: splitUserId }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(clearReport())
      dispatch(deleteReportWithIds({ postId, userId }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'REPORTED POST DELETED',
        body: 'You have successfully deleted the report!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete reported post',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const removeReportedPost = async (combinedIds: string) => {
    const splitPostId = combinedIds.split(',')[0]
    const splitUserId = combinedIds.split(',')[1]
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Removing this post' }))
      const [ data ] = await Promise.all([
        deletePost(splitPostId).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(clearReport())
      dispatch(deleteReportWithIds({ postId: splitPostId, userId: splitUserId }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'POST DELETED',
        body: 'You have successfully deleted the post!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete post',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const visitUserProfile = (userId: string | undefined) => {
    if (!userId) return
    navigate('/users-board')
    dispatch(jumpToUserAbout(userId))
  }
  
  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <WHMTitleBlock title='Reported Post' />
      </div>
      <DashboardDetails template='grid-cols-2lg'>
      <>
        {/* Reported Post */}
        <div className='px-3 pb-4'>
          <WHMDetailTitle 
            title='Report Actions'
            details='Choose how you would like to handle this report. You can keep the existing post of the app or removed the post entirely.'
            margin='mb-1'
          />
          <WHMButton 
            text='Keep Post'
            className='blue-button'
            onSubmit={() => dispatch(openModal({
              modalType: MODAL_TYPES.DELETE,
              deleteId: `${postId},${userId}`,
              deleteFn: keepReportedPost
            }))}
          />
          <WHMButton 
            text='Remove Post'
            className='remove-post-button'
            onSubmit={() => dispatch(openModal({
              modalType: MODAL_TYPES.DELETE,
              deleteId: `${postId},${userId}`,
              deleteFn: removeReportedPost
            }))}
          />

          <p className='font-semibold text-WHMBlack mb-1 ml-1'>
            Original Post
          </p>
          <PostCard 
            post={{...reportPostState.post, 
              likeCount: count?.postLike ?? 0,
              commentCount: count?.postComment ?? 0,
            }} 
          />

          <div className='h-full w-full overflow-y-auto overflow-x-hidden pb-[10em]'>
            <p className='font-semibold text-WHMBlack mb-1 mt-3 ml-1'>
              Post Comments
            </p>
            <CommentsTitles postId={postId}/>
          </div>
        </div>

        {/* Poster & Reporter */}
        <div className='px-3 pb-4'>
          <WHMDetailTitle 
            title='Original Poster'
            details='These are the details of the original poster of this community post.'
            margin='mb-1'
          />
          {postLoading ? (<UserCardSkeleton />) : (
            <UserCard 
              avatar={reportPostState.post.author?.avatar}
              firstName={reportPostState.post.author?.firstName}
              lastName={reportPostState.post.author?.lastName}
              btnFn={() => visitUserProfile(reportPostState.post.author.id)}
            />
          )}
          <WHMDetailTitle 
            title='Post Reporter'
            details='These are the details of the post reporter.'
            margin='mb-1'
          />
          {reportLoading ? (<UserCardSkeleton />) : (
            <UserCard 
              avatar={user?.avatar}
              firstName={user?.firstName}
              lastName={user?.lastName}
              btnFn={() => visitUserProfile(user?.id)}
            />
          )}
          {reportLoading ? (<InputSkeleton />) : (
          <div className='bg-WHMRed/30 rounded-[10px] px-3 py-2 mx-2 my-4'>
            <p className='text-WHMRed font-bold'>Reported For...</p>
            <p>
              This post was reported for:
              <span className='text-WHMRed ml-2'>{reportState.report.reportName}</span>
            </p>
          </div>
          )}
        </div>
      </>
      </DashboardDetails>
    </>
  )
}

export default ReportDetail