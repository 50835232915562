import { useEffect, useState } from 'react'
import { ExerciseFocusTypes, RFC, WorkoutFocusTypes } from '../../../types/propTypes'
import { ALL_TITLES } from '../../../assets/data/enums'
import { useWHMDispatch } from '../../../app/hooks'
// Service Types
import { 
    ProgramFeedbacksData, HelpTicketsData, 
    ProgramsData, WorkoutsData, ExercisesData, 
    CoursesData, LessonsData, 
    ReportsData, GroupsData, PostsData, 
    UsersData, 
} from '../../../types/serviceTypes'
// Services
import { useLazyGetFeedbacksQuery } from '../../../services/FeedbackService'
import { useLazyGetHelpTicketsQuery } from '../../../services/HelpTicketService'
import { useLazyGetProgramsQuery } from '../../../services/ProgramService'
import { useLazyGetWorkoutsQuery } from '../../../services/WorkoutService'
import { useLazyGetExercisesQuery } from '../../../services/ExerciseService'
import { useLazyGetCoursesQuery } from '../../../services/CourseService'
import { useLazyGetLessonsQuery } from '../../../services/LessonService'
import { useLazyGetReportsQuery } from '../../../services/ReportService'
import { useLazyGetGroupsQuery } from '../../../services/GroupService'
import { useLazyGetGroupPostsQuery } from '../../../services/GroupService'
import { useLazyGetUsersQuery } from '../../../services/UserService'
// Dispatch
import { setInitialFeedbacks, setFeedbacks, clearFeedbacks } from '../../../features/Notice/ProgramFeedback/feedbacksSlice'
import { setInitialHelpTickets, setHelpTickets, clearHelpTickets } from '../../../features/Notice/HelpTickets/helpTicketsSlice'
import { clearPrograms, setInitialPrograms, setPrograms } from '../../../features/Training/Programs/programsSlice'
import { clearWorkouts, setInitialWorkouts, setWorkouts } from '../../../features/Training/Workouts/workoutsSlice'
import { setInitialExercises, setExercises, clearExercises } from '../../../features/Training/Exercises/exercisesSlice'
import { setInitialCourses, setCourses, clearCourses } from '../../../features/Resources/Courses/coursesSlice'
import { clearLessons, setInitialLessons, setLessons } from '../../../features/Resources/Lessons/lessonsSlice'
import { clearReports, setInitialReports, setReports } from '../../../features/Community/Reports/reportsSlice'
import { setInitialGroups, setGroups, clearGroups} from '../../../features/Community/Groups/groupsSlice'
import { setInitialGroupPosts, setGroupPosts, clearGroupPosts} from '../../../features/Community/Groups/groupSlice'
import { clearUsers, setInitialUsers, setUsers } from '../../../features/Users/Users/usersSlice'
// Components
import InfiniteScroll from 'react-infinite-scroller'
import { CardLoading } from '../../atoms/atoms'
import { 
    FeedbackTitles, HelpTicketTitles, 
    ProgramTitles, WorkoutTitles, ExerciseTitles, 
    CourseTitles, LessonTitles, 
    ReportTitles, GroupTitles, GroupPostTitles, 
    UserTitles,
} from '../../molecules/molecules'
import { userTypeFormatter } from '../../../utils/userInfoFormatter'

type InfiniteScrollListProps = {
    page:       ALL_TITLES
    searchText: string
    category?:  string
    pb?:        boolean
    onRemoveNew?: () => void
}

const InfiniteScrollList:RFC<InfiniteScrollListProps> = ({ 
    page, 
    searchText, 
    category, 
    pb,
    onRemoveNew 
}) => {
    const perPage = 10
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const dispatch = useWHMDispatch()

    // Get All Items APIs
    const [ getFeedbacks, { isLoading: feedbacksLoading, isError: feedbacksError } ] = useLazyGetFeedbacksQuery()
    const [ getHelpTickets, { isLoading: helpTicketsLoading, isError: helpTicketsError } ] = useLazyGetHelpTicketsQuery()
    const [ getPrograms, { isLoading: programsLoading, isError: programsError } ] = useLazyGetProgramsQuery()
    const [ getWorkouts, { isLoading: workoutsLoading, isError: workoutsError } ] = useLazyGetWorkoutsQuery()
    const [ getExercises, { isLoading: exerciseLoading, isError: exerciseError } ] = useLazyGetExercisesQuery()
    const [ getCourses, { isLoading: coursesLoading, isError: coursesError } ] = useLazyGetCoursesQuery()
    const [ getLessons, { isLoading: lessonsLoading, isError: lessonsError } ] = useLazyGetLessonsQuery()
    const [ getReports, { isLoading: reportsLoading, isError: reportsError } ] = useLazyGetReportsQuery()
    const [ getGroups, { isLoading: groupsLoading, isError: groupsError } ] = useLazyGetGroupsQuery()
    const [ getGroupPosts, { isLoading: groupPostsLoading, isError: groupPostsError } ] = useLazyGetGroupPostsQuery()
    const [ getUsers, { isLoading: usersLoading, isError: usersError } ] = useLazyGetUsersQuery()

    useEffect(() => {
        setCurrentPage(0)
        loadMoreItems(10, 0)
    },[searchText, category])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(perPage, currentPage)
    }

    const loadMoreItems = async (perPage: number, currentPage: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const items = await switchService(currentPage, perPage, searchText)
            
            if (!items?.data.length && currentPage === 0) clearList()
            if (!items || !items.data.length) {
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    resetList(items)
                    setHasMore(true)
                    setCurrentPage(1)
                } else if (items.pagination.lastPage === currentPage) {
                    setItems(items)
                    setHasMore(false)
                } else {
                    setItems(items)
                    setHasMore(true)
                    setCurrentPage(prev => prev + 1)
                }
            }
    
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    const switchService = (currentPage: number, perPage: number, searchText: string) => {
        const lazyLoadingObj = {
            currentPage,
            perPage,
            filter: searchText
        }
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return getFeedbacks(lazyLoadingObj).unwrap()
            case ALL_TITLES.HELP_TICKETS:
                return getHelpTickets(lazyLoadingObj).unwrap()
            case ALL_TITLES.PROGRAM:
                return getPrograms(lazyLoadingObj).unwrap()
            case ALL_TITLES.WORKOUTS:
                return getWorkouts({ ...lazyLoadingObj, filterByFocus: category as WorkoutFocusTypes }).unwrap()
            case ALL_TITLES.EXERCISES:
                return getExercises({ ...lazyLoadingObj, filterByFocus: category as ExerciseFocusTypes }).unwrap()
            case ALL_TITLES.COURSES:
                return getCourses(lazyLoadingObj).unwrap()
            case ALL_TITLES.LESSONS:
                return getLessons(lazyLoadingObj).unwrap()
            case ALL_TITLES.REPORTS:
                return getReports(lazyLoadingObj).unwrap()
            case ALL_TITLES.GROUPS:
                return getGroups(lazyLoadingObj).unwrap()
            case ALL_TITLES.GROUP_POSTS:
                return getGroupPosts(lazyLoadingObj).unwrap()
            case ALL_TITLES.ALL_USERS:
                return getUsers({ ...lazyLoadingObj, isSubscribed: userTypeFormatter(category) }).unwrap()
            default:
                return
        }
    }

    const clearList = () => {
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return dispatch(clearFeedbacks())
            case ALL_TITLES.HELP_TICKETS:
                return dispatch(clearHelpTickets())
            case ALL_TITLES.PROGRAM:
                return dispatch(clearPrograms())
            case ALL_TITLES.WORKOUTS:
                return dispatch(clearWorkouts())
            case ALL_TITLES.EXERCISES:
                return dispatch(clearExercises())
            case ALL_TITLES.COURSES:
                return dispatch(clearCourses())
            case ALL_TITLES.LESSONS:
                return dispatch(clearLessons())
            case ALL_TITLES.REPORTS:
                return dispatch(clearReports())
            case ALL_TITLES.GROUPS:
                return dispatch(clearGroups())
            case ALL_TITLES.GROUP_POSTS:
                return dispatch(clearGroupPosts())
            case ALL_TITLES.ALL_USERS:
                return dispatch(clearUsers())
            default:
                return
        }
    }

    const resetList = (items: any) => {
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return dispatch(setInitialFeedbacks({ feedbacks: items as ProgramFeedbacksData }))
            case ALL_TITLES.HELP_TICKETS:
                return dispatch(setInitialHelpTickets({ helpTickets: items as HelpTicketsData }))
            case ALL_TITLES.PROGRAM:
                return dispatch(setInitialPrograms({ programs: items as ProgramsData }))
            case ALL_TITLES.WORKOUTS:
                return dispatch(setInitialWorkouts({ workouts: items as WorkoutsData }))
            case ALL_TITLES.EXERCISES:
                return dispatch(setInitialExercises({ exercises: items as ExercisesData }))
            case ALL_TITLES.COURSES:
                return dispatch(setInitialCourses({ courses: items as CoursesData }))
            case ALL_TITLES.LESSONS:
                return dispatch(setInitialLessons({ lessons: items as LessonsData }))
            case ALL_TITLES.REPORTS:
                return dispatch(setInitialReports({ reports: items as ReportsData }))
            case ALL_TITLES.GROUPS:
                return dispatch(setInitialGroups({ groups: items as GroupsData }))
            case ALL_TITLES.GROUP_POSTS:
                return dispatch(setInitialGroupPosts(items as PostsData))
            case ALL_TITLES.ALL_USERS:
                return dispatch(setInitialUsers({ users: items as UsersData }))
            default:
                return
        }
    }

    const setItems = (items: any) => {
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return dispatch(setFeedbacks({ feedbacks: items as ProgramFeedbacksData }))
            case ALL_TITLES.HELP_TICKETS:
                return dispatch(setHelpTickets({ helpTickets: items as HelpTicketsData }))
            case ALL_TITLES.PROGRAM:
                return dispatch(setPrograms({ programs: items as ProgramsData }))
            case ALL_TITLES.WORKOUTS:
                return dispatch(setWorkouts({ workouts: items as WorkoutsData }))
            case ALL_TITLES.EXERCISES:
                return dispatch(setExercises({ exercises: items as ExercisesData }))
            case ALL_TITLES.COURSES:
                return dispatch(setCourses({ courses: items as CoursesData }))
            case ALL_TITLES.LESSONS:
                return dispatch(setLessons({ lessons: items as LessonsData }))
            case ALL_TITLES.REPORTS:
                return dispatch(setReports({ reports: items as ReportsData }))
            case ALL_TITLES.GROUPS:
                return dispatch(setGroups({ groups: items as GroupsData }))
            case ALL_TITLES.GROUP_POSTS:
                return dispatch(setGroupPosts( items as PostsData ))
            case ALL_TITLES.ALL_USERS:
                return dispatch(setUsers({ users: items as UsersData }))
            default:
                return
        }
    }

    const renderTitleCards = () => {
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return <FeedbackTitles 
                    isLoading={feedbacksLoading}
                    isError={feedbacksError}
                />
            case ALL_TITLES.HELP_TICKETS:
                return <HelpTicketTitles 
                    isLoading={helpTicketsLoading}
                    isError={helpTicketsError}
                />
            case ALL_TITLES.PROGRAM:
                if (!onRemoveNew) return <></>
                return <ProgramTitles 
                    isLoading={programsLoading}
                    isError={programsError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.WORKOUTS:
                if (!onRemoveNew) return <></>
                return <WorkoutTitles 
                    isLoading={workoutsLoading}
                    isError={workoutsError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.EXERCISES:
                if (!onRemoveNew) return <></>
                return <ExerciseTitles 
                    isLoading={exerciseLoading}
                    isError={exerciseError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.COURSES:
                if (!onRemoveNew) return <></>
                return <CourseTitles 
                    isLoading={coursesLoading}
                    isError={coursesError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.LESSONS:
                if (!onRemoveNew) return <></>
                return <LessonTitles 
                    isLoading={lessonsLoading}
                    isError={lessonsError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.REPORTS:
                return <ReportTitles 
                    isLoading={reportsLoading}
                    isError={reportsError}
                />
            case ALL_TITLES.GROUPS:
                if (!onRemoveNew) return <></>
                return <GroupTitles 
                    isLoading={groupsLoading}
                    isError={groupsError}
                    onRemoveNew={onRemoveNew}
                />
            case ALL_TITLES.GROUP_POSTS:
                return <GroupPostTitles 
                    isLoading={groupPostsLoading}
                    isError={groupPostsError}
                />
            case ALL_TITLES.ALL_USERS:
                return <UserTitles 
                    isLoading={usersLoading}
                    isError={usersError}
                />
            default:
                return
        }
    }

    return (
        <div className={`w-full h-screen overflow-y-auto overflow-x-hidden ${pb ? 'pb-[16em]' : 'pb-[13em]'}`}>
            <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                loader={<CardLoading key={0}/>}
                initialLoad={true}
                threshold={150}
            >
                { renderTitleCards() }
            </InfiniteScroll>
        </div>
    )
}

export default InfiniteScrollList