import { WHMApi } from '../app/api'
import { GroupsData, PaginationPayload, PostsData } from '../types/serviceTypes'
import { Group, Post } from '../types/stateTypes'

export const groupApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query<GroupsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: `/admin/groups`,
                params: { currentPage, perPage, filter }
            }),
            providesTags: ['Group']
        }),
        getGroupPosts: builder.query<PostsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/posts',
                params: { currentPage, perPage, filterByGroup: filter }
            }),
            providesTags: ['Group']
        }),
        createGroup: builder.mutation<Group, Pick<Group, 'name' | 'isActive'>>({
            query: ({ name, isActive }) => ({
                url: '/admin/groups',
                method: 'POST',
                body: {
                    isActive: isActive === 1 ? true : false,
                    name
                }
            }),
            invalidatesTags: ['Group']
        }),
        updateGroup: builder.mutation<
            Group, 
            Partial<Group> & { groupName?: string }>
        ({
            query: ({ name, isActive, groupName }) => ({
                url: `/admin/groups/${groupName}`,
                method: 'PATCH',
                body: {
                    isActive: (isActive || isActive === 1) ? true : false,
                    name
                }
            }),
            invalidatesTags: ['Group']
        }),
        deleteGroup: builder.mutation<Group, Pick<Group, 'name'>>({
            query: ({ name }) => ({
                url: `/admin/groups/${name}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Group']
        }),
        deletePost: builder.mutation<Post, string>({
            query: (postId) => ({
                url: `/admin/posts/${postId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Group']
        })
    })
})

export const {
    useLazyGetGroupsQuery,
    useLazyGetGroupPostsQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useDeletePostMutation,
} = groupApi