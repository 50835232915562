import { useWHMDispatch, useWHMSelector } from '../../app/hooks'
import { changePhaseTab, programSelector, removeProgramWorkout, sortProgramWorkouts } from '../../features/Training/Programs/programSlice'
import { phaseTabs } from '../../assets/data/arrays'
import { WHMDetailTabs } from '../atoms/atoms'
import { ProgramDnDListField } from './molecules'
import { openModal } from '../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../assets/data/enums'
import getPhaseTabIndex from '../../utils/getPhaseTabIndex'

const MultiDnDLists = () => {
    const { program, phaseTab } = useWHMSelector(programSelector)
    const { phase } = program
    const dispatch = useWHMDispatch()

    return (
        <div>
            <WHMDetailTabs 
                tabs={phaseTabs}
                selectedTab={phaseTab}
                isPhase={true}
                onSelectTab={(tab) => dispatch(changePhaseTab(tab))}
            />
            <div className='px-3 py-2'>
                <ProgramDnDListField
                    list={phase && phase[getPhaseTabIndex(phaseTab)].phaseWorkout}
                    sortItems={(items) => dispatch(sortProgramWorkouts(items))}
                    removeItem={(item) => dispatch(removeProgramWorkout(item))}
                    openModal={() => dispatch(openModal({ modalType: MODAL_TYPES.ADD_WORKOUT }))}
                />
            </div>
        </div>
    )
}

export default MultiDnDLists