import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import ProgramsTitles from '../../../features/Training/Programs/ProgramsTitles'

type ProgramsListProps = {
  isNewProgram: boolean
  setIsNewProgram: (isOpen: boolean) => void
}

const ProgramsList:RFC<ProgramsListProps> = ({ 
  isNewProgram,
  setIsNewProgram
}) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock 
        title='Programs'
        btnTitle='New'
        onClick={() => setIsNewProgram(!isNewProgram)}
      />
      <WHMSearchbar onChange={onChangeText}/>
      <ProgramsTitles 
        isNewProgram={isNewProgram}
        searchText={searchText}
        onRemoveNew={() => setIsNewProgram(false)}
      />
    </>
  )
}

export default ProgramsList