import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../app/store' 
import { InitialProgramsState } from '../../../assets/data/InitialState'
import { ProgramsData } from '../../../types/serviceTypes'
import { Programs } from '../../../types/stateTypes'

export type ProgramsSliceType = {
    programs: ProgramsData
}

export const programsSlice = createSlice({
    name: 'programs',
    initialState: InitialProgramsState,
    reducers: {
        clearPrograms: (state) => {
            state.programs.data = []
        },
        setInitialPrograms: (state, action:PayloadAction<ProgramsSliceType>) => {
            const updatedOrder = action.payload.programs.data.slice().sort((a, b) => 
                parseInt(moment(b.updatedAt).format('X')) - parseInt(moment(a.updatedAt).format('X'))
            )
            state.programs.data = updatedOrder
        },
        setPrograms: (state, action:PayloadAction<ProgramsSliceType>) => {
            const { data: storedPrograms } = state.programs
            const { data: newPrograms } = action.payload.programs
            const programIds = storedPrograms.map(program => program.id)
            const filteredDuplicates = newPrograms.filter(program => !programIds.includes(program.id))
            const updatedOrders = filteredDuplicates.slice().sort((a, b) => 
                parseInt(moment(b.updatedAt).format('X')) - parseInt(moment(a.updatedAt).format('X'))
            )
            updatedOrders.map(program => state.programs.data.push(program))
        },
        addProgram: (state, action:PayloadAction<Programs>) => {
            state.programs.data.unshift(action.payload)
        },
        updateProgramInfo: (state, action:PayloadAction<Programs>) => {
            const filteredPrograms = state.programs.data.filter(program => program.id !== action.payload.id)
            state.programs.data = [ action.payload, ... filteredPrograms ]
        },
        deleteProgramWithId: (state, action:PayloadAction<string>) => {
            const filteredPrograms = state.programs.data.filter(program => program.id !== action.payload)
            state.programs.data = filteredPrograms
        }
    }
})

export const programsSelector = (state: RootState) => state.programs
export const {
    clearPrograms,
    setInitialPrograms,
    setPrograms,
    addProgram,
    updateProgramInfo,
    deleteProgramWithId
} = programsSlice.actions
export default programsSlice.reducer