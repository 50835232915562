import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialReportPostState } from '../../../assets/data/InitialState'
import { Post } from '../../../types/stateTypes'
import { CommentsData } from '../../../types/serviceTypes'

export type ReportPostSliceType = {
    post:     Post
    comments: CommentsData
}

export const reportPostSlice = createSlice({
    name: 'reportPost',
    initialState: InitialReportPostState,
    reducers: {
        setOriginalPost: (state, action:PayloadAction<Post>) => {
            state.post = action.payload
        },
        setInitialComments: (state, action: PayloadAction<CommentsData>) => {
            state.comments.data = action.payload.data
        },
        setComments: (state, aciton:PayloadAction<CommentsData>) => {
            const { data: storedComments } = state.comments
            const { data: newComments } = aciton.payload
            const commentIds = storedComments.map(comment => comment.id)
            const filteredDuplicates = newComments.filter(comment => !commentIds.includes(comment.id))
            filteredDuplicates.map(comment => state.comments.data.push(comment))
        },
        clearComments: (state) => {
            state.comments.data = []
        }
    }
})

export const reportPostSelector = (state: RootState) => state.reportPost
export const {
    setOriginalPost,
    setInitialComments,
    setComments,
    clearComments,
} = reportPostSlice.actions
export default reportPostSlice.reducer