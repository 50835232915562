const editIcon = ({ height, width, color, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'} 
            height={height ?? '24'} 
            viewBox="0 0 24 24"
            onClick={onClick}
        >
        <g id="Group_200" data-name="Group 200">
            <rect id="Rectangle_360" data-name="Rectangle 360" width="24" height="24" fill="none"/>
        </g>
        <g id="Group_202" data-name="Group 202">
            <g id="Group_201" data-name="Group 201">
            <path id="Path_895" data-name="Path 895" d="M12.06,7.19,3,16.25V20H6.75l9.06-9.06ZM5.92,18H5v-.92l7.06-7.06.92.92Z" fill="#5e257d"/>
            <path id="Path_896" data-name="Path 896" d="M18.71,8.04a1,1,0,0,0,0-1.41L16.37,4.29A.982.982,0,0,0,15.66,4a1,1,0,0,0-.7.29L13.13,6.12l3.75,3.75Z" fill="#5e257d"/>
            </g>
        </g>
        </svg>
      </div>
    )
}

export default editIcon