const SuggestSkeleton = () => {
  return (
    <>
        <div className='w-full pl-3'>
            {Array(4).fill('').map((_,i) => (
            <div
            key={i}
            role='status'
            className='w-full my-2 animate-pulse'
            >
                <div className='h-12 w-[70%] bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey'></div>
            </div>
            ))}
        </div>
        <div className='w-full pl-3'>
            {Array(4).fill('').map((_,i) => (
            <div
            key={i}
            role='status'
            className='w-full my-2 animate-pulse'
            >
                <div className='h-12 w-[70%] bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey'></div>
            </div>
            ))}
        </div>
        <div className='w-full pl-3'>
            {Array(4).fill('').map((_,i) => (
            <div
            key={i}
            role='status'
            className='w-full my-2 animate-pulse'
            >
                <div className='h-12 w-[70%] bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey'></div>
            </div>
            ))}
        </div>
    </>
  )
}

export default SuggestSkeleton