import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import LessonsTitles from '../../../features/Resources/Lessons/LessonsTitles'

type LessonsListProps = {
  isNewLesson: boolean
  setIsNewLesson: (isOpen: boolean) => void
}

const LessonsList:RFC<LessonsListProps> = ({ isNewLesson, setIsNewLesson }) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock 
        title='Lessons'
        btnTitle='New'
        onClick={() => setIsNewLesson(!isNewLesson)}
      />
      <WHMSearchbar onChange={onChangeText}/>
      <LessonsTitles 
        isNewLesson={isNewLesson}
        searchText={searchText}
        onRemoveNew={() => setIsNewLesson(false)} 
      />
    </>
  )
}

export default LessonsList