import { RFC } from '../../../types/propTypes'
import { ProgramSuggestExperience, ProgramSuggestGoal, ProgramSuggestTraining } from '../../../types/stateTypes'

type WHMSuggestListProps = {
    title:        string
    list:         string[] | undefined
    isRequired:   boolean
    selectedList: 
        ProgramSuggestGoal[]       |
        ProgramSuggestExperience[] |
        ProgramSuggestTraining[]   |
        undefined
    updateItem:   (text: string) => void
}

const WHMSuggestList:RFC<WHMSuggestListProps> = ({
    title,
    list,
    isRequired,
    selectedList,
    updateItem,
}) => {
    const selectedNames = () => {
        switch (title) {
            case 'Goal':
                const goalList = selectedList as ProgramSuggestGoal[]
                return goalList?.map(item => item.suggestGoalName) 
            case 'Experience':
                const experienceList = selectedList as ProgramSuggestExperience[]
                return experienceList?.map(item => item.suggestExperienceName) 
            case 'Training':
                const trainingList = selectedList as ProgramSuggestTraining[]
                return trainingList?.map(item => item.suggestTrainingName) 
            default:
                return
        }
    }
    return (
        <div className='w-full text-center'>
            <p className='text-WHMGold text-xl font-semibold mb-2'>
                {title}&nbsp;
                <span className='text-WHMGold'>{isRequired && '*'}</span>
            </p>
            <div className='w-full text-center px-2'>
            {list?.map((item, index) => (
                <div
                    key={index}
                    className={`border-WHMBorder border-[1px] border-solid rounded-[5px]
                    text-2xl font-semibold italic py-2 mb-2 cursor-pointer
                    hover:bg-WHMDarkPurple/40 hover:text-WHMWhite transition-all duration-200
                    ${selectedNames()?.includes(item) 
                        ? 'bg-WHMDarkPurple/40 text-WHMWhite' 
                        : 'text-WHMDark/80'
                    }`}
                    onClick={() => updateItem(item)}
                >
                    {item}
                </div>
            ))}
            </div>
        </div>
    )
}

export default WHMSuggestList