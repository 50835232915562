import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch } from '../../../app/hooks'
import { useLazyGetCommentsQuery } from '../../../services/ReportService'
import { setComments, setInitialComments } from './reportPostSlice'
import { AnimatePresence } from 'framer-motion'
import InfiniteScroll from 'react-infinite-scroller'
import { CardLoading } from '../../../components/atoms/atoms'
import { CommentTitles } from '../../../components/molecules/molecules'

type CommentsTitlesProps = {
    postId: string
}

const CommentsTitles:RFC<CommentsTitlesProps> = ({ postId }) => {
    const perPage = 10
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const dispatch = useWHMDispatch()

    const [ getComments, { isLoading, isError } ] = useLazyGetCommentsQuery()

    useEffect(() => {
        setCurrentPage(0)
        loadMoreItems(10, 0)
    },[postId])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(perPage, currentPage)
    }

    const loadMoreItems = async (perPage: number, currentPage: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const items = await getComments({ currentPage, perPage, postId }).unwrap()
    
            if (!items || !items.data.length) {
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    dispatch(setInitialComments(items))
                    setHasMore(true)
                    setCurrentPage(1)
                } else if (items.pagination.lastPage === currentPage) {
                    dispatch(setComments(items))
                    setHasMore(false)
                } else {
                    dispatch(setComments(items))
                    setHasMore(true)
                    setCurrentPage(prev => prev + 1)
                }
            }
    
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    return (
        <div className='w-full mt-4'>
            <AnimatePresence>
                <div className='w-full h-full overflow-y-auto overflow-x-hidden pb-[20em]'>
                    <InfiniteScroll 
                        loadMore={fetchMoreData}
                        hasMore={hasMore}
                        useWindow={false}
                        loader={<CardLoading key={0}/>}
                        initialLoad={true}
                        threshold={150}
                    >
                        <CommentTitles 
                            isLoading={isLoading}
                            isError={isError}
                        />
                    </InfiniteScroll>
                </div>
            </AnimatePresence>
        </div>
    )
}

export default CommentsTitles