import { RFC } from '../../../types/propTypes'
import { WHMButton } from '../atoms'

type WHMTitleBlockProps = {
    title:      string
    btnTitle?:  string
    onClick?: () => void
}

const WHMTitleBlock:RFC<WHMTitleBlockProps> = ({ 
    title,
    btnTitle,
    onClick,
}) => {

    return (
        <div className='w-full flex items-center justify-between px-3 py-2'>
            <div className='text-WHMDarkPurple font-bold text-[1.6em] max-w-[15em] truncate'>
                {title}
            </div>
            {btnTitle && onClick && (
                <WHMButton 
                    text='New'
                    className='small-dark-purple-button'
                    onSubmit={onClick}
                />
            )}
        </div>
    )
}

export default WHMTitleBlock