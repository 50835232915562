import { Dialog } from '@headlessui/react'
import { useWHMDispatch, useWHMSelector } from '../../app/hooks'
import { closeModal, modalSelector } from '../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../assets/data/enums'
import { 
    AddExerciseModal, AddLessonModal, AddWorkoutModal, AlternativesModal, 
    FailModal, HomeAlternativeModal, SuccessModal 
} from '../molecules/molecules'
import DeleteModal from '../molecules/Modals/DeleteModal'

const WHMModal = () => {
    const modalState = useWHMSelector(modalSelector)
    const dispatch = useWHMDispatch()

    const renderModal = () => {
        switch (modalState.modalType) {
            case MODAL_TYPES.ADD_WORKOUT:      return <AddWorkoutModal />
            case MODAL_TYPES.ADD_EXERCISES:    return <AddExerciseModal />
            case MODAL_TYPES.ADD_LESSON:       return <AddLessonModal />
            case MODAL_TYPES.HOME_ALTERNATIVE: return <HomeAlternativeModal />
            case MODAL_TYPES.ALTERNATIVES:     return <AlternativesModal />
            case MODAL_TYPES.SUCCESS:          return <SuccessModal />
            case MODAL_TYPES.FAIL:             return <FailModal />
            case MODAL_TYPES.DELETE:           return <DeleteModal />
            default: return null
        }
    }

    return (
        <Dialog
            open={modalState.isDisplayed}
            onClose={() => dispatch(closeModal())}
            className='fixed inset-0 z-50 overflow-y-auto flex justify-center items-center'
        >
            <Dialog.Overlay className='fixed inset-0 bg-WHMDark/50'/>
            <Dialog.Panel className='relative bg-white rounded-[20px] p-6'>
                { renderModal() }
            </Dialog.Panel>
        </Dialog>
    )
}

export default WHMModal