import { RFC } from '../../../types/propTypes'
import { SearchIcon } from '../../../assets/icons/icons'

type WHMSearchbarProps = {
    style?: string
    onChange: (text: string) => void
}

const WHMSearchbar:RFC<WHMSearchbarProps> = ({ style, onChange }) => {
  return (
    <form 
      className='flex items-center'
      onSubmit={e => e.preventDefault()}
    >
      <div 
        className='relative w-full flex justify-between items-center  
        bg-WHMDarkWhite border-[1px] border-solid border-WHMBorder
        mx-2 rounded-lg hover:shadow-focused selection:shadow-focused
        transition-all duration-200'
      >
        <input 
          type='text'
          className={`w-full mx-1 pl-2 ${style ?? 'py-1'} bg-WHMDarkWhite outline-none`}
          placeholder='Search'
          onChange={(e) => onChange(e.target.value)}
        />
        <SearchIcon 
          className='flex items-center px-1 pointer-events-none text-WHMDarkGrey'
        />
      </div>
    </form>
  )
}

export default WHMSearchbar