import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { selectWorkout, workoutSelector } from '../../../features/Training/Workouts/workoutSlice'
import { useCreateWorkoutMutation, useDeleteWorkoutMutation, useLazyGetWorkoutQuery, useUpdateWorkoutMutation } from '../../../services/WorkoutService'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { addWorkout, deleteWorkoutWithId, updateWorkoutInfo } from '../../../features/Training/Workouts/workoutsSlice'
import { exerciseDetailSelector, removeExerciseGroup, setInitialExerciseList } from '../../../features/Training/Workouts/exerciseDetailSlice'
import { clearWorkout } from '../../../features/Training/Workouts/workoutSlice'
import { WHMDetailTabs, WHMTitleBlock } from '../../atoms/atoms'
import { InputSkeleton, StatusButtons } from '../../molecules/molecules'
import { AboutWorkoutTab, ExercisesTab } from '../organisms'
import { workoutTabs } from '../../../assets/data/arrays'
import asyncTimeout from '../../../utils/asyncTimeout'

type WorkoutDetailsProps = {
    isNewWorkout?:    boolean
    setIsNewWorkout?: (close: boolean) => void
}

const WorkoutDetails:RFC<WorkoutDetailsProps> = ({
    isNewWorkout,
    setIsNewWorkout
}) => {
    const [ workoutTab, setWorkoutTab ] = useState('About')
    // Global State
    const workoutState = useWHMSelector(workoutSelector)
    const exerciseDetailState = useWHMSelector(exerciseDetailSelector)
    const { id, title, description, focus, phaseWorkout } = workoutState.workout
    const dispatch = useWHMDispatch()

    // Service
    const [ getWorkout, { data: workoutData, isLoading: workoutLoading } ] = useLazyGetWorkoutQuery()
    const [ createWorkout ] = useCreateWorkoutMutation()
    const [ updateWorkout ] = useUpdateWorkoutMutation()
    const [ deleteWorkout ] = useDeleteWorkoutMutation()
    
    useEffect(() => {
        id && getWorkout(id)
    },[id])

    useEffect(() => {
        if (!workoutData) return
            dispatch(selectWorkout({ id: workoutData.id, workout: {
                ...workoutData,
                phaseWorkout: workoutData.phaseWorkout[0]
            } }))
            dispatch(setInitialExerciseList(workoutData.exerciseGroup))
            dispatch(removeExerciseGroup())
    },[workoutData])

    const createNewWorkout = async () => {
        try {
            dispatch(openOverlay({ text: 'Creating a new workout' }))
            const [ data ] = await Promise.all([
                createWorkout({
                    title:       title ?? '', 
                    description: description ?? '', 
                    focus:       focus ?? '',
                    exerciseGroups: exerciseDetailState.exerciseGroups
                }).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data || !setIsNewWorkout) throw Error
            setIsNewWorkout(false)
            dispatch(addWorkout(data))
            dispatch(selectWorkout({ id: data.id, workout: data }))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Workout Created',
                body: 'You have successfully created a new workout!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to create workout',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }
    
    const saveWorkout = async () => {
        try {
            dispatch(openOverlay({ text: 'Saving workout details '}))
            const [ data ] = await Promise.all([
                updateWorkout({
                    id, title, description, focus,
                    exerciseGroups: exerciseDetailState.exerciseGroups,
                    initialGroups:  exerciseDetailState.initialGroups
                }).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(updateWorkoutInfo(data))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Workout Updated',
                body: 'Your changes have been successfully saved!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to update workout',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }
    
    const clearNewWorkout = () => {
        if (!setIsNewWorkout) return
        setIsNewWorkout(false)
    }
    
    const removeWorkout = async (workoutId: string) => {
        try {
            dispatch(closeModal())
            dispatch(openOverlay({ text: 'Deleting this workout' }))
            const [ data ] = await Promise.all([
                deleteWorkout(workoutId).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(clearWorkout())
            dispatch(deleteWorkoutWithId(id))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'WORKOUT DELETED',
                body: 'You have successfully deleted the workout!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to delete workout',
                body: 'Something went wrong. Please try again later.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const renderWorkoutDetails = (workoutTab: string) => {
        switch (workoutTab) {
            case 'About':     return (<AboutWorkoutTab 
                isNewWorkout={isNewWorkout}
                isLoading={workoutLoading}
                />)
            case 'Exercises': return (<ExercisesTab 
                isLoading={workoutLoading}
                />)
            default:          return <></>
        }
    }

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <WHMTitleBlock title={isNewWorkout ? 'New Workout' : `${title}`}/>
                {workoutLoading ? (<InputSkeleton />) : (
                    <StatusButtons 
                        type='ct-d-s'
                        page='Program'
                        title={phaseWorkout?.phase?.program?.title ?? 'NA'}
                        onSave={isNewWorkout ? createNewWorkout : saveWorkout}
                        onDelete={isNewWorkout 
                            ? clearNewWorkout 
                            : () => dispatch(openModal({
                                modalType: MODAL_TYPES.DELETE,
                                deleteId: id,
                                deleteFn: removeWorkout
                            }))}
                    />
                )}
            </div>
            <WHMDetailTabs 
                tabs={workoutTabs}
                selectedTab={workoutTab}
                onSelectTab={(tab) => setWorkoutTab(tab)}
            />
            { renderWorkoutDetails(workoutTab) }
        </>
    )
}

export default WorkoutDetails