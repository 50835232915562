const commentIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'} 
            height={height ?? '24'} 
            viewBox="0 0 24 24"
        >
        <path id="Path_119" data-name="Path 119" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_120" data-name="Path 120" d="M4,18l2-2H20V4H4Z" fill="#d6a156" opacity="0.3"/>
        <path id="Path_121" data-name="Path 121" d="M20,2H4A2.006,2.006,0,0,0,2,4V22l4-4H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,14H6L4,18V4H20Z" fill="#5e257d"/>
        </svg>
      </div>
    )
  }
  
  export default commentIcon