import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useWHMDispatch, useWHMSelector } from './app/hooks'
import { overlaySelector } from './features/Modal/overlaySlice'
import { useLazyGetMeQuery } from './services/AuthService'
import { WHMSnackbar, WHMModal, WHMOverlay, WHMSplashScreen } from './components/atoms/atoms'
import { AuthRequiredRoutes, NotAuthRoutes } from './utils/CustomRoutes'
import { Community, Login, NoticeBoard, Resources, Training, Users } from './pages/pages'
import asyncTimeout from './utils/asyncTimeout'
import { setCredentials } from './features/Auth/authSlice'

const App = () => {
  const [ isLoadingUser, setIsLoadingUser ] = useState(true)
  const [ getMe ] = useLazyGetMeQuery()
  const overlayState = useWHMSelector(overlaySelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    getUserData()
  },[])

  const getUserData = async () => {
    try {
      const [ data ] = await Promise.all([
        getMe().unwrap(),
        asyncTimeout(2000)
      ])
      if (!data.accessToken.token) return
      // dispatch(setCredentials(data))
      localStorage.setItem('WHMAdminToken', data.accessToken.token)
    } catch (error) {
      console.log({error})
    } finally {
      setIsLoadingUser(false)
    }
  }

  if (isLoadingUser) return <WHMSplashScreen />

  return (
    <Router>
      <WHMSnackbar position='bottom_left' />
      <WHMModal />
      {overlayState.isLoading && (<WHMOverlay />)}

      <Routes>
        <Route path='/' element={<Navigate to='/login' replace />}/>

        <Route element={<NotAuthRoutes />}>
          <Route path='/login' element={<Login />} />
        </Route>

        <Route element={<AuthRequiredRoutes />}>
          <Route path='/notice-board'    element={<NoticeBoard />} />
          <Route path='/training-board'  element={<Training />} />
          <Route path='/resources-board' element={<Resources />} />
          <Route path='/community-board' element={<Community />} />
          <Route path='/users-board'     element={<Users />} />
        </Route>

        <Route path='*' element={<Login />} />
        
      </Routes>
    </Router>
  )
}

export default App