import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { groupSelector, setGroupPost } from '../../../features/Community/Groups/groupSlice'
import { domAnimation, LazyMotion, motion } from 'framer-motion'
import { CardError, CardLogo, PostCard } from '../../atoms/atoms'

type GroupPostTitlesProps = {
  isLoading: boolean
  isError:   boolean
}

const GroupPostTitles:RFC<GroupPostTitlesProps> = ({
    isLoading,
    isError,
}) => {
  const { post, posts } = useWHMSelector(groupSelector)
  const dispatch = useWHMDispatch()

  if (isLoading) return <CardLogo />
  if (isError)   return <CardError />
  if (!posts.data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No Post
    </div>
  )

  return (
    <LazyMotion features={domAnimation} key='group_post'>
      {posts.data.map((groupPost, index) => (
        <motion.div
          key={index}
          initial={{ x: -150, opacity: 0, scale: 0 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          exit={{ x: 150, opacity: 0 }}
          transition={{ stiffness: 80 }}
          className='my-2 pr-3'
          onClick={() => dispatch(setGroupPost(groupPost))}
        >
          <PostCard 
            post={groupPost}
            hoverable={true}
            isSelected={groupPost.id === post.id}
          />
        </motion.div>
      ))}
    </LazyMotion>
  )
}

export default GroupPostTitles