import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { PaginationPayloadForStripe, UserData } from '../../../types/serviceTypes'
import { useLazyGetStripePaymentIntentsQuery } from '../../../services/UserService'
import { PaypalDetails } from '../../../types/stateTypes'
import moment from 'moment'
import { DnDListSkeleton } from '../../molecules/molecules'
import { CardError, CardLoading } from '../../atoms/atoms'
import { calculateStripePrice, convertSubscriptionDate, subscriptionPeriodFormatter } from '../../../utils/userInfoFormatter'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearStripe, setInitialUserStripe, setUserStripe, userStripeSelector } from '../../../features/Users/Users/stripeSlice'
import InfiniteScroll from 'react-infinite-scroller'

type SubscriptionsListProps = {
    userId: string
    paymentDetails: UserData
}

const SubscriptionsList:RFC<SubscriptionsListProps> = ({ userId, paymentDetails }) => {
    const limit = 3
    const [ page, setPage ] = useState<string | undefined | null>('')
    const [ currentPage, setCurretPage ] = useState(0)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    
    const { data } = useWHMSelector(userStripeSelector).stripe
    const dispatch = useWHMDispatch()
    const [ getStripepaymentIntents, { isLoading, isFetching, isError } ] = useLazyGetStripePaymentIntentsQuery()

    useEffect(() => {
        setPage('')
        dispatch(clearStripe())
        setCurretPage(0)
        loadMoreItems(3)
    },[userId])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(limit, page)
    }

    const loadMoreItems = async (limit: number, page?: string | null | undefined) => {
        let intentObj:PaginationPayloadForStripe = { limit, userId }
        if (page) intentObj = { page, limit, userId }
        else intentObj = { limit, userId }

        if (!isLoadMore) setIsLoadMore(true)
        try {
            const payments = await getStripepaymentIntents(intentObj).unwrap()
            
            if (!payments || !payments.data.length){
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    dispatch(setInitialUserStripe({ stripe: payments }))
                    setHasMore(payments.has_more)
                    setPage(payments.next_page)
                    setCurretPage(1)
                } else {
                    dispatch(setUserStripe({ stripe: payments }))
                    setHasMore(payments.has_more)
                    setPage(payments.next_page)
                    setCurretPage(prev => prev + 1)
                }
            }
    
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    const renderSubscriptionType = (
            prevInterval:    string | undefined, 
            currentInterval: string | undefined, 
            isSubscribed:    boolean
        ) => {
        if (isSubscribed)                                           return 'Subscribed to WHM'
        if (prevInterval === 'month' && currentInterval === 'year') return 'Upgraded Subscription'
        if (prevInterval === 'year' && currentInterval === 'month') return 'Downgraded Subscription'
    }

    if (paymentDetails.userSubscriptionInformation?.type === 'paypal' ||
        paymentDetails.userSubscriptionInformation?.type === 'PAYPAL') {
        const subscriptionDetails = paymentDetails?.subscriptionDetails as PaypalDetails
        const formattedPrice = subscriptionDetails?.billing_info?.last_payment ? parseFloat(subscriptionDetails?.billing_info?.last_payment.amount.value).toFixed(2) : '---'
        return (
            <div 
                className='flex items-center justify-between px-3 py-3 rounded-[10px] my-2 text-WHMDarkPurple border-solid border-WHMBorder border-[1px]'
            >
                <p className="font-semibold">
                    Payment Received
                    <span className="ml-3 font-normal">
                        ${formattedPrice}
                    </span>
                </p>
                <div className="font-semibold text-WHMDarkPurple/80">
                    {subscriptionDetails?.billing_info?.last_payment && moment(subscriptionDetails?.billing_info?.last_payment.time).format('DD-MM-YYYY')}
                </div>
            </div>
        )
    }

    if (isLoading || isFetching) return <DnDListSkeleton />
    if (isError) return <CardError />
    if (!data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No Payment
    </div>
    )

    return (
        <div className='w-full h-screen overflow-y-auto overflow-x-hidden pb-[14em] pr-1'>
            <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                loader={<CardLoading key={0}/>}
                initialLoad={true}
                threshold={200}
            >
            {data.map((subscription, index) => {
                const prevInterval = (index + 1 <= data.length) ? data[index + 1]?.invoice?.subscription?.plan?.interval : undefined
                const currentInterval = subscription?.invoice?.subscription?.plan?.interval
                const isChangedPlan = prevInterval !== currentInterval
                const isSubscribed = data.length === index + 1
                const isCancelled = false
                return (
                isCancelled ? (
                <div
                    key={index}
                    className={`flex items-center justify-between px-3 py-3 rounded-[10px] my-2
                    bg-WHMLightGrey text-WHMGold`}
                >
                    <p className='font-semibold'>
                        Ended Subscription
                    </p>
                    <p className='font-semibold'>
                        {'22-10-2022'}
                    </p>
                </div>
                ) : (
                <div 
                    key={index}
                    className='w-full'
                >
                    {(isChangedPlan || isSubscribed) && (
                    <div
                        className='flex items-center justify-between px-3 py-3 rounded-[10px] my-2
                        bg-WHMDarkPurple/60 text-WHMWhite'
                    >
                        <p className='flex flex-col text-lg font-semibold'>
                            { renderSubscriptionType(prevInterval, currentInterval, isSubscribed) }
                            <span className='text-sm font-normal'>
                                {`${subscriptionPeriodFormatter(currentInterval)} Subscription`}
                            </span>
                        </p>
                        <div
                            className='font-semibold text-WHMWhite'
                        >
                            {convertSubscriptionDate(subscription?.invoice?.created)}
                        </div>
                    </div>
                    )}
                    <div
                        className='flex items-center justify-between px-3 py-3 rounded-[10px] my-2 
                        text-WHMDarkPurple border-solid border-WHMBorder border-[1px]'
                    >
                        <div>
                            <p className='font-semibold'>
                                Payment Received
                                <span className='ml-3 font-normal'>
                                    ${calculateStripePrice(subscription.amount)}
                                </span>
                            </p>
                        </div>
                        <div
                            className='font-semibold text-WHMDarkPurple/80'
                        >
                            {convertSubscriptionDate(subscription?.invoice?.created)}
                        </div>
                    </div>
                </div>
                )
            )})}
            </InfiniteScroll>
        </div>
    )
}

export default SubscriptionsList