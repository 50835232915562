import { WHMApi } from '../app/api'
import { UsersData, UserData, UserFeedbacksData, PaginationPayloadWithUserId, UserTicketsData, PaginationPayloadForStripe, PaginationPayloadWithType, UpdateUserRoleArgs } from '../types/serviceTypes'
import { UserStatisticsData } from '../types/stateTypes'
import { UserStripePaymentsData } from '../types/stripeType'

export const userApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<UsersData, PaginationPayloadWithType>({
            query: ({ currentPage, perPage, filter, isSubscribed }) => ({
                url: '/admin/users',
                params: { currentPage, perPage, filter, isSubscribed }
            }),
            providesTags: ['User']
        }),
        getUser: builder.query<{user: UserData}, string>({
            query: (userId) => `/admin/users/${userId}`,
            providesTags: ['User']
        }),
        getUserStatistics: builder.query<UserStatisticsData, string>({
            query: (userId) => `/admin/users/${userId}/statistics`,
            providesTags: ['User']
        }),
        getUserFeedbacks: builder.query<UserFeedbacksData, PaginationPayloadWithUserId>({
            query: ({ currentPage, perPage, userId }) => ({
                url: `/admin/users/${userId}/programs/feedbacks`,
                params: { currentPage, perPage }
            }),
            providesTags: ['User', 'Feedback']
        }),
        getUserTickets: builder.query<UserTicketsData, PaginationPayloadWithUserId>({
            query: ({ currentPage, perPage, userId }) => ({
                url: `/admin/supports/users/${userId}`,
                params: { currentPage, perPage }
            }),
            providesTags: ['User', 'Help']
        }),
        getStripePaymentIntents: builder.query<UserStripePaymentsData, PaginationPayloadForStripe>({
            query: ({ page, limit, userId }) => ({
                url: `/stripe/users/${userId}/payment-intents/search`,
                params: { page, limit }
            })
        }),
        updateUserRole: builder.mutation<UserStripePaymentsData, UpdateUserRoleArgs>({
            query: ({ role, userId }) => ({
                url: `/admin/users/${userId}`,
                body: { role },
                method: 'PATCH'
            })
        }),
    })
})

export const {
    useLazyGetUsersQuery,
    useLazyGetUserQuery,
    useLazyGetUserStatisticsQuery,
    useLazyGetUserFeedbacksQuery,
    useLazyGetUserTicketsQuery,
    useLazyGetStripePaymentIntentsQuery,
    useUpdateUserRoleMutation
} = userApi