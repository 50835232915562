import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/styles/index.css'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import 'tw-elements'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

reportWebVitals()