import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { courseFocusList } from '../../../assets/data/arrays'
import { 
    clearCourse, courseSelector, removeCourseLesson, selectCourse, sortCourseLessons, 
    updateCourseActive, updateCourseDescription, updateCourseFocus, updateCourseImage, updateCourseTitle 
} from '../../../features/Resources/Courses/courseSlice'
import { 
    WHMDetailInput, WHMDetailTitle, WHMDropdown, WHMStatsBlock, 
    WHMTextArea, WHMTitleBlock, WHMUploadInput 
} from '../../atoms/atoms'
import { LessonDnDListField, StatusButtons } from '../../molecules/molecules'
import { DashboardDetails } from '../../templates/templates'
import CourseStats from '../../../features/Resources/Courses/CourseStats'
import { useCreateCourseMutation, useDeleteCourseMutation, useLazyGetCourseQuery, useUpdateCourseMutation } from '../../../services/CourseService'
import { DnDListSkeleton, ImageSkeleton, InputSkeleton, TextAreaSkeleton } from '../../molecules/molecules'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { FOLDER_TYPE, MODAL_TYPES } from '../../../assets/data/enums'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { addCourse, deleteCourseWithId, updateCourseInfo } from '../../../features/Resources/Courses/coursesSlice'

type CourseDetailsProps = {
    isNewCourse?:    boolean
    setIsNewCourse?: (close: boolean) => void
}

const CourseDetails:RFC<CourseDetailsProps> = ({ 
    isNewCourse,
    setIsNewCourse, 
}) => {
    // Global States
    const { id, title, focus, image, description, isActive, lesson } = useWHMSelector(courseSelector)
    const dispatch = useWHMDispatch()

    // Services
    const [ getCourse, { data: courseData, isLoading: courseLoading }] = useLazyGetCourseQuery()
    const [ createCourse ] = useCreateCourseMutation()
    const [ updateCourse ] = useUpdateCourseMutation()
    const [ deleteCourse ] = useDeleteCourseMutation()

    useEffect(() => {
        if (id) {
            dispatch(updateCourseImage(''))
            getCourse(id)
        }
    },[id])

    useEffect(() => {
        courseData && dispatch(selectCourse({ id: courseData.id, course: courseData }))
    },[courseData])

    const createNewCourse = async () => {
        const lessonIds = lesson?.map(l => l.id)
        const createCourseObj = {
            title: title ?? '', 
            focus: focus ?? '', 
            image, 
            description: description ?? '',
            lesson: lessonIds,
        }

        try {
            dispatch(openOverlay({ text: 'Creating a new course' }))
            const [ data ] = await Promise.all([
                createCourse(createCourseObj).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data || !setIsNewCourse) throw Error
            setIsNewCourse(false)
            dispatch(addCourse(data))
            dispatch(selectCourse({ id: data.id, course: data }))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Course Created',
                body: 'You have successfully created a new course!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to create course',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const saveCourse = async () => {
        const lessonIds = lesson?.map(l => l.id)
        const updateCourseObj = {
            id,
            title: title ?? '', 
            focus: focus ?? '', 
            image, 
            description: description ?? '',
            lesson: lessonIds,
            isActive
        }

        try {
            dispatch(openOverlay({ text: 'Saving course details' }))
            const [ data ] = await Promise.all([
                updateCourse(updateCourseObj).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(updateCourseInfo(data))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Course Updated',
                body: 'Your changes have been successfully saved!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to update course',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const clearNewCourse = () => {
        if (!setIsNewCourse) return
        setIsNewCourse(false)
    }

    const removeCourse = async (courseId: string) => {
        try {
            dispatch(closeModal())
            dispatch(openOverlay({ text: 'Deleting this course' }))
            const [ data ] = await Promise.all([
                deleteCourse(courseId).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(clearCourse())
            dispatch(deleteCourseWithId(id))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'COURSE DELETED',
                body: 'You have successfully deleted the course!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to delete couse',
                body: 'Something went wrong. Please try again later.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <WHMTitleBlock title={isNewCourse ? 'New Course' : `${title}`} />
                {courseLoading ? (<InputSkeleton />) : (
                    <StatusButtons 
                        type='d-as-s' 
                        isActive={isActive}
                        onSave={isNewCourse ? createNewCourse : saveCourse}
                        onDelete={isNewCourse 
                            ? clearNewCourse 
                            : () => dispatch(openModal({
                                modalType: MODAL_TYPES.DELETE,
                                deleteId: id,
                                deleteFn: removeCourse
                            }))
                        }
                        onChangeStatus={() => dispatch(updateCourseActive(!isActive))}
                    />
                )}
            </div>

            <DashboardDetails template='grid-cols-2sm'>
            <>
                {/* Courses Detail Left Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>

                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Title' 
                            details='Add a title that describes your course.'
                            margin='mb-1'
                            isRequired={isNewCourse ? true : false}
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <WHMDetailInput
                                placeholder='Title'
                                value={title ?? ''}
                                onChange={(text) => dispatch(updateCourseTitle(text))}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Focus' 
                            details='Add a course focus to categorise this course and help users find a course.'
                            margin='mb-1'
                            isRequired={isNewCourse ? true : false}
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <WHMDropdown 
                                value={focus ?? 'Focus'}
                                list={courseFocusList}
                                onClick={(option) => dispatch(updateCourseFocus(option))}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Image' 
                            details='Upload an image for this course’s thumbnail card and overview.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<ImageSkeleton />) : (
                            <WHMUploadInput 
                                type='image'
                                source={image ?? ''}
                                folder={FOLDER_TYPE.COURSE_IMAGE}
                                onUpload={(fileName) => dispatch(updateCourseImage(fileName ?? ''))}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Course Description' 
                            details='Create a course description that outlines what the course is about.'
                            margin='mb-1'
                            isRequired={isNewCourse ? true : false}
                        />
                        {courseLoading ? (<TextAreaSkeleton />) : (
                            <WHMTextArea 
                                text={description ?? ''}
                                onChange={(text) => dispatch(updateCourseDescription(text))}
                            />
                        )}
                    </div>

                </div>

                {/* Courses Detail Right Side */}
                <div className='pl-2'>

                    <WHMStatsBlock title='Course Stats'>
                        <CourseStats courseId={id ?? ''}/>
                    </WHMStatsBlock>
                    <div className='mt-3'>
                        <WHMDetailTitle 
                            title='Course Lessons' 
                            details='Add lessons to this course. These are created in the Lessons tab.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<DnDListSkeleton />) : (
                            <LessonDnDListField 
                                list={lesson}
                                sortItems={(items) => dispatch(sortCourseLessons(items))}
                                removeItem={(item) => dispatch(removeCourseLesson(item))}
                                openModal={() => dispatch(openModal({ modalType: MODAL_TYPES.ADD_LESSON }))}
                            />
                        )}
                    </div>

                </div>
            </>
            </DashboardDetails>
        </>
    )
}

export default CourseDetails