import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialHelpTicketState } from '../../../assets/data/InitialState'
import { HelpTicketData } from '../../../types/serviceTypes'

export type HelpTicketSliceType = {
    id:         string
    helpTicket: HelpTicketData
}

export const helpTicketSlice = createSlice({
    name: 'helpTicket',
    initialState: InitialHelpTicketState,
    reducers: {
        selectHelpTicket: (state, action:PayloadAction<HelpTicketSliceType>) => {
            state.id         = action.payload.id
            state.helpTicket = action.payload.helpTicket
        },
        clearTicket: (state) => {
            state.id         = ''
            state.helpTicket = { id: '' }
        }
    }
})

export const helpTicketSelector = (state: RootState) => state.helpTicket
export const {
    selectHelpTicket,
    clearTicket,
} = helpTicketSlice.actions
export default helpTicketSlice.reducer