import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../app/store'
import { InitialCoursesState } from '../../../assets/data/InitialState'
import { CoursesData } from '../../../types/serviceTypes'
import { Course } from '../../../types/stateTypes'

export type CoursesSliceType = {
    courses: CoursesData
}

export const coursesSlice = createSlice({
    name: 'course',
    initialState: InitialCoursesState,
    reducers: {
        clearCourses: (state) => {
            state.courses.data = []
        },
        setInitialCourses: (state, action:PayloadAction<CoursesSliceType>) => {
            const updatedOrders = action.payload.courses.data.slice().sort((a, b) => 
                parseInt(moment(b.updatedAt).format('X')) - parseInt(moment(a.updatedAt).format('X'))
            )
            state.courses.data = updatedOrders
        },
        setCourses: (state, action:PayloadAction<CoursesSliceType>) => {
            const { data: storedCourses } = state.courses
            const { data: newCourses } = action.payload.courses
            const courseId = storedCourses.map(course => course.id)
            const filteredDuplicates = newCourses.filter(course => !courseId.includes(course.id))
            const updatedOrders = filteredDuplicates.slice().sort((a, b) => 
                parseInt(moment(b.updatedAt).format('X')) - parseInt(moment(a.updatedAt).format('X'))
            )
            updatedOrders.map(course => state.courses.data.push(course))
        },
        addCourse: (state, action:PayloadAction<Course>) => {
            state.courses.data.unshift(action.payload)
        },
        updateCourseInfo: (state, action:PayloadAction<Course>) => {
            const filteredCourses = state.courses.data.filter(course => course.id !== action.payload.id)
            state.courses.data = [ action.payload, ...filteredCourses ]
        },
        deleteCourseWithId: (state, action:PayloadAction<string>) => {
            const filteredCourses = state.courses.data.filter(course => course.id !== action.payload)
            state.courses.data = filteredCourses
        }
    }
})

export const coursesSelector = (state: RootState) => state.courses
export const {
    clearCourses,
    setInitialCourses,
    setCourses,
    addCourse,
    updateCourseInfo,
    deleteCourseWithId,
} = coursesSlice.actions
export default coursesSlice.reducer