import { WhiteLogo } from '../../assets/images/images'
import { motion } from 'framer-motion'

const WHMSplashScreen = () => {
    return (
        <motion.div
            className='min-h-screen w-full flex flex-col justify-center items-center
            bg-gradient-to-tl from-WHMDarkPurple via-WHMDarkPurple to-WHMGold'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <img 
                src={WhiteLogo} 
                className=''
                alt='splash-logo' 
            />
            <div className='w-[600px] bg-WHMBorder/50 rounded-full h-2 mt-[5em]'>
                <div 
                    className='bg-WHMWhite h-2 rounded-full animate-splash-progress'>
                </div>
            </div>
        </motion.div>
    )
}

export default WHMSplashScreen