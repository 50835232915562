import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearComments, reportPostSelector } from '../../../features/Community/Reports/reportPostSlice'
import { groupSelector } from '../../../features/Community/Groups/groupSlice'
import { domAnimation, LazyMotion, motion } from 'framer-motion'
import { CardError, CardLogo, WHMAsyncAvatar } from '../../atoms/atoms'

type CommentTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

const CommentTitles:RFC<CommentTitlesProps> = ({
    isLoading,
    isError
}) => {
    const { post } = useWHMSelector(groupSelector)
    const { data: commentsState } = useWHMSelector(reportPostSelector).comments
    const dispatch = useWHMDispatch()

    useEffect(() => {
        dispatch(clearComments())
    },[post])

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!commentsState.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Comment
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='comment_card'>
            {commentsState.map((item, index) => (
            <div key={index}>
                <motion.div 
                    key={index}
                    initial={{ x: -150, opacity: 0, scale: 0 }}
                    animate={{ x: 0, opacity: 1, scale: 1 }}
                    exit={{ x: 150, opacity: 0 }}
                    transition={{ stiffness: 80 }}
                    className='ml-3'
                >
                    <div className='flex items-center'>
                        <WHMAsyncAvatar fileName={item.user.avatar} />
                        <p className='flex font-bold text-xl text-WHMDarkPurple/80 ml-2'>
                            {item.user.firstName} {item.user.lastName}
                        </p>
                    </div>
                    <p className='px-2 pt-1 pb-2'>
                        {item.comment}
                    </p>
                </motion.div>
                {commentsState.length - 1 !== index && (
                    <hr className='h-[1px] bg-WHMBorder mx-8'/>
                )}
            </div>
            ))}
        </LazyMotion>
    )
}

export default CommentTitles