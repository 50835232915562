import { useNavigate } from 'react-router-dom'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { openModal } from '../../../features/Modal/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { clearTicket, helpTicketSelector } from '../../../features/Notice/HelpTickets/helpTicketSlice'
import { deleteTicketWithId } from '../../../features/Notice/HelpTickets/helpTicketsSlice'
import { jumpToUserTickets } from '../../../features/Users/Users/userSlice'
import { useReplyTicketMutation } from '../../../services/HelpTicketService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { UserCard } from '../../atoms/atoms'
import { SupportTicketField } from '../../molecules/molecules'

const HelpTicketDetails = () => {
  const navigate = useNavigate()
  const helpTicketState = useWHMSelector(helpTicketSelector)
  const dispatch = useWHMDispatch()
  const { id, user, createdAt, topic, note, repliedAt, reply } = helpTicketState.helpTicket
  const [ replyTicket ] = useReplyTicketMutation()

  const visitUserProfile = (userId: string | undefined) => {
    if (!userId) return
    navigate('/users-board')
    dispatch(jumpToUserTickets(userId))
  }

  const submitReply = async (text: string) => {
    try {
      dispatch(openOverlay({ text: `Responding to ${user?.firstName} ${user?.lastName}'s question.`}))
      const [ data ] = await Promise.all([
        replyTicket({ userSupportId: id, reply: text }),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(deleteTicketWithId(id))
      dispatch(clearTicket())
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Message Sent',
        body: 'You have successfully sent a message.'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to send a message.',
        body: 'Please check your network and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <>
      <div className='w-full flex items-center'>
        <div className='text-WHMDarkPurple font-bold text-[1.6em] max-w-max mr-4 px-3 py-2'>
          {`${user?.firstName} ${user?.lastName}`}
        </div>
        <div className='text-WHMPurple font-medium text-[1.6em] max-w-max truncate px-3 py-2'>
          {topic}
        </div>
      </div>
      <UserCard 
        avatar={user?.avatar}
        firstName={user?.firstName}
        lastName={user?.lastName}
        btnFn={() => visitUserProfile(user?.id)}
      />
      <div className='px-2 py-3 max-h-screen overflow-y-auto overflow-x-hidden pb-[13em]'>
        <SupportTicketField 
          sentOn={createdAt}
          toppic={topic}
          note={note}
          repliedOn={repliedAt}
          reply={reply}
          onSubmitReply={(text) => submitReply(text)}
        />
      </div>
    </>
  )
}

export default HelpTicketDetails