import { Modal, Overlay, Snackbar } from '../../types/stateTypes'
import { ERROR_MESSAGES, SNACKBAR_TYPES } from './enums'
import { AuthData, LoginArgs } from '../../types/serviceTypes'
import { FeedbackSliceType } from '../../features/Notice/ProgramFeedback/feedbackSlice'
import { FeedbacksSliceType } from '../../features/Notice/ProgramFeedback/feedbacksSlice'
import { HelpTicketSliceType } from '../../features/Notice/HelpTickets/helpTicketSlice'
import { HelpTicketsSliceType } from '../../features/Notice/HelpTickets/helpTicketsSlice'
import { ProgramSliceType } from '../../features/Training/Programs/programSlice'
import { ProgramsSliceType } from '../../features/Training/Programs/programsSlice'
import { ProgramWorkoutSliceType } from '../../features/Training/Workouts/programWorkoutsSlice'
import { WorkoutSliceType } from '../../features/Training/Workouts/workoutSlice'
import { WorkoutsSliceType } from '../../features/Training/Workouts/workoutsSlice'
import { ExerciseDetailSliceType } from '../../features/Training/Workouts/exerciseDetailSlice'
import { ExerciseSliceType } from '../../features/Training/Exercises/exerciseSlice'
import { ExercisesSliceType } from '../../features/Training/Exercises/exercisesSlice'
import { AlternativesSliceType } from '../../features/Training/Exercises/alternativesSlice'
import { WorkoutExercisesSliceType } from '../../features/Training/Exercises/workoutExercisesSlice'
import { CourseSliceType } from '../../features/Resources/Courses/courseSlice'
import { CoursesSliceType } from '../../features/Resources/Courses/coursesSlice'
import { LessonSliceType } from '../../features/Resources/Lessons/lessonSlice'
import { LessonsSliceType } from '../../features/Resources/Lessons/lessonsSlice'
import { CourseLessonsSliceType } from '../../features/Resources/Lessons/courseLessonsSlice'
import { ReportSliceType } from '../../features/Community/Reports/reportSlice'
import { ReportsSliceType } from '../../features/Community/Reports/reportsSlice'
import { GroupSliceType } from '../../features/Community/Groups/groupSlice'
import { GroupsSliceType } from '../../features/Community/Groups/groupsSlice'
import { ReportPostSliceType } from '../../features/Community/Reports/reportPostSlice'
import { UsersSliceType } from '../../features/Users/Users/usersSlice'
import { UserSliceType } from '../../features/Users/Users/userSlice'
import { UserFeedbacksSliceType } from '../../features/Users/Users/userFeedbacksSlice'
import { UserHelpTicketsSliceType } from '../../features/Users/Users/userHelpTicketsSlice'
import { StatisticsSliceType } from '../../features/Users/Statistics/statisticsSlice'
import { StripeSliceType } from '../../features/Users/Users/stripeSlice'

// --- Snackbar ---
export const InitialSnackbarState: Snackbar = {
    isDisplayed:     false,
    snackbarType:    SNACKBAR_TYPES.ERROR,
    snackbarMessage: ERROR_MESSAGES.LOGIN,
    snackbarTimeout: 10000,
    snackbarBtnText: 'CLOSE',
    snackbarBtnFunc: () => {}
}

// --- Modal ---
export const InitialModalState: Modal = {
    isDisplayed: false,
    modalType:   null,
    title:       '',
    body:        '',
    deleteId:    '',
    deleteFn:    () => {}
}

// --- Overlay ---
export const InitialOverlayState: Overlay = {
    isLoading:  false,
    text:       ''
}

// --- Auth ---
export const InitialLoginState: LoginArgs = {
    email:    '',
    password: '',
}

export const InitialAuthState: AuthData = {
    accessToken: {
        token:     null,
        expiresIn: null
    },
    user:        {}
}

// --- Notice ---
export const InitialFeedbackState: FeedbackSliceType = {
    id:       '',
    feedback: { userProgramId: '' }
}

export const InitialFeedbacksState: FeedbacksSliceType = {
    feedbacks: {
        data:       [],
        pagination: {}
    }
}

export const InitialHelpTicketState: HelpTicketSliceType = {
    id:         '',
    helpTicket: { id: ''}
}

export const InitialHelpTicketsState: HelpTicketsSliceType = {
    helpTickets: {
        data:       [],
        pagination: {}
    }
}

// --- Training ---
export const InitialProgramState: ProgramSliceType = {
     id:      '',
     program: { 
        id:   '',
        phase: [
            { 
                _count: { phaseWorkout: 0 },
                id: '',
                orderIndex: 1,
                phaseWorkout: [],
                programId: '',
            },
            { 
                _count: { phaseWorkout: 0 },
                id: '',
                orderIndex: 2,
                phaseWorkout: [],
                programId: '',
            },
            { 
                _count: { phaseWorkout: 0 },
                id: '',
                orderIndex: 3,
                phaseWorkout: [],
                programId: '',
            },
        ],
        programSuggestGoal: [],
        programSuggestExperience: [],
        programSuggestTraining: []
    },
     phaseTab: 'Phase 1'
}

export const InitialProgramsState: ProgramsSliceType = {
    programs: {
        data:       [],
        pagination: {}
    }
}

export const InitialWorkoutState: WorkoutSliceType = {
    id:      '',
    workout: { id: '' }
}

export const InitialWorkoutsState: WorkoutsSliceType = {
    workouts: {
        data:       [],
        pagination: {}
    }
}

export const InitialProgramWorkoutsState: ProgramWorkoutSliceType = {
    programWorkouts: {
        data:       [],
        pagination: {}
    }
}

export const InitialExerciseDetailState: ExerciseDetailSliceType = {
    initialGroups:  [],
    exerciseGroups: [],
    exerciseGroup:  {
        exerciseGroupInformation: [],
        id:                       '',
        orderIndex:               0,
        workoutId:                ''
    },
    lastOrderIndex:               -1
}

export const InitialExerciseState: ExerciseSliceType = {
    id:           '',
    exercise:     { id: ''},
    equipment:    [],
    cues:         [],
    focus:        [],
    alternatives: []
}

export const InitialExercisesState: ExercisesSliceType = {
    exercises: {
        data:       [],
        pagination: {}
    }
}

export const InitialAlternativesState: AlternativesSliceType = {
    exercises: {
        data:       [],
        pagination: {}
    }
}

export const InitialWorkoutExercisesState: WorkoutExercisesSliceType = {
    workoutExercises: {
        data:       [],
        pagination: {}
    }
}

// --- Resources ---
export const InitialCourseState: CourseSliceType = {
    id:     '',
    course: { id: '' }
}

export const InitialCoursesState: CoursesSliceType = {
    courses: {
        data:       [],
        pagination: {}
    }
}

export const InitialLessonState: LessonSliceType = {
    id:     '',
    lesson: { id: '' }
}

export const InitialLessonsState: LessonsSliceType = {
    lessons: {
        data:       [],
        pagination: {}
    }
}

export const InitialCourseLessonsState: CourseLessonsSliceType = {
    courseLessons: {
        data:       [],
        pagination: {}
    }
}

// --- Community ---
export const InitialReportState: ReportSliceType = {
    id:     '',
    report: { 
        postId: '',
        userId: '', 
    }
}

export const InitialReportsState: ReportsSliceType = {
    reports: {
        data:       [],
        pagination: {}
    }
}

export const InitialReportPostState: ReportPostSliceType = {
    post: {
        author: {
            avatar:               '',
            birthday:             new Date(),
            createdAt:            new Date(),
            deletedUserAccountId: '',
            email:                '',
            facebook:             '',
            firstName:            '',
            id:                   '',
            instagram:            '',
            lastName:             '',
            role:                 '',
            tiktok:               '',
            userMobileId:         ''
        },
        authorId:            '',
        commentCount:        0,
        createdAt:           new Date(),
        description:         '',
        id:                  '',
        likeCount:           0,
        postGroup:           [],
        userWorkoutRecord:   '',
        userWorkoutRecordId: '',
    },
    comments: {
        data:       [],
        pagination: {}
    }
}

export const InitialGroupState: GroupSliceType = {
    group: {
        isActive: 0,
        name:     ''
    },
    posts: {
        data:       [],
        pagination: {}
    },
    post: {
        author: {
            avatar:               '',
            birthday:             new Date(),
            createdAt:            new Date(),
            deletedUserAccountId: '',
            email:                '',
            facebook:             '',
            firstName:            '',
            id:                   '',
            instagram:            '',
            lastName:             '',
            role:                 '',
            tiktok:               '',
            userMobileId:         ''
        },
        authorId:            '',
        commentCount:        0,
        createdAt:           new Date(),
        description:         '',
        id:                  '',
        likeCount:           0,
        postGroup:           [],
        userWorkoutRecord:   '',
        userWorkoutRecordId: '',
    },
}

export const InitialGroupsState: GroupsSliceType = {
    groups: {
        data:       [],
        pagination: {}
    }
}

// --- User ---
export const InitialUserState: UserSliceType = {
    id:         '',
    currentTab: 'About',
    user:       { id: '' },
}

export const InitialUsersState: UsersSliceType = {
    users: {
        data:       [],
        pagination: {}
    }
}

export const InitialUserStripeState: StripeSliceType = {
    stripe: {
        data:        [],
        has_more:    false,
        object:      '',
        url:         '',
        next_page:   '',
        total_count: 0
    }
}

export const InitialUserFeedbacksState: UserFeedbacksSliceType = {
    userFeedbacks: {
        data:       [],
        pagination: {}
    }
}

export const InitialUserHelpTicketsState: UserHelpTicketsSliceType = {
    helpTickets: {
        data:       [],
        pagination: {}
    }
}

export const InitialStatisticsState: StatisticsSliceType = {
    userStats:    {
        newWeeklySubscribedCount:   0,
        newWeeklyUnsubscribedCount: 0,
        subscribedCount:            0,
        userCount:                  0,
    },
    programStats: {
        startCount:  0,
        finishCount: 0,
        program:     {
            description:              '',
            id:                       '',
            image:                    '',
            isActive:                 false,
            phase:                    [],
            programSuggestExperience: [],
            programSuggestGoal:       [],
            programSuggestTraining:   [],
            title:                    '',
            updatedAt:                new Date()
        },
    },
    courseStats:  {
        course: {
            description: '',
            focus:       '',
            id:          '',
            image:       '',
            isActive:    false,
            lesson:      [],
            title:       '',
            updatedAt:   new Date(),
            video:       ''
        },
        finishCount:     '',
        startCount:      ''
    }
}