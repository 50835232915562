import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialCourseLessonsState } from '../../../assets/data/InitialState'
import { LessonsData } from '../../../types/serviceTypes'

export type CourseLessonsSliceType = {
    courseLessons: LessonsData
}

export const courseLessonsSlice = createSlice({
    name: 'courseLessons',
    initialState: InitialCourseLessonsState,
    reducers: {
        setInitialCourseLessons: (state, action:PayloadAction<CourseLessonsSliceType>) => {
            state.courseLessons.data = action.payload.courseLessons.data
        },
        setCourseLessons: (state, action:PayloadAction<CourseLessonsSliceType>) => {
            const { data: storedLessons } = state.courseLessons
            const { data: newLessons } = action.payload.courseLessons
            const lessonIds = storedLessons.map(lesson => lesson.id)
            const filteredDuplicates = newLessons.filter(lesson => !lessonIds.includes(lesson.id))
            filteredDuplicates.map(lesson => state.courseLessons.data.push(lesson))
        },
        clearCourseLessons: (state) => {
            state.courseLessons.data       = []
            state.courseLessons.pagination = {}
        }
    }
})

export const courseLessonsSelector = (state: RootState) => state.courseLessons
export const {
    setInitialCourseLessons,
    setCourseLessons,
    clearCourseLessons
} = courseLessonsSlice.actions
export default courseLessonsSlice.reducer