import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { useLazyGetLessonsQuery } from '../../../services/LessonService'
import { Dialog } from '@headlessui/react'
import { addCourseLessons, courseSelector, removeCourseLesson } from '../../../features/Resources/Courses/courseSlice'
import { ArrowLeftIcon } from '../../../assets/icons/icons'
import { CardError, CardLoading, WHMButton, WHMSearchbar } from '../../atoms/atoms'
import { CardLogo, ModalCard } from '../../atoms/atoms'
import { courseLessonsSelector, setCourseLessons, setInitialCourseLessons } from '../../../features/Resources/Lessons/courseLessonsSlice'
import InfiniteScroll from 'react-infinite-scroller'
import { closeModal } from '../../../features/Modal/modalSlice'

const AddLessonModal = () => {
    const perPage = 10
    const [ searchText, setSearchText ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
    
    const courseState = useWHMSelector(courseSelector)
    const courseLessonsState = useWHMSelector(courseLessonsSelector)
    const dispatch = useWHMDispatch()
    const [ getLessons, { isLoading, isError } ] = useLazyGetLessonsQuery()

    useEffect(() => {
        setCurrentPage(0)
        loadMoreItems(10, 0)
    },[searchText])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(perPage, currentPage)
    }

    const loadMoreItems = async (perPage: number, currentPage: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const lessons = await getLessons({ currentPage, perPage, filter: searchText }).unwrap()
    
            if (!lessons || !lessons.data.length) {
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    dispatch(setInitialCourseLessons({ courseLessons: lessons }))
                    setHasMore(true)
                    setCurrentPage(1)
                } else if (lessons.pagination.lastPage === currentPage) {
                    dispatch(setCourseLessons({ courseLessons: lessons }))
                    setHasMore(false)
                } else {
                    dispatch(setCourseLessons({ courseLessons: lessons }))
                    setHasMore(true)
                    setCurrentPage(prev => prev + 1)
                }
            }
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    const renderModalCards = () => {
        if (isLoading) <CardLogo />
        if (isError)   <CardError />
        if (!courseLessonsState.courseLessons.data.length) return (
            <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Lesson
            </div>
        )

        return courseLessonsState.courseLessons.data.map((option, index) => {
            const checkedLesson = courseState.lesson?.find(lesson => lesson.id === option.id)
            return (
                <ModalCard
                    key={index}
                    title={option.title}
                    checked={checkedLesson === undefined ? false : true}
                    addNewItem={() => dispatch(addCourseLessons(option))}
                    removeItem={() => dispatch(removeCourseLesson(option))}
                />
            )
        })
    }

    return (
        <>
            <Dialog.Title className='w-full flex items-center justify-between'>
                <div className='w-full flex items-center justify-center relative'>
                    <div
                        className='absolute left-0 cursor-pointer hover:scale-110 transition-all duration-200' 
                        onClick={() => dispatch(closeModal())}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <p className='w-full text-2xl font-bold text-center'>
                    Add Lesson
                    </p>
                </div>
            </Dialog.Title>
            <hr className='whm-divider'/>
            <Dialog.Description className='my-3'>
                <span className='text-lg m-3'>
                Select what lesson you would like to add to this course.
                </span>
            </Dialog.Description>
            <WHMSearchbar onChange={onChangeText} style={'py-3'}/>
            <div className='w-full h-[45vh] overflow-y-auto overflow-x-hidden'>
                <InfiniteScroll
                    loadMore={fetchMoreData}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<CardLoading key={0}/>}
                    initialLoad={true}
                    threshold={200}
                >
                    { renderModalCards() }
                </InfiniteScroll>
            </div>
            <hr className='whm-divider my-4'/>
            <WHMButton 
                 text='Add Lessons'
                 className='golden-button'
                 onSubmit={() => dispatch(closeModal())}
            />
        </>
    )
}

export default AddLessonModal