import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialGroupsState } from '../../../assets/data/InitialState'
import { GroupsData } from '../../../types/serviceTypes'
import { Group } from '../../../types/stateTypes'

export type GroupsSliceType = {
    groups: GroupsData
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState: InitialGroupsState,
    reducers: {
        clearGroups: (state) => {
            state.groups.data = []
        },
        setInitialGroups: (state, action:PayloadAction<GroupsSliceType>) => {
            state.groups.data = action.payload.groups.data
        },
        setGroups: (state, action:PayloadAction<GroupsSliceType>) => {
            const { data: storedGroups } = state.groups
            const { data: newGroups } = action.payload.groups
            const groupNames = storedGroups.map(group => group.name)
            const filteredDuplicates = newGroups.filter(group => !groupNames.includes(group.name))
            filteredDuplicates.map(group => state.groups.data.push(group))
        },
        addGroup: (state, action:PayloadAction<Group>) => {
            state.groups.data.unshift(action.payload)
        },
        updateGroupInfo: (state, action:PayloadAction<{ group: Group, oldName: string }>) => {
            const filteredGroups = state.groups.data.filter(group => group.name !== action.payload.oldName)
            state.groups.data = [ action.payload.group, ...filteredGroups ]
        },
        deleteGroupWithName: (state, action:PayloadAction<string>) => {
            const filteredGroups = state.groups.data.filter(group => group.name !== action.payload)
            state.groups.data = filteredGroups
        }
    }
})

export const groupsSelector = (state: RootState) => state.groups
export const {
    clearGroups,
    setInitialGroups,
    setGroups,
    addGroup,
    updateGroupInfo,
    deleteGroupWithName,
} = groupsSlice.actions
export default groupsSlice.reducer