import { RFC } from '../../../types/propTypes'
import { AnimatePresence } from 'framer-motion'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'

type ReportsTitlesProps = {
    searchText: string
}

const ReportsTitles:RFC<ReportsTitlesProps> = ({ searchText }) => {
    return (
        <div className='w-full mt-4'>
            <AnimatePresence>
                <InfiniteScrollList 
                    key='infinite_scroll'
                    page={ALL_TITLES.REPORTS}
                    searchText={searchText}
                />
            </AnimatePresence>
        </div>
    )
}

export default ReportsTitles
