import { useState } from 'react'
import { NOTICE_BOARD_TITLES } from '../../assets/data/enums'
import { useWHMSelector } from '../../app/hooks'
import { helpTicketSelector } from '../../features/Notice/HelpTickets/helpTicketSlice'
import { feedbackSelector } from '../../features/Notice/ProgramFeedback/feedbackSlice'
import { ProgramFeedbackList, HelpTicketsList } from '../../components/organisms/organisms'
import { WHMEmptyDetailBox } from '../../components/atoms/atoms'
import { FeedbackDetails, HelpTicketDetails } from '../../components/organisms/organisms'
import { noticeBoardTitles } from '../../assets/data/arrays'
import { DashboardLayout, DashboardTitles } from '../../components/templates/templates'

const NoticeBoard = () => {
    const [ currentPage, setCurrentPage ] = useState<string>(NOTICE_BOARD_TITLES.PROGRAM_FEEDBACK)
    const feedbackState = useWHMSelector(feedbackSelector)
    const helpTicketState = useWHMSelector(helpTicketSelector)

    const renderList = () => {
        switch (currentPage) {
            case NOTICE_BOARD_TITLES.PROGRAM_FEEDBACK:
                return <ProgramFeedbackList />
            case NOTICE_BOARD_TITLES.HELP_TICKETS:
                return <HelpTicketsList />
            default: 
                return
        }
    }

    const renderDetails = () => {
        switch (currentPage) {
            case NOTICE_BOARD_TITLES.PROGRAM_FEEDBACK:
                if (!feedbackState.id)
                    return <WHMEmptyDetailBox title='feedback'/>
                else
                    return <FeedbackDetails />
            case NOTICE_BOARD_TITLES.HELP_TICKETS:
                if (!helpTicketState.id)
                    return <WHMEmptyDetailBox title='help ticket'/>
                else
                    return <HelpTicketDetails />
            default:
                return
        }
    }

    return (
        <DashboardLayout page='notice'>
        <>
            <div className='dashboard-menu-box'>
                <DashboardTitles 
                    title='Notice Board' 
                    menu={noticeBoardTitles} 
                    currentPage={currentPage}
                    onClick={setCurrentPage}
                />
            </div>
            <div className='dashboard-title-box'>
                { renderList() }
            </div>
            <div className='dashboard-detail-box'>
                { renderDetails() }
            </div>
        </>
        </DashboardLayout>
    )
}

export default NoticeBoard