const replyButtonIcon = ({
  height,
  width,
  color,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width ?? "20.548"} 
        height={height ?? "20.548"} 
        viewBox="0 0 20.548 20.548"
      >
        <g id="arrow_back_black_24dp" transform="translate(20.548) rotate(90)">
          <path id="Path_41" data-name="Path 41" d="M0,0H20.548V20.548H0Z" fill="none"/>
          <path id="Path_42" data-name="Path 42" d="M14.225,6.224H3.405l4.97-4.97L7.113,0,0,7.113l7.113,7.113,1.254-1.254L3.405,8h10.82Z" transform="translate(3.161 3.161)" fill="#fff"/>
        </g>
      </svg>
    </div>
  )
}

export default replyButtonIcon;