import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { selectTab, userSelector } from '../../../features/Users/Users/userSlice'
import { WHMDetailTabs, WHMTitleBlock } from '../../atoms/atoms'
import { userTabs } from '../../../assets/data/arrays'
import { AboutUserTab, FeedbackTab, TicketsTab } from '../organisms'
import { useEffect, useState } from 'react'
import StatusButtons from '../../molecules/StatusButtons'
import { useUpdateUserRoleMutation } from '../../../services/UserService'
import { closeSnackbar, openSnackbar } from '../../../features/Snackbar/snackbarSlice'
import { ACCESS_TYPE, ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES, SUCCESS_MESSAGES } from '../../../assets/data/enums'

const UserDetails = () => {
  const userState = useWHMSelector(userSelector)
  const { firstName, lastName, role, id } = userState.user
  const dispatch = useWHMDispatch()
  const [updateUserRole] = useUpdateUserRoleMutation()
  const [isFreeAccessActive, setIsFreeAccessActive] = useState(role === ACCESS_TYPE.FREE_ACCESS)

  useEffect( () => {
    if (!userState.id) return
    setIsFreeAccessActive(userState.user.role === ACCESS_TYPE.FREE_ACCESS)
  },[userState.id])

  const renderUserDetails = () => {
    switch (userState.currentTab) {
      case 'About':    return (<AboutUserTab />)
      case 'Feedback': return (<FeedbackTab />)
      case 'Tickets':  return (<TicketsTab />)
      default:         return (<></>)
    }
  }

  const handleUpdateAccessStatus = async () => {
    setIsFreeAccessActive(!isFreeAccessActive)
    try {
      if (isFreeAccessActive){
        await updateUserRole({userId: id, role: ACCESS_TYPE.COMMON}).unwrap()
      } else {
        await updateUserRole({userId: id, role: ACCESS_TYPE.FREE_ACCESS}).unwrap()
      }
      dispatch(openSnackbar({
        snackbarType: SNACKBAR_TYPES.SUCCESS,
        snackbarMessage: SUCCESS_MESSAGES.FREE_ACCESS,
        snackbarTimeout: 10000,
    }))
    } catch (error) {
      dispatch(openSnackbar({
        snackbarType: SNACKBAR_TYPES.ERROR,
        snackbarMessage: ERROR_MESSAGES.USER_ACCESS,
        snackbarTimeout: 10000,
        snackbarBtnText: SNACKBAR_BUTTON_TYPES.DISMISS,
        snackbarBtnFunc: () => {
            dispatch(closeSnackbar())
        }
    }))
    }
  }
  
  return (
    <>
      <div className='w-full flex justify-center'>
        <WHMTitleBlock 
          title={`${firstName} ${lastName}`}
        />
        <StatusButtons 
          type='d-as-s'
          isActive={isFreeAccessActive}
          switchTitle='Free Access'
          onChangeStatus={handleUpdateAccessStatus}
        />
      </div>
      <WHMDetailTabs 
        tabs={userTabs}
        selectedTab={userState.currentTab}
        onSelectTab={(tab) => dispatch(selectTab(tab))}
      />
      { renderUserDetails()}
    </>
  )
}

export default UserDetails