import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useLazyGetPresignedDownloadURLQuery } from '../../../services/FileService'
import WHMLoading from '../WHMLoading'
import { ErrorIcon } from '../../../assets/icons/icons'

type WHMAsyncVideoProps = {
    fileName: string | undefined
}

const WHMAsyncVideo:RFC<WHMAsyncVideoProps> = ({ fileName }) => {
    const [ isVideoError, setIsVideoError ] = useState(false)
    const [ getPresignedDownloadUrl, { data, isLoading, isError } ] = useLazyGetPresignedDownloadURLQuery()

    useEffect(() => {
        if (!fileName) return
        getPresignedDownloadUrl({ fileName })
    },[fileName])
    
    if (isLoading) return (
        <div className='h-full w-full flex justify-center items-center rounded-lg'>
            <WHMLoading shape='circle' />
        </div>
    )

    if (!data?.url || isError || isVideoError ) return (
        <div className="w-full h-full flex items-center justify-center">
            <ErrorIcon width="50" height="50" color="#5E257D" />
            <p className="text-2xl ml-2 text-WHMDarkPurple">Video unavailable</p>
        </div>
    )
        
    return (
        <video className='h-full w-full rounded-lg' controls>
            <source 
                src={data.url}
                onError={() => setIsVideoError(true)}
            />
        </video>
    )
}

export default WHMAsyncVideo