const resourcesIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="school_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 30 30"
        >
            <path id="Path_12" data-name="Path 12" d="M0,0H30V30H0Z" fill="none"/>
            <path id="Path_13" data-name="Path 13" d="M7.455,14.018v4.65l6.25,3.413,6.25-3.413v-4.65l-6.25,3.412ZM5.18,9.93l8.525,4.65L22.23,9.93,13.705,5.28Z" transform="translate(1.295 1.32)" fill="#d6a156" opacity="0.3"/>
            <path id="Path_14" data-name="Path 14" d="M14.75,3,1,10.5l5,2.725v7.5L14.75,25.5l8.75-4.775v-7.5L26,11.863V20.5h2.5v-10ZM21,19.238,14.75,22.65,8.5,19.238v-4.65L14.75,18,21,14.588v4.65ZM14.75,15.15,6.225,10.5,14.75,5.85l8.525,4.65L14.75,15.15Z" transform="translate(0.25 0.75)"/>
        </svg>
      </div>
    )
  }
  
  export default resourcesIcon