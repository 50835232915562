import { WHMApi } from '../app/api'
import { 
    CourseCreateArgs, CourseData, CoursesData, CourseStatisticArgs, 
    CourseStatisticData, CourseUpdateArgs, PaginationPayload 
} from '../types/serviceTypes'
import { Course } from '../types/stateTypes'

export const courseApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getCourses: builder.query<CoursesData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/courses',
                params: { currentPage, perPage, filter, orderUpdatedAt: 'desc' }
            }),
            providesTags: ['Course']
        }),
        getCourse: builder.query<CourseData, string>({
            query: (courseId) => `/admin/courses/${courseId}`,
            providesTags: ['Course']
        }),
        getCourseStatistic: builder.query<CourseStatisticData, CourseStatisticArgs>({
            query: ({ courseId }) => `/admin/courses/${courseId}/statistics`,
            providesTags: ['Course']
        }),
        createCourse: builder.mutation<Course, CourseCreateArgs>({
            query: ({ image, lesson, title, description, focus }) => ({
                url: '/admin/courses',
                method: 'POST',
                body: {
                    image, 
                    lesson, 
                    title, 
                    description, 
                    focus
                }
            }),
            invalidatesTags: ['Course']
        }),
        updateCourse: builder.mutation<Course, CourseUpdateArgs>({
            query: ({ id, image, lesson, title, description, focus, isActive }) => ({
                url: `/admin/courses/${id}`,
                method: 'PATCH',
                body: {
                    image, 
                    lesson, 
                    title, 
                    description, 
                    focus,
                    isActive
                }
            }),
            invalidatesTags: ['Course']
        }),
        deleteCourse: builder.mutation<Course, string>({
            query: (courseId) => ({
                url: `/admin/courses/${courseId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Course']
        })
    })
})

export const { 
    useLazyGetCoursesQuery,
    useLazyGetCourseQuery,
    useGetCourseStatisticQuery,
    useCreateCourseMutation,
    useUpdateCourseMutation,
    useDeleteCourseMutation,
} = courseApi