const closeIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '45.255'} 
            height={height ?? '45.255'} 
            viewBox="0 0 45.255 45.255"
        >
        <g id="add_circle_black_24dp" transform="translate(29.848) rotate(45)">
            <path id="Path_57" data-name="Path 57" d="M0,0H32V32H0Z" transform="translate(-5.105 5.105)" fill="none"/>
            <path id="Path_58" data-name="Path 58" d="M11.23,0A11.23,11.23,0,1,0,22.46,11.23,11.245,11.245,0,0,0,11.23,0Zm7.019,12.634H12.634v5.615H9.826V12.634H4.211V9.826H9.826V4.211h2.808V9.826h5.615Z" transform="translate(-0.336 9.875)" fill="#d6a156" opacity="0.3"/>
            <path id="Path_59" data-name="Path 59" d="M15.4,7H12.6v5.6H7v2.8h5.6V21h2.8V15.4H21V12.6H15.4ZM14,0A14,14,0,1,0,28,14,14.005,14.005,0,0,0,14,0Zm0,25.2A11.2,11.2,0,1,1,25.2,14,11.215,11.215,0,0,1,14,25.2Z" transform="translate(-3.105 7.105)" fill="#5e257d"/>
        </g>
        </svg>
      </div>
    )
}
  
export default closeIcon