import { RFC } from '../../../types/propTypes'
import { ExerciseGroupInformation } from '../../../types/stateTypes'

type HomeAlternativeCardProps = {
    title:                  'Default' | 'Deloading' | 'Work, Transition and Tempo'
    desc:                   string
    values:                 ExerciseGroupInformation
    isHomeAlternative?:     boolean
    onChangeDefaultSets?:   (text: string) => void
    onChangeDefaultReps?:   (text: string) => void
    onChangeDeloadingSets?: (text: string) => void
    onChangeDeloadingReps?: (text: string) => void
    onChangeWork?:          (text: string) => void
    onChangeRest?:          (text: string) => void
    onChangeTempo?:         (text: string) => void
}

const HomeAlternativeCard: RFC<HomeAlternativeCardProps> = ({
    title,
    desc,
    values,
    onChangeDefaultSets,
    onChangeDefaultReps,
    onChangeDeloadingSets,
    onChangeDeloadingReps,
    onChangeWork,
    onChangeTempo,
    onChangeRest
}) => {
    const {
        defaultSets, defaultReps, deloadingSets,
        deloadingReps, workTimeS, restTimeS, tempoS
    } = values

    return (
        <div
            className={`rounded-md px-2 py-3 mb-2
            border-solid border-[3px] border-WHMBorder`}
        >
            <p
                className={`font-bold
                ${title === "Default" && "text-WHMGold"}
                ${title === "Deloading" && "text-WHMBlue"}
                ${title === "Work, Transition and Tempo" && "text-WHMDarkPurple"}
                `}
            >
                {title}
            </p>
            <p className="font-light text-[0.9em]">{desc}</p>
            <div className="w-full">
                {title === "Work, Transition and Tempo" ? (
                    <div className="flex justify-center flex-wrap gap-6 min-h-[2em]">
                        <div className="flex flex-col items-center">
                            <p className="font-semibold">Work/On</p>
                            <input
                                type={"number"}
                                value={workTimeS ? workTimeS : ''}
                                placeholder={"30s"}
                                className="w-20 h-[2em] bg-WHMDarkWhite rounded-lg outline-none
                                border-[1px] border-WHMBorder border-solid text-center"
                                onChange={(e) => onChangeWork && onChangeWork(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-semibold">Rest/Trans</p>
                            <input
                                type={"number"}
                                value={restTimeS ? restTimeS : ''}
                                placeholder={"10s"}
                                className="w-20 h-[2em] bg-WHMDarkWhite rounded-lg outline-none
                                border-[1px] border-WHMBorder border-solid text-center"
                                onChange={(e) => onChangeRest && onChangeRest(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-semibold">Tempo</p>
                            <input
                                type={"number"}
                                value={tempoS ? tempoS : ''}
                                placeholder={"0000"}
                                className="w-20 h-[2em] bg-WHMDarkWhite rounded-lg outline-none
                                border-[1px] border-WHMBorder border-solid text-center"
                                onChange={(e) => onChangeTempo && onChangeTempo(e.target.value)}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center flex-wrap gap-6 min-h-[2em]">
                        <div className="flex flex-col items-center">
                            <p className="font-semibold">Sets</p>
                            <input
                                type={"number"}
                                value={title === "Default"
                                    ? defaultSets ? defaultSets : ''
                                    : deloadingSets ? deloadingSets : ''
                                }
                                placeholder={"0"}
                                className="w-20 h-[2em] bg-WHMDarkWhite rounded-lg outline-none
                                border-[1px] border-WHMBorder border-solid text-center"
                                onChange={(e) => {
                                    title === 'Default'
                                        ? onChangeDefaultSets && onChangeDefaultSets(e.target.value)
                                        : onChangeDeloadingSets && onChangeDeloadingSets(e.target.value)
                                }}
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-semibold">Repetitions</p>
                            <input
                                type={"text"}
                                value={title === "Default"
                                    ? (defaultReps ? defaultReps : '')
                                    : (deloadingReps ? deloadingReps : '')
                                }
                                placeholder={"0"}
                                className="w-20 h-[2em] bg-WHMDarkWhite rounded-lg outline-none
                                border-[1px] border-WHMBorder border-solid text-center"
                                onChange={(e) => {
                                    title === 'Default'
                                        ? onChangeDefaultReps && onChangeDefaultReps(e.target.value)
                                        : onChangeDeloadingReps && onChangeDeloadingReps(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomeAlternativeCard