import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExerciseState } from '../../../assets/data/InitialState'
import { ExerciseData } from '../../../types/serviceTypes'
import { ExerciseAlternative } from '../../../types/stateTypes'

export type ExerciseSliceType = {
    id:            string
    exercise:      ExerciseData
    equipment?:    string[]
    cues?:         string[]
    focus?:        string[]
    alternatives?: ExerciseData[]
}

export const exerciseSlice = createSlice({
    name: 'exercise',
    initialState: InitialExerciseState,
    reducers: {
        selectExercise: (state, action:PayloadAction<ExerciseSliceType>) => {
            const equipmentNames = action.payload.exercise.exerciseEquipment?.map(equiptment => equiptment.name)
            const cueNames       = action.payload.exercise.exerciseCues?.map(cue => cue.description)
            const focusNames     = action.payload.exercise.exerciseFocus?.map(exercise => exercise.focus)
            state.id             = action.payload.id
            state.exercise       = action.payload.exercise
            state.equipment      = equipmentNames
            state.cues           = cueNames
            state.focus          = focusNames
            state.alternatives   = []
        },
        clearExercise: (state) => {
            state.id           = ''
            state.exercise     = { id: ''}
            state.equipment    = []
            state.cues         = []
            state.focus        = []
            state.alternatives = []
        },
        updateExerciseTitle: (state, action:PayloadAction<string>) => {
            state.exercise.title = action.payload
        },
        updateExerciseVideo: (state, action:PayloadAction<string>) => {
            state.exercise.video = action.payload
        },
        updateExerciseImage: (state, action:PayloadAction<string>) => {
            state.exercise.image = action.payload
        },
        refreshExerciseMedia: (state) => {
            state.exercise.video = ''
            state.exercise.image = ''
        },
        updateAlternativeList: (state, action:PayloadAction<ExerciseAlternative[]>) => {
            state.exercise.exerciseAlternatives = action.payload
        },
        addNewExerciseEquipment: (state, action:PayloadAction<string>) => {
            const isDuplicated = state.equipment?.find(equipment => 
                equipment.toLowerCase() === action.payload.toLowerCase()    
            )
            if (!isDuplicated) state.equipment?.push(action.payload)
        },
        removeExerciseEquipment: (state, action:PayloadAction<string>) => {
            const filteredEquipments = state.equipment?.filter(equipment => 
                equipment.toLowerCase() !== action.payload.toLowerCase()
            )
            state.equipment = filteredEquipments
        },
        addNewExerciseCue: (state, action:PayloadAction<string>) => {
            const isDuplicated = state.cues?.find(cue => 
                cue.toLowerCase() === action.payload.toLowerCase()    
            )
            if (!isDuplicated) state.cues?.push(action.payload)
        },
        removeExerciseCue: (state, action:PayloadAction<string>) => {
            const filteredCues = state.cues?.filter(cue => 
                cue.toLowerCase() !== action.payload.toLowerCase()    
            )
            state.cues = filteredCues
        },
        selectExerciseFocus: (state, action:PayloadAction<string>) => {
            const isExist = state.focus?.find(focus => focus === action.payload)
            if (isExist) {
                const filteredFocuses = state.focus?.filter(focus => focus !== action.payload)
                state.focus = filteredFocuses
            } else {
                state.focus?.push(action.payload)
            }
        },
        addAlternativeExercise: (state, action:PayloadAction<ExerciseData>) => {
            if (!state.alternatives) return
            if (!state.alternatives.length) {
                state.alternatives = [ action.payload ]
            } else {
                const isDuplicated = state.alternatives.find(alternative => alternative.id === action.payload.id)
                if (!isDuplicated) state.alternatives.push(action.payload)
            }
        },
        removeAlternativeExercise: (state, action:PayloadAction<string>) => {
            const filteredAlternatives = state.exercise.exerciseAlternatives?.filter(alternative => 
                alternative.alternativeExerciseId !== action.payload
            )
            const filteredAlternativeExercise = state.alternatives?.filter(alternative => 
                alternative.id !== action.payload
            )
            state.exercise.exerciseAlternatives = filteredAlternatives
            state.alternatives = filteredAlternativeExercise
        }
    }
})

export const exerciseSelector = (state: RootState) => state.exercise
export const {
    selectExercise,
    clearExercise,
    updateExerciseTitle,
    updateExerciseVideo,
    updateExerciseImage,
    refreshExerciseMedia,
    updateAlternativeList,
    addNewExerciseEquipment,
    removeExerciseEquipment,
    addNewExerciseCue,
    removeExerciseCue,
    selectExerciseFocus,
    addAlternativeExercise,
    removeAlternativeExercise,
} = exerciseSlice.actions
export default exerciseSlice.reducer