import { useGetGlobalWHMStatisticsQuery } from '../../../services/StatisticService'
import { StatsCard, WHMTitleBlock } from '../../atoms/atoms'

const UserStatisticsDetails = () => {
  const { data, isLoading } = useGetGlobalWHMStatisticsQuery()

  return (
    <div className='w-screen h-screen overflow-y-auto overflow-x-hidden pb-[7em]'>
      <WHMTitleBlock title='User Statistics' />

      <div className='w-full px-3'>
        <p className='font-bold text-WHMBlack mt-2 mb-1'>General User Stats</p>
        <div className='w-max flex'>
          <StatsCard 
            label='Total Users'
            resultNumber={data?.userStats.userCount ?? 0}
            isLoading={isLoading}
          />
          <StatsCard 
            label='Total Subscribed Users'
            resultNumber={data?.userStats.subscribedCount ?? 0}
            isLoading={isLoading}
          />
          <StatsCard 
            label='Total New Weekly Subscribed Users'
            resultNumber={data?.userStats.newWeeklySubscribedCount ?? 0}
            isLoading={isLoading}
          />
          <StatsCard 
            label='Total Weekly Unsubscribed Users'
            resultNumber={data?.userStats.newWeeklyUnsubscribedCount ?? 0}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className='w-full px-3'>
        <p className='font-bold text-WHMBlack mt-2 mb-1'>Program Statistics</p>
        <div className='w-max flex'>
          <StatsCard 
            label='Top Program'
            resultTitle={data?.programStats.program.title ?? 'NA'}
            isLoading={isLoading}
          />
          <StatsCard 
            label='Average Program Completion Rate'
            resultNumber={(((data?.programStats.finishCount ?? 0) / (data?.programStats.startCount ?? 0)) * 100).toFixed(0)}
            unit='%'
            isLoading={isLoading}
          />
          <StatsCard 
            label='Total Programs Completed'
            resultNumber={data?.programStats.finishCount ?? 0}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className='w-full px-3'>
        <p className='font-bold text-WHMBlack mt-2 mb-1'>Course Statistics</p>
        <div className='w-max flex'>
          <StatsCard 
            label='Top Course'
            resultTitle={data?.courseStats.course.title ?? 'NA'}
            isLoading={isLoading}
          />
          <StatsCard 
            label='Average Course Completion Rate'
            resultNumber={(
              ((parseInt(data?.courseStats.finishCount ?? '0') ) / parseInt(data?.courseStats.startCount ?? '0')) * 100).toFixed(0)
            }
            unit='%'
            isLoading={isLoading}
          />
          <StatsCard 
            label='Total Courses Completed'
            resultNumber={data?.courseStats.finishCount ?? '0'}
            isLoading={isLoading}
          />
        </div>
      </div>

    </div>
  )
}

export default UserStatisticsDetails