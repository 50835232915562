import { RFC } from '../../../types/propTypes'

type WHMStatusProps = {
    status: boolean
}

const WHMStatus:RFC<WHMStatusProps> = ({ status }) => {
    return (
        <p 
            className={`flex justify-center items-center ${status 
            ? 'bg-WHMGreen' : 'bg-WHMRed'} min-w-[65px] 
            text-WHMWhite text-sm rounded-[10px] py-[2px] px-1 mr-2`
            }
        >
        {status ? 'Active' : 'In-Active'}
        </p>
    )
}

export default WHMStatus