import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { exerciseSelector, updateAlternativeList } from '../../../features/Training/Exercises/exerciseSlice'
import { useLazyGetExercisesQuery } from '../../../services/ExerciseService'
import { alternativesSelector, setAlternatives, setInitialAlternatives } from '../../../features/Training/Exercises/alternativesSlice'
import { CardError, CardLoading, CardLogo, ModalCard, WHMButton, WHMCategoryTab, WHMSearchbar } from '../../atoms/atoms'
import { Dialog } from '@headlessui/react'
import { closeModal } from '../../../features/Modal/modalSlice'
import { ArrowLeftIcon } from '../../../assets/icons/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { ExerciseAlternative } from '../../../types/stateTypes'
import { ExerciseFocusTypes } from '../../../types/propTypes'
import { exerciseFocusList } from '../../../assets/data/arrays'

const AlternativesModal = () => {
  const perPage = 10
  const [ searchText, setSearchText ] = useState('')
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ focus, setFocus ] = useState<ExerciseFocusTypes>('All')
  const [ newAlternatives, setNewAlternatives ] = useState<ExerciseAlternative[]>([])
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  const exerciseState = useWHMSelector(exerciseSelector)
  const exercisesState = useWHMSelector(alternativesSelector)
  const [ getExercises, { isLoading, isError }] = useLazyGetExercisesQuery()
  const dispatch = useWHMDispatch()

  useEffect(() => {
    setCurrentPage(0)
    loadMoreItems(10, 0)
  },[searchText, focus])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(perPage, currentPage)
  }

  const loadMoreItems = async (perPage: number, currentPage: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const exercises = await getExercises({ 
        currentPage, 
        perPage, 
        filter: searchText, 
        filterByFocus: focus 
      }).unwrap()
  
      if (!exercises || !exercises.data.length) {
        setHasMore(false)
      } else {
        if (currentPage === 0) {
          dispatch(setInitialAlternatives({ exercises: exercises }))
          setHasMore(true)
          setCurrentPage(1)
        } else if (exercises.pagination.lastPage === currentPage) {
          dispatch(setAlternatives({ exercises: exercises }))
          setHasMore(false)
        } else {
          dispatch(setAlternatives({ exercises: exercises }))
          setHasMore(true)
          setCurrentPage(prev => prev + 1)
        }
      }
      setTimeout(() => {
        setIsLoadMore(false)
      }, 1000)
    } catch (error) {
      console.log({error})
    }
  }

  const addNewAlternatives = (items: ExerciseAlternative[]) => {
    dispatch(updateAlternativeList(items))
    dispatch(closeModal())
  }

  const renderModalCards = () => {
    if (isLoading) <CardLogo />
    if (isError)   <CardError />
    if (!exercisesState.exercises.data.length) return (
      <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
        No Exercise
      </div>
    )

    return exercisesState.exercises.data.map((option, index) => {
      const isSelected = newAlternatives.find(alternative => alternative.alternativeExerciseId === option.id)
      const isChecked = exerciseState?.alternatives?.find(alternative => alternative.id === option.id)
      return (
        <ModalCard 
          key={index}
          title={option.title}
          checked={
            isChecked === undefined &&
            isSelected === undefined 
            ? false
            : true
          }
          addNewItem={() => setNewAlternatives(prev => [
            ...prev, 
            { 
              alternativeExerciseId: option.id, 
              exerciseId: exerciseState.exercise.id
            }
          ])}
          removeItem={() => {
            const filteredAlternatives = newAlternatives.filter(alternative => 
              alternative.alternativeExerciseId !== option.id
            )
            setNewAlternatives(filteredAlternatives)
          }}
        />
      )
    })
  }

  return (
    <div className='max-w-[500px]'>
      <Dialog.Title className='w-full flex items-center justify-between'>
          <div className='w-full flex items-center justify-center relative'>
            <div
                className='absolute left-0 cursor-pointer hover:scale-110 transition-all duration-200' 
                onClick={() => dispatch(closeModal())}
            >
                <ArrowLeftIcon />
            </div>
            <p className='w-full text-2xl font-bold text-center'>
            Add Home Alternative Exercise
            </p>
          </div>
      </Dialog.Title>
      <hr className='whm-divider'/>
      <Dialog.Description className='my-3'>
          <span className='text-lg m-3'>
          Select what Home Alternative exercise you would like to add to this workout.
          </span>
      </Dialog.Description>
      <WHMSearchbar onChange={onChangeText} style={'py-3'}/>
      <WHMCategoryTab
        selectedItem={focus}
        categories={exerciseFocusList}
        onClick={(focus) => setFocus(focus)}
      />
      <div className='w-full h-[45vh] overflow-y-auto overflow-x-hidden'>
        <InfiniteScroll
            loadMore={fetchMoreData}
            hasMore={hasMore}
            useWindow={false}
            loader={<CardLoading key={0}/>}
            initialLoad={true}
            threshold={200}
        >
            { renderModalCards() }
        </InfiniteScroll>
      </div>
      <hr className='whm-divider my-4'/>
      <WHMButton 
        text='Add Exercise'
        className='golden-button'
        onSubmit={() => addNewAlternatives(newAlternatives)}
      />
    </div>
  )
}

export default AlternativesModal