const noticeIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="question_answer_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
        >
            <path id="Path_880" data-name="Path 880" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_881" data-name="Path 881" d="M15,4v7H5.17l-.59.59L4,12.17V4H15m1-2H3A1,1,0,0,0,2,3V17l4-4H16a1,1,0,0,0,1-1V3A1,1,0,0,0,16,2Zm5,4H19v9H6v2a1,1,0,0,0,1,1H18l4,4V7A1,1,0,0,0,21,6Z"/>
        </svg>
      </div>
    )
  }
  
  export default noticeIcon