import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialUserStripeState } from '../../../assets/data/InitialState'
import { UserStripePaymentsData } from '../../../types/stripeType'

export type StripeSliceType = {
    stripe: UserStripePaymentsData
}

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState: InitialUserStripeState,
    reducers: {
        setInitialUserStripe: (state, action: PayloadAction<StripeSliceType>) => {
            state.stripe = action.payload.stripe
        },
        setUserStripe: (state, action: PayloadAction<StripeSliceType>) => {
            const { data: storedStripes } = state.stripe
            const { data: newStripes } = action.payload.stripe
            const stripeIds = storedStripes.map(stripe => stripe.id)
            const filteredDuplicates = newStripes.filter(stripe => !stripeIds.includes(stripe.id))
            filteredDuplicates.map(stripe => state.stripe.data.push(stripe))
        },
        clearStripe: (state) => {
            state.stripe = {
                data: [],
                has_more: false,
                object: '',
                url: '',
                next_page: '',
                total_count: 0
            }
        }
    }
})

export const userStripeSelector = (state: RootState) => state.userStripe
export const {
    setInitialUserStripe,
    setUserStripe,
    clearStripe,
} = stripeSlice.actions
export default stripeSlice.reducer