import '../../assets/styles/animation.css'
import { useNavigate } from 'react-router-dom'
import { useAdminLoginMutation } from '../../services/AuthService'
import { useFormik } from 'formik'
import { InitialLoginState } from '../../assets/data/InitialState'
import { LoginSchema } from '../../utils/validationSchema'
import asyncTimeout from '../../utils/asyncTimeout'
import { PurpleLogo } from '../../assets/images/images'
import { LoginField } from '../../components/molecules/molecules'
import { useWHMDispatch } from '../../app/hooks'
import { closeSnackbar, openSnackbar } from '../../features/Snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../assets/data/enums'
import { setCredentials } from '../../features/Auth/authSlice'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useWHMDispatch()
    const [ adminLogin ] = useAdminLoginMutation()
    const formik = useFormik({
        initialValues: InitialLoginState,
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            try {
                const [ data ] = await Promise.all([
                    adminLogin(values).unwrap(),
                    asyncTimeout(1500)
                ])
                if (!data.accessToken.token) throw Error
                // dispatch(setCredentials(data))
                localStorage.setItem('WHMAdminToken', data.accessToken.token)
                navigate('/notice-board')
            } catch (error) {
                dispatch(openSnackbar({
                    snackbarType: SNACKBAR_TYPES.ERROR,
                    snackbarMessage: ERROR_MESSAGES.LOGIN,
                    snackbarTimeout: 10000,
                    snackbarBtnText: SNACKBAR_BUTTON_TYPES.RETRY,
                    snackbarBtnFunc: () => {
                        formik.setFieldValue('email', '')
                        formik.setFieldValue('password', '')
                        dispatch(closeSnackbar())
                    }
                }))
            }
        }
    })

    return (
        <div 
            className='min-h-screen w-full flex justify-center items-center 
            bg-gradient-to-br from-WHMGold/50 to-WHMDarkPurple/70 
            background-animate'
        >
            <div 
                className='w-[480px] mx-auto flex justify-center items-center flex-col 
                bg-WHMWhite/50 rounded-[10px] px-5 py-3 drop-shadow-xl shadow-lg'
            >
                <div className='w-full flex justify-center items-center py-4'>
                    <img src={PurpleLogo} className='w-auto h-auto max-h-[100px] max-w-[90px]' alt='WHM-purple'/>
                </div>
                <div className='w-full flex text-SuggesterfyRed font-bold text-3xl mt-2'>
                    <span className='animate-waving-hand mr-3'>👋</span> Welcome Back
                </div>
                <div className='w-full text-xl font-medium text-SuggesterfyDark'>
                Enter your email and password to enter.
                </div>
                <LoginField formik={formik}/>
            </div>
        </div>
    )
}

export default Login