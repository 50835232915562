import { RFC } from '../../../types/propTypes'

type WHMDetailTextAreaProps = {
    placeholder: string
    value: string
    onChange: (text: string) => void
}

const WHMDetailTextArea:RFC<WHMDetailTextAreaProps> = ({
    placeholder,
    value,
    onChange
}) => {
    return (
        <div className='w-full'>
            <textarea 
                placeholder={placeholder}
                value={value}
                className='w-full text-WHMDarkPurple rounded-lg
                bg-WHMDarkWhite px-3 py-[6px] outline-none border-WHMBorder
                border-[1px] border-solid shadow-sm'
                rows={7}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

export default WHMDetailTextArea
