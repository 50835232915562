import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { lessonSelector, selectLesson } from '../../../features/Resources/Lessons/lessonSlice'
import { lessonsSelector } from '../../../features/Resources/Lessons/lessonsSlice'
import { Lesson } from '../../../types/stateTypes'
import { domAnimation, LazyMotion } from 'framer-motion'
import { CardError, CardLogo, TitleCard } from '../../atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type LessonTitlesProps = {
    isLoading: boolean
    isError:   boolean
    onRemoveNew: () => void
}

const LessonTitles:RFC<LessonTitlesProps> = ({
    isLoading,
    isError, 
    onRemoveNew
}) => {
    const lessonState = useWHMSelector(lessonSelector)
    const lessonsState = useWHMSelector(lessonsSelector)
    const dispatch = useWHMDispatch()

    const handleClickCard = (item: Lesson) => {
        onRemoveNew()
        dispatch(selectLesson({ id: item.id, lesson: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!lessonsState.lessons.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Lesson
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='lesson_card'>
            {lessonsState.lessons.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={lessonState.id === item.id}
                    onClick={() => handleClickCard(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <div className='flex'>
                                <p>Assigned</p>
                                <p className='mx-1'>●</p>
                                <p className='truncate'>{item.course?.title}</p>
                            </div>
                            <p className='truncate text-2xl font-bold'>{item.title}</p>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default LessonTitles