const checkedIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '20'}
            height={height ?? '20'}
            viewBox="0 0 20 20"
        >
            <g id="Group_1336" data-name="Group 1336" transform="translate(11101 15539)">
                <path id="Path_66" data-name="Path 66" d="M12,4a8,8,0,1,0,8,8A8.011,8.011,0,0,0,12,4ZM10,17,6,13l1.41-1.41L10,14.17l6.59-6.59L18,9Z" transform="translate(-11103 -15541)" fill='#D6A156'/>
                <path id="Path_67" data-name="Path 67" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM16.59,7.58,10,14.17,7.41,11.59,6,13l4,4,8-8Z" transform="translate(-11103 -15541)" fill="#5e257d"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default checkedIcon