import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialWorkoutState } from '../../../assets/data/InitialState'
import { WorkoutData } from '../../../types/serviceTypes'
import { ExerciseGroup } from '../../../types/stateTypes'

export type WorkoutSliceType = {
    id:      string
    workout: WorkoutData
}

export const workoutSlice = createSlice({
    name: 'workout',
    initialState: InitialWorkoutState,
    reducers: {
        selectWorkout: (state, action:PayloadAction<WorkoutSliceType>) => {
            state.id      = action.payload.id
            state.workout = action.payload.workout
        },
        clearWorkout: (state) => {
            state.id      = ''
            state.workout = { id: '' }
        },
        updateWorkoutTitle: (state, action:PayloadAction<string>) => {
            state.workout.title = action.payload
        },
        updateWorkoutDescription: (state, action:PayloadAction<string>) => {
            state.workout.description = action.payload
        },
        updateWorkoutFocus: (state, action:PayloadAction<string>) => {
            state.workout.focus = action.payload
        },
        addWorkoutExercise: (state, action:PayloadAction<ExerciseGroup>) => {                                                                                                                                                                                                                                                                                                                                                              
            if (!state.workout.exerciseGroup)
                state.workout.exerciseGroup = [ action.payload ]
            else
                state.workout.exerciseGroup.push(action.payload)
        },
        removeWorkoutExercise: (state, action:PayloadAction<ExerciseGroup>) => {
            const filteredWorkoutExercise = state.workout.exerciseGroup?.filter(exercise => exercise.id !== action.payload.id)
            state.workout.exerciseGroup   = filteredWorkoutExercise
        }
    }
})

export const workoutSelector = (state: RootState) => state.workout
export const {
    selectWorkout,
    clearWorkout,
    updateWorkoutTitle,
    updateWorkoutDescription,
    updateWorkoutFocus,
} = workoutSlice.actions
export default workoutSlice.reducer