import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { helpTicketSelector, selectHelpTicket } from '../../../features/Notice/HelpTickets/helpTicketSlice'
import { helpTicketsSelector } from '../../../features/Notice/HelpTickets/helpTicketsSlice'
import { CardError, CardLogo, TitleCard, WHMAsyncAvatar } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type HelpTicketTitlesProps = {
  isLoading: boolean
  isError:   boolean
}

const HelpTicketTitles:RFC<HelpTicketTitlesProps> = ({
    isLoading,
    isError
}) => {
    const helpTicketState = useWHMSelector(helpTicketSelector)
    const helpTicketsState = useWHMSelector(helpTicketsSelector)
    const dispatch = useWHMDispatch()

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!helpTicketsState.helpTickets.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Help Ticket
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='help_ticket_card'>
            {helpTicketsState.helpTickets.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={ helpTicketState.helpTicket.id === item.id }
                    onClick={ () => dispatch(selectHelpTicket({ id: item.id, helpTicket: item })) }
                >
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center space-x-4'>
                            <WHMAsyncAvatar fileName={item?.user?.avatar} />
                            <div>
                                <p className='text-xl font-bold'>
                                    {item?.user?.firstName}{" "}
                                    {item?.user?.lastName}
                                </p>
                                <p>
                                    {item?.topic}
                                </p>
                            </div>
                        </div>
                        <ArrowRightIcon width={18} height={18}/>
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default HelpTicketTitles