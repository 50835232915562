const trainingIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="fitness_center_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 30 30"
        >
            <path id="Path_26" data-name="Path 26" d="M0,0H30V30H0Z" fill="none"/>
            <path id="Path_27" data-name="Path 27" d="M25.212,18.075,27,16.288,25.212,14.5,20.75,18.963,10.038,8.25,14.5,3.788,12.713,2,10.925,3.788,9.138,2,6.463,4.675,4.675,2.888,2.888,4.675,4.675,6.462,2,9.138l1.788,1.788L2,12.713,3.788,14.5,8.25,10.038,18.963,20.75,14.5,25.212,16.288,27l1.788-1.788L19.863,27l2.675-2.675,1.787,1.788,1.788-1.788-1.788-1.787L27,19.863Z" transform="translate(0.5 0.5)"/>
        </svg>
      </div>
    )
  }
  
  export default trainingIcon