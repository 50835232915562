import { useMemo, useState } from 'react'
import { ExerciseFocusTypes, RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock, WHMCategoryTab } from '../../atoms/atoms'
import { exerciseFocusList } from '../../../assets/data/arrays'
import ExercisesTitles from '../../../features/Training/Exercises/ExercisesTitles'

type ExercisesListProps = {
  isNewExercise:    boolean
  setIsNewExercise: (isOpen: boolean) => void
}

const ExercisesList:RFC<ExercisesListProps> = ({ isNewExercise, setIsNewExercise }) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const [ focus, setFocus ] = useState<ExerciseFocusTypes>('All')

  return (
    <>
      <WHMTitleBlock
        title="Exercises"
        btnTitle="New"
        onClick={() => setIsNewExercise(!isNewExercise)}
      />
      <WHMSearchbar onChange={onChangeText} />
      <WHMCategoryTab
        selectedItem={focus}
        categories={exerciseFocusList}
        onClick={(focus) => setFocus(focus)}
      />
      <ExercisesTitles
        isNewExercise={isNewExercise}
        searchText={searchText}
        selectedFocus={focus}
        onRemoveNew={() => setIsNewExercise(false)}
      />
    </>
  )
}

export default ExercisesList