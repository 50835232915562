import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExerciseDetailState } from '../../../assets/data/InitialState'
import { Exercise, ExerciseGroup, ExerciseGroupInformation } from '../../../types/stateTypes'
import { v4 as uuidv4 } from 'uuid'

export type ExerciseDetailSliceType = {
    initialGroups:  ExerciseGroup[]
    exerciseGroups: ExerciseGroup[]
    exerciseGroup:  ExerciseGroup
    lastOrderIndex: number
}

export type ExerciseSettingType = {
    value: string
    exercise: ExerciseGroupInformation
}

export const exerciseDetailSlice = createSlice({
    name: 'exerciseDetail',
    initialState: InitialExerciseDetailState,
    reducers: {
        setInitialExerciseList: (state, action:PayloadAction<ExerciseGroup[] | undefined>) => {
            if (!action.payload) return
            state.initialGroups  = action.payload
            state.exerciseGroups = action.payload
            state.lastOrderIndex = action.payload.length - 1
        },
        clearExerciseList: (state) => {
            state.initialGroups  = []
            state.exerciseGroups = []
            state.exerciseGroup  = {
                exerciseGroupInformation: [],
                id:         '',
                orderIndex: 0,
                workoutId:  ''
            }
            state.lastOrderIndex = -1
        },
        addNewExercises: (state, action:PayloadAction<Exercise[]>) => {
            const newExercise = action.payload.map((exercise, index) => {
                return {
                    id: uuidv4(),
                    orderIndex: index,
                    defaultSets:               0,
                    defaultReps:               '0',
                    deloadingSets:             0,
                    deloadingReps:             '0',
                    workTimeS:                 0,
                    restTimeS:                 0,
                    tempoS:                    0,
                    exerciseGroupId:           '',
                    exerciseId:                exercise.id,
                    exercise,
                    exerciseHomeAlternative:   null,
                    exerciseHomeAlternativeId: null
                }
            })
            const newExerciseGroup = {
                exerciseGroupInformation: newExercise,
                id: `groupId:${uuidv4()}`,
                orderIndex: state.lastOrderIndex + 1,
                workoutId: ''
            }
            state.exerciseGroups = [ ...state.exerciseGroups, newExerciseGroup ]
            state.lastOrderIndex++
        },
        removeNewExercise: (state, action:PayloadAction<string>) => {
            for(let i = 0; i < state.exerciseGroups.length; i++) {
                const singleExerciseGroup = state.exerciseGroups[i]
                const isFound = singleExerciseGroup.exerciseGroupInformation.filter(exercise => 
                    exercise.id === action.payload).length
                if(isFound) {
                    // decrement orderIndex when last exercise in a exerciseGroup is deleted
                    if (singleExerciseGroup.exerciseGroupInformation.length === 1) {
                        state.lastOrderIndex--
                        for(let j = (i + 1); j < state.exerciseGroups.length; j++) {
                            state.exerciseGroups[j].orderIndex--
                        }
                    }
                    
                    if (singleExerciseGroup.exerciseGroupInformation.length === 1) {
                        const filteredGroups = state.exerciseGroups.filter(group => group.id !== singleExerciseGroup.id)
                        state.exerciseGroups = filteredGroups
                    } else {
                        // filter a deleted exercise and update the exercise group
                        const updatedExerciseGroup = singleExerciseGroup.exerciseGroupInformation.filter(exercise => 
                            exercise.id !== action.payload
                        )
                        state.exerciseGroups[i].exerciseGroupInformation = [...updatedExerciseGroup]
                    }
                }
            }
        },
        selectExerciseGroup: (state, action:PayloadAction<ExerciseGroup>) => {
            if (state.exerciseGroup.id) {
                state.exerciseGroups.forEach((exercise, index) => {
                    if (exercise.id === state.exerciseGroup.id) 
                    state.exerciseGroups[index] = state.exerciseGroup
                })
            }
            state.exerciseGroup = action.payload
        },
        removeExerciseGroup: (state) => {
            state.exerciseGroup = {
                exerciseGroupInformation: [],
                id:                       '',
                orderIndex:               0,
                workoutId:                ''
            }
        },
        removeExerciseWithId: (state, action:PayloadAction<string>) => {
            const filteredExercises = state.exerciseGroup.exerciseGroupInformation.filter(exercise => exercise.id !== action.payload)
            state.exerciseGroup.exerciseGroupInformation = filteredExercises
        },
        updateExerciseGroups: (state, action:PayloadAction<ExerciseGroupInformation[]>) => {
            state.exerciseGroups.forEach((group, index) => {
                if (group.id === state.exerciseGroup.id)
                    state.exerciseGroups[index] = {
                        ...state.exerciseGroup,
                        exerciseGroupInformation: action.payload
                    }
            })
        },
        addHomeAlternative: (state, action: PayloadAction<{ exercise: Exercise | undefined, infoId: string | undefined }>) => {
            if (!action.payload.exercise || !action.payload.infoId) return
            const homeAlternativeId = uuidv4()
            const newHomeAlternativeObj = {
                defaultReps:               '1',
                defaultSets:               1,
                deloadingReps:             '1',
                deloadingSets:             1,
                exercise:                  action.payload.exercise,
                exerciseGroupId:           state.exerciseGroup.id,
                exerciseId:                action.payload.exercise.id,
                id:                        homeAlternativeId,
                orderIndex:                0,
                restTimeS:                 1,
                tempoS:                    1,
                workTimeS:                 1,
                exerciseHomeAlternative:   null,
                exerciseHomeAlternativeId: null,
            }
            state.exerciseGroup.exerciseGroupInformation.forEach((exercise, index) => {
                if (exercise.id === action.payload.infoId) {
                    if (!action.payload.exercise) return
                    state.exerciseGroup.exerciseGroupInformation[index].exerciseHomeAlternativeId = newHomeAlternativeObj.exerciseId
                    state.exerciseGroup.exerciseGroupInformation[index].exerciseHomeAlternative   = newHomeAlternativeObj
                }
            })
            state.exerciseGroups.forEach((group, index) => {
                if (group.id === state.exerciseGroup.id)
                state.exerciseGroups[index] = state.exerciseGroup
            })
        },
        removeHomeAlternative: (state, action:PayloadAction<string>) => {
            state.exerciseGroup.exerciseGroupInformation.forEach((exercise, index) => {
                if (exercise.id === action.payload) {
                    state.exerciseGroup.exerciseGroupInformation[index].exerciseHomeAlternativeId = null
                    state.exerciseGroup.exerciseGroupInformation[index].exerciseHomeAlternative   = null
                }
            })
            state.exerciseGroups.forEach((group, index) => {
                if (group.id === state.exerciseGroup.id)
                    state.exerciseGroups[index] = state.exerciseGroup
            })
        },
        updateDefaultSets: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.defaultSets = ~~action.payload.value
                    return {
                        ...group,
                        defaultSets: ~~action.payload.value
                    }
                } else {
                    return {...group}
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: ''})
        },
        updateDefaultReps: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.defaultReps = action.payload.value
                    return {
                        ...group,
                        defaultReps: action.payload.value
                    }
                } else {
                    return {...group}
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateDeloadingSets: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.deloadingSets = ~~action.payload.value
                    return {
                        ...group,
                        deloadingSets: ~~action.payload.value
                    }
                } else {
                    return {...group}
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateDeloadingReps: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.deloadingReps = action.payload.value
                    return {
                        ...group,
                        deloadingReps: action.payload.value
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateWorkOn: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.workTimeS = ~~action.payload.value
                    return {
                        ...group,
                        workTimeS: ~~action.payload.value
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateRestTrans: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.restTimeS = ~~action.payload.value
                    return {
                        ...group,
                        restTimeS: ~~action.payload.value
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateTempo: (state, action:PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseId === action.payload.exercise.exerciseId) {
                    group.tempoS = ~~action.payload.value
                    return {
                        ...group,
                        tempoS: ~~action.payload.value
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeDefaultSets: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.defaultSets = ~~action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            defaultSets: ~~action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeDefaultReps: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.defaultReps = action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            defaultReps: action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeDeloadingSets: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.deloadingSets = ~~action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            deloadingSets: ~~action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeDeloadingReps: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.deloadingReps = action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            deloadingReps: action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeWorkOn: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.workTimeS = ~~action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            workTimeS: ~~action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeRestTrans: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.restTimeS = ~~action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            restTimeS: ~~action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
        updateHomeAlternativeTempo: (state, action: PayloadAction<ExerciseSettingType>) => {
            const updatedGroup = state.exerciseGroup.exerciseGroupInformation.map(group => {
                if (group.exerciseHomeAlternative?.exerciseId === action.payload.exercise.exerciseId) {
                    if (!group.exerciseHomeAlternative) return { ...group }
                    group.exerciseHomeAlternative.tempoS = ~~action.payload.value
                    return {
                        ...group,
                        exerciseHomeAlternative: {
                            ...group.exerciseHomeAlternative,
                            tempoS: ~~action.payload.value
                        }
                    }
                } else {
                    return { ...group }
                }
            })
            exerciseDetailSlice.caseReducers.updateExerciseGroups(state, { payload: updatedGroup, type: '' })
        },
    }
})

export const exerciseDetailSelector = (state: RootState) => state.exerciseDetail
export const exerciseGroupSelector  = (state: RootState) => state.exerciseDetail.exerciseGroup
export const {
    setInitialExerciseList,
    clearExerciseList,
    addNewExercises,
    removeNewExercise,
    selectExerciseGroup,
    removeExerciseGroup,
    removeExerciseWithId,
    updateExerciseGroups,
    addHomeAlternative,
    removeHomeAlternative,
    updateDefaultSets,
    updateDefaultReps,
    updateDeloadingSets,
    updateDeloadingReps,
    updateWorkOn,
    updateRestTrans,
    updateTempo,
    updateHomeAlternativeDefaultSets,
    updateHomeAlternativeDefaultReps,
    updateHomeAlternativeDeloadingSets,
    updateHomeAlternativeDeloadingReps,
    updateHomeAlternativeWorkOn,
    updateHomeAlternativeRestTrans,
    updateHomeAlternativeTempo,
} = exerciseDetailSlice.actions
export default exerciseDetailSlice.reducer