const UserCardSkeleton = () => {
    return (
        <div
            role='status'
            className='w-[80%] animate-pulse my-2'
        >
            <div className='h-16 bg-WHMLightGrey rounded-[5px] dark:bg-WHMGrey w-full'></div>
        </div>
    )
}

export default UserCardSkeleton