import { RFC } from '../../../types/propTypes'
import { AnimatePresence } from 'framer-motion'
import { ALL_TITLES } from '../../../assets/data/enums'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { NewCard, WHMStatus } from '../../../components/atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearGroup, groupSelector } from './groupSlice'
import { useEffect } from 'react'

type GroupsTitlesProps = {
    isNewGroup:  boolean
    searchText:  string
    onRemoveNew: () => void
}

const GroupsTitles:RFC<GroupsTitlesProps> = ({
    isNewGroup,
    searchText,
    onRemoveNew
}) => {
    const groupState = useWHMSelector(groupSelector)
    const dispatch = useWHMDispatch()

    useEffect(() => {
        dispatch(clearGroup())
    },[isNewGroup])

    const renderAddNewCard = () => {
        return (
            <NewCard>
                <div className='flex items-center justify-between'>
                    <div className='max-w-[90%]'>
                        <p className='truncate text-2xl font-bold'>
                            {groupState.group.name !== '' ? groupState.group.name : 'New Group'}
                        </p>
                        <div className='flex items-center'>
                            <WHMStatus status={false}/>
                        </div>
                    </div>
                    <ArrowRightIcon 
                        width={18}
                        height={18}
                    />
                </div>
            </NewCard>
        )
    }

    return (
        <div className='w-full mt-4'>
            <AnimatePresence>
                { isNewGroup && renderAddNewCard() }
                <InfiniteScrollList 
                    key='infinite_scroll'
                    page={ALL_TITLES.GROUPS}
                    searchText={searchText}
                    onRemoveNew={onRemoveNew}
                />
            </AnimatePresence>
        </div>
    )
}

export default GroupsTitles
