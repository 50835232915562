import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialModalState } from '../../assets/data/InitialState'
import { Modal } from '../../types/stateTypes'

type OpenModalPayload = Omit<Modal, 'isDisplayed'>

export const modalSlice = createSlice({
    name: 'modal',
    initialState: InitialModalState,
    reducers: {
        openModal: (state, action:PayloadAction<OpenModalPayload>) => {
            state.isDisplayed = true
            state.modalType   = action.payload.modalType
            state.title       = action.payload.title
            state.body        = action.payload.body
            state.itemId      = action.payload.itemId
            state.deleteId    = action.payload.deleteId
            state.deleteFn    = action.payload.deleteFn
        },
        closeModal: (state) => {
            state.isDisplayed = false
        }
    }
})

export const modalSelector = (state:RootState) => state.modal
export const {
    openModal,
    closeModal
} = modalSlice.actions
export default modalSlice.reducer