import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { groupsSelector } from '../../../features/Community/Groups/groupsSlice'
import { groupSelector, selectGroup } from '../../../features/Community/Groups/groupSlice'
import { Group } from '../../../types/stateTypes'
import { CardError, CardLogo, TitleCard, WHMStatus } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type GroupTitlesProps = {
  isLoading:   boolean
  isError:     boolean
  onRemoveNew: () => void
}

const GroupTitles:RFC<GroupTitlesProps> = ({
  isLoading,
  isError,
  onRemoveNew
}) => {
  const groupState = useWHMSelector(groupSelector)
  const groupsState = useWHMSelector(groupsSelector)
  const dispatch = useWHMDispatch()

  const handleClickCard = (item: Group) => {
    onRemoveNew()
    dispatch(selectGroup(item))
  }

  if (isLoading) return <CardLogo />
  if (isError)   return <CardError />
  if (!groupsState.groups.data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No Group
    </div>
  )
  
  return (
    <LazyMotion features={domAnimation} key='group_card'>
      {groupsState.groups.data.map((item, index) => (
        <TitleCard
          key={index}
          isSelected={groupState.group.name == item.name}
          onClick={() => handleClickCard(item)}
        >
          <div className='flex items-center justify-between'>
            <div className='max-w-[90%]'>
              <p className='truncate text-2xl font-bold'>
                {item.name}
              </p>
              <div className='flex items-center'>
                  <WHMStatus status={(item.isActive || item.isActive === 1) ? true : false} />
              </div>
            </div>
            <ArrowRightIcon 
                width={18}
                height={18}
            />
          </div>
        </TitleCard>
      ))}
    </LazyMotion>
  )
}

export default GroupTitles