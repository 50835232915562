import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialReportsState } from '../../../assets/data/InitialState'
import { DeleteReport, ReportsData } from '../../../types/serviceTypes'

export type ReportsSliceType = {
    reports: ReportsData
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: InitialReportsState,
    reducers: {
        clearReports: (state) => {
            state.reports.data = []
        },
        setInitialReports: (state, action:PayloadAction<ReportsSliceType>) => {
            state.reports.data = action.payload.reports.data
        },
        setReports: (state, action:PayloadAction<ReportsSliceType>) => {
            const { data: storedReports } = state.reports
            const { data: newReports } = action.payload.reports
            const postIds = storedReports.map(report => report.postId)
            const filteredDuplicates = newReports.filter(report => !postIds.includes(report.postId))
            filteredDuplicates.map(report => state.reports.data.push(report))
        },
        deleteReportWithIds: (state, action:PayloadAction<DeleteReport>) => {
            const filteredReports = state.reports.data.filter(report => 
                (report.postId !== action.payload.postId ||
                report.userId !== action.payload.userId)
            )
            state.reports.data = filteredReports
        }
    }
})

export const reportsSelector = (state: RootState) => state.reports
export const {
    clearReports,
    setInitialReports,
    setReports,
    deleteReportWithIds,
} = reportsSlice.actions
export default reportsSlice.reducer