import { useEffect, useMemo, useState } from 'react'
import { Workout } from '../../../types/stateTypes'
import { debounce } from 'lodash'
import { useLazyGetWorkoutsQuery } from '../../../services/WorkoutService'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { addProgramWorkouts, programSelector } from '../../../features/Training/Programs/programSlice'
import { programWorkoutsSelector, setInitialProgramWorkouts, setProgramWorkouts } from '../../../features/Training/Workouts/programWorkoutsSlice'
import { closeModal } from '../../../features/Modal/modalSlice'
import { CardError, CardLoading, CardLogo, ModalCard, WHMButton, WHMFilterDropdown, WHMSearchbar } from '../../atoms/atoms'
import { Dialog } from '@headlessui/react'
import { ArrowLeftIcon } from '../../../assets/icons/icons'
import { workoutFocusList } from '../../../assets/data/arrays'
import InfiniteScroll from 'react-infinite-scroller'
import getPhaseTabIndex from '../../../utils/getPhaseTabIndex'
import { WorkoutFocusTypes } from '../../../types/propTypes'

const AddWorkoutModal = () => {
    const perPage = 10
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ searchText, setSearchText ] = useState('')
    const [ filter, setFilter ] = useState('')
    const [ newWorkouts, setNewWorkouts ] = useState<Workout[]>([])
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

    const programWorkoutsState = useWHMSelector(programWorkoutsSelector)
    const { program, phaseTab } = useWHMSelector(programSelector)
    const { phase } = program
    const dispatch = useWHMDispatch()
    const [ getWorkouts, { isLoading, isError } ] = useLazyGetWorkoutsQuery()

    useEffect(() => {
        setCurrentPage(0)
        loadMoreItems(10, 0)
    }, [searchText])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(perPage, currentPage)
    }

    const loadMoreItems = async (perPage: number, currentPage: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const workouts = await getWorkouts({
                currentPage,
                perPage,
                filter: searchText,
                filterByFocus: filter as WorkoutFocusTypes ?? ''
            }).unwrap()
            
            if (!workouts  || !workouts.data.length) {
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    dispatch(setInitialProgramWorkouts({ programWorkouts: workouts }))
                    setHasMore(true)
                    setCurrentPage(1)
                } else if (workouts.pagination.lastPage === currentPage) {
                    dispatch(setProgramWorkouts({ programWorkouts: workouts }))
                    setHasMore(false)
                } else {
                    dispatch(setProgramWorkouts({ programWorkouts: workouts }))
                    setHasMore(true)
                    setCurrentPage(prev => prev + 1)
                }
            }
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    const addNewProgramWorkouts = () => {
        dispatch(addProgramWorkouts(newWorkouts))
        dispatch(closeModal())
    }

    const renderModalCards = () => {
        if (isLoading) return <CardLogo />
        if (isError)   return <CardError />
        if (!programWorkoutsState.programWorkouts.data.length) return (
            <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
                No Workout
            </div>
        )

        return programWorkoutsState.programWorkouts.data.map((option, index) => {
            const workoutPhase = phase && phase[getPhaseTabIndex(phaseTab)]
            const selectedLength = workoutPhase?.phaseWorkout.length ?? 0
            const isMaxNumber = (selectedLength + newWorkouts.length) > 6
            const checkedWorkout = workoutPhase && workoutPhase?.phaseWorkout.find(workout =>
                workout.workout.id === option.id
            )
            const isSelected = newWorkouts.find(workout => workout.id === option.id)
            const isNotAssigned = option.phaseWorkout === null
            
            return (
                <ModalCard 
                    key={index}
                    title={option.title}
                    checked={checkedWorkout === undefined && isSelected === undefined
                        ? false 
                        : true
                    }
                    isNotAssigned={isNotAssigned}
                    addNewItem={() => !isMaxNumber && setNewWorkouts(prev => [...prev, option])}
                    removeItem={() => {
                        const filteredWorkouts = newWorkouts.filter(workout => workout.id !== option.id)
                        setNewWorkouts(filteredWorkouts)
                    }}
                />
            )
        })
    }

return (
        <div className='max-w-[500px]'>
            <Dialog.Title className='w-full flex items-center justify-between'>
                <div className='w-full flex items-center justify-center relative'>
                    <div
                        className='absolute left-0 cursor-pointer hover:scale-110 transition-all duration-200' 
                        onClick={() => dispatch(closeModal())}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <p className='w-full text-2xl font-bold text-center'>
                    Add Workout
                    </p>
                </div>
            </Dialog.Title>
            <hr className='whm-divider'/>
            <Dialog.Description className='my-3'>
                <span className='text-lg m-3'>
                Select what exercise you would like to add to this workout.
                </span>
            </Dialog.Description>
            <WHMSearchbar onChange={onChangeText} style={'py-3'}/>
            <WHMFilterDropdown 
                value={filter === '' ? 'Select' : filter}
                list={workoutFocusList}
                onClick={(option) => setFilter(option)}
            />
            <div className='w-full h-[45vh] overflow-y-auto overflow-x-hidden'>
                <InfiniteScroll
                    loadMore={fetchMoreData}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<CardLoading key={0}/>}
                    initialLoad={true}
                    threshold={200}
                >
                    { renderModalCards() }
                </InfiniteScroll>
            </div>
            <hr className='whm-divider my-4'/>
            <WHMButton 
                 text='Add Workouts'
                 className='golden-button'
                 onSubmit={addNewProgramWorkouts}
            />
        </div>
    )
}

export default AddWorkoutModal