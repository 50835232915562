import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialWorkoutExercisesState } from '../../../assets/data/InitialState'
import { ExercisesData } from '../../../types/serviceTypes'

export type WorkoutExercisesSliceType = {
    workoutExercises: ExercisesData
}

export const workoutExercisesSlice = createSlice({
    name: 'workoutExercises',
    initialState: InitialWorkoutExercisesState,
    reducers: {
        setInitialWorkoutExercises: (state, action:PayloadAction<WorkoutExercisesSliceType>) => {
            state.workoutExercises.data = action.payload.workoutExercises.data
        },
        setWorkoutExercises: (state, action:PayloadAction<WorkoutExercisesSliceType>) => {
            const { data: storedExercises } = state.workoutExercises
            const { data: newExercises } = action.payload.workoutExercises
            const exerciseIds = storedExercises.map(exercise => exercise.id)
            const filteredDuplicates = newExercises.filter(exercise => !exerciseIds.includes(exercise.id))
            filteredDuplicates.map(exercise => state.workoutExercises.data.push(exercise))
        },
        clearWorkoutExercises: (state) => {
            state.workoutExercises.data       = []
            state.workoutExercises.pagination = {}
        }
    }
})

export const workoutExercisesSelector = (state: RootState) => state.workoutExercises
export const {
    setInitialWorkoutExercises,
    setWorkoutExercises,
    clearWorkoutExercises
} = workoutExercisesSlice.actions
export default workoutExercisesSlice.reducer