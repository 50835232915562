import { RFC } from '../../../types/propTypes'
import { domAnimation, LazyMotion } from 'framer-motion'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { feedbackSelector, selectFeedback } from '../../../features/Notice/ProgramFeedback/feedbackSlice'
import { feedbacksSelector } from '../../../features/Notice/ProgramFeedback/feedbacksSlice'
import { CardError, CardLogo, TitleCard, WHMAsyncAvatar } from '../../atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type FeedbackTitlesProps = {
    isLoading: boolean
    isError:   boolean
}

const FeedbackTitles:RFC<FeedbackTitlesProps> = ({
    isLoading,
    isError
}) => {
    const feedbackState = useWHMSelector(feedbackSelector)
    const feedbacksState = useWHMSelector(feedbacksSelector)
    const dispatch = useWHMDispatch()

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!feedbacksState.feedbacks.data.length) return (
      <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
        No Feedback
      </div>
    )

    return (
      <LazyMotion features={domAnimation} key="feedback_card">
        {feedbacksState.feedbacks.data.map((item, index) => (
          <TitleCard
            key={index}
            isSelected={feedbackState.feedback.userProgramId === item.userProgramId }
            onClick={() => dispatch(selectFeedback({ id: item.userProgramId, feedback: item })) }
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <WHMAsyncAvatar fileName={item?.userProgram?.user?.avatar} />
                <div>
                  <p className="text-xl font-bold">
                    {item?.userProgram?.user?.firstName}{" "}
                    {item?.userProgram?.user?.lastName}
                  </p>
                  <p>
                    Program: {item?.userProgram?.program?.title}
                  </p>
                </div>
              </div>
              <ArrowRightIcon width={18} height={18} />
            </div>
          </TitleCard>
        ))}
      </LazyMotion>
    )
}

export default FeedbackTitles