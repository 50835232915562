import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearGroupPost, groupSelector, updateGroupActive, selectGroup, clearGroup, deleteGroupPostWithId } from '../../../features/Community/Groups/groupSlice'
import { addGroup, deleteGroupWithName, updateGroupInfo } from '../../../features/Community/Groups/groupsSlice'
import { useCreateGroupMutation, useDeleteGroupMutation, useDeletePostMutation, useUpdateGroupMutation } from '../../../services/GroupService'
import StatusButtons from '../../molecules/StatusButtons'
import PostsTitles from '../../../features/Community/Groups/PostsTitles'
import CommentsTitles from '../../../features/Community/Reports/CommentsTitles'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import asyncTimeout from '../../../utils/asyncTimeout'
import { DashboardDetails } from '../../templates/templates'
import { PostCard, WHMButton, WHMTitleBlock } from '../../atoms/atoms'
import { BinIcon, EditIcon } from '../../../assets/icons/icons'

type GroupDetailProps = {
  isNewGroup?:    boolean
  setIsNewGroup?: (isOpen: boolean) => void
}

const GroupDetail:RFC<GroupDetailProps> = ({
  isNewGroup,
  setIsNewGroup
}) => {
  // Global State
  const groupState = useWHMSelector(groupSelector)
  const { group, post } = groupState
  const dispatch = useWHMDispatch()
  
  // Services
  const [ createGroup ] = useCreateGroupMutation()
  const [ updateGroup ] = useUpdateGroupMutation()
  const [ deleteGroup ] = useDeleteGroupMutation()
  const [ deletePost ]  = useDeletePostMutation()
  
  const [ isEditable, setIsEditable ] = useState(false)
  const [ newGroupTitle, setNewGroupTitle ] = useState(group.name)

  useEffect(() => {
    setNewGroupTitle(group.name)
    dispatch(clearGroupPost())
  },[group])

  const createNewGroup = async () => {
    try {
      dispatch(openOverlay({ text: 'Creating a new group' }))
      const [ data ] = await Promise.all([
        createGroup({ name: newGroupTitle === '' ? 'New Group' : newGroupTitle, isActive: 0 }).unwrap(),
        asyncTimeout(2000)
      ]) 
      if (!data || !setIsNewGroup) throw Error
      setIsNewGroup(false)
      dispatch(addGroup(data))
      dispatch(selectGroup(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Group Created',
        body: 'You have successfully created a new group!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create group',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const saveGroup = async () => {
    let updateGroupObj = {}
    if (newGroupTitle === group.name) {
      updateGroupObj = {
        isActive: group.isActive, 
        groupName: group.name,
      }
    } else {
      updateGroupObj = {
        name: newGroupTitle, 
        isActive: group.isActive, 
        groupName: group.name,
      }
    }
    try {
      dispatch(openOverlay({ text: 'Saving a new group' }))
      const [ data ] = await Promise.all([
        updateGroup(updateGroupObj).unwrap(),
        asyncTimeout(2000)
      ]) 
      if (!data) throw Error
      dispatch(updateGroupInfo({ group: data, oldName: group.name }))
      setIsEditable(false)
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Group Updated',
        body: 'Your changes have been successfully saved!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update group',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const clearNewGroup = () => {
    if (!setIsNewGroup) return
    setIsNewGroup(false)
  }

  const removeGroup = async (name: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this group' }))
      const [ data ] = await Promise.all([
        deleteGroup({ name }).unwrap(),
        asyncTimeout(2000)
      ])
      if(!data) throw Error
      dispatch(clearGroup())
      dispatch(deleteGroupWithName(name))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'GROUP DELETED',
        body: 'You have successfully deleted the group!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete group',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const removePost = async (postId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this post' }))
      const [ data ] = await Promise.all([
        deletePost(postId).unwrap(),
        asyncTimeout(2000)
      ])
      if(!data) throw Error
      dispatch(clearGroupPost())
      dispatch(deleteGroupPostWithId(postId))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'POST DELETED',
        body: 'You have successfully deleted the post!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete group',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <div className='flex items-center'>
          {isEditable ? (
            <input 
              type='text' 
              className='text-WHMDarkPurple font-bold text-[1.6em] max-w-[15em] 
              px-3 py-2 m-1 outline-none bg-WHMDarkWhite rounded-xl'
              value={newGroupTitle}
              onChange={(e) => setNewGroupTitle(e.target.value)}
            />
          ) : (
            <WHMTitleBlock title={isNewGroup ? 'New Group' : `${group.name}`}/>
          )}
          <EditIcon width={'30'} height={'30'} className='cursor-pointer' onClick={() => setIsEditable(!isEditable)} />
        </div>
        <StatusButtons 
          type='d-as-s'
          isActive={group.isActive === 1 ? true : false}
          onSave={isNewGroup ? createNewGroup : saveGroup}
          onDelete={isNewGroup 
            ? clearNewGroup 
            : () => dispatch(openModal({
              modalType: MODAL_TYPES.DELETE,
              deleteId: newGroupTitle,
              deleteFn: removeGroup
            }))}
          onChangeStatus={() => dispatch(updateGroupActive(
            (group.isActive || group.isActive === 1) ? 0 : 1
          ))}
        />
      </div>
      <DashboardDetails template='grid-cols-2lg'>
      <>
        <PostsTitles groupName={group.name}/>
        {post.id && (
          <div className='w-full'>
            <p className='font-semibold text-lg'>
              View Post
            </p>
            <div className='pr-3 py-2'>
              <PostCard post={post} />
            </div>
            <WHMButton 
              text='Delete Post'
              className='small-light-red-button'
              rightIcon={<BinIcon />}
              onSubmit={() => dispatch(openModal({
                modalType: MODAL_TYPES.DELETE,
                deleteId: post.id,
                deleteFn: removePost
              }))}
            />
            <div className='h-full w-full overflow-y-auto overflow-x-hidden pb-[10em]'>
              <p className='font-semibold text-WHMBlack mb-1 mt-3 ml-1'>
                Post Comments
              </p>
              <CommentsTitles postId={post.id}/>
            </div>
          </div>
        )}
      </>
      </DashboardDetails>
    </>
  )
}

export default GroupDetail