import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import CoursesTitles from '../../../features/Resources/Courses/CoursesTitles'

type CoursesListProps = {
  isNewCourse: boolean
  setIsNewCourse: (isOpen: boolean) => void
}

const CoursesList:RFC<CoursesListProps> = ({ 
  isNewCourse, 
  setIsNewCourse 
}) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock 
        title='Courses'
        btnTitle='New'
        onClick={() => setIsNewCourse(!isNewCourse)} 
      />
      <WHMSearchbar onChange={onChangeText} />
      <CoursesTitles 
        isNewCourse={isNewCourse}
        searchText={searchText}
        onRemoveNew={() => setIsNewCourse(false)} 
      />
    </>
  )
}

export default CoursesList