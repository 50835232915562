import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialProgramWorkoutsState } from '../../../assets/data/InitialState'
import { WorkoutsData } from '../../../types/serviceTypes'

export type ProgramWorkoutSliceType = {
    programWorkouts: WorkoutsData
}

export const programWorkoutsSlice = createSlice({
    name: 'programWorkouts',
    initialState: InitialProgramWorkoutsState,
    reducers: {
        setInitialProgramWorkouts: (state, action:PayloadAction<ProgramWorkoutSliceType>) => {
            state.programWorkouts.data = action.payload.programWorkouts.data
        },
        setProgramWorkouts: (state, action:PayloadAction<ProgramWorkoutSliceType>) => {
            const { data: storedWorkouts } = state.programWorkouts
            const { data: newWorkouts } = action.payload.programWorkouts
            const workoutIds = storedWorkouts.map(workout => workout.id)
            const filteredDuplicates = newWorkouts.filter(workout => !workoutIds.includes(workout.id))
            filteredDuplicates.map(workout => state.programWorkouts.data.push(workout))
        },
        clearProgramWorkouts: (state) => {
            state.programWorkouts.data       = []
            state.programWorkouts.pagination = {}
        }
    }
})

export const programWorkoutsSelector = (state: RootState) => state.programWorkouts
export const {
    setInitialProgramWorkouts,
    setProgramWorkouts,
    clearProgramWorkouts
} = programWorkoutsSlice.actions
export default programWorkoutsSlice.reducer