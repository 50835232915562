import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { exerciseSelector, selectExercise } from '../../../features/Training/Exercises/exerciseSlice'
import { exercisesSelector } from '../../../features/Training/Exercises/exercisesSlice'
import { Exercise } from '../../../types/stateTypes'
import { domAnimation, LazyMotion } from 'framer-motion'
import { CardError, CardLogo, TitleCard } from '../../atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type ExerciseTitlesProps = {
    isLoading:   boolean
    isError:     boolean
    onRemoveNew: () => void
}

const ExerciseTitles:RFC<ExerciseTitlesProps> = ({
    isLoading,
    isError, 
    onRemoveNew
}) => {
    const exerciseState = useWHMSelector(exerciseSelector)
    const exercisesState = useWHMSelector(exercisesSelector)
    const dispatch = useWHMDispatch()

    const handleClickCard = (item: Exercise) => {
        onRemoveNew()
        dispatch(selectExercise({ id: item.id, exercise: item }))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!exercisesState?.exercises?.data?.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Exercise
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='exercise-card'>
            {exercisesState.exercises.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={exerciseState.id === item.id}
                    onClick={() => handleClickCard(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <p className="truncate text-2xl font-bold">{item.title}</p>
                            <div className="flex items-center">
                                <p className='font-semibold'>Focus:</p>
                                <div className='flex items-center placeholder:truncate truncate'>
                                    {!item?.exerciseFocus?.length 
                                    ?   <p className='mx-1'>none</p>
                                    :   item.exerciseFocus.map((focus, index) => (
                                        <div key={index} className='flex'>
                                            <p className='mx-1'>
                                                {focus.focus}
                                            </p>
                                            {(index !== item.exerciseFocus?.length - 1 )&& (
                                                <span className='mx-1'>/</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default ExerciseTitles