import { RFC } from '../../types/propTypes'
import { ExerciseGroupInformation } from '../../types/stateTypes'
import { useWHMDispatch } from '../../app/hooks'
import { DraggableBinIcon } from '../../assets/icons/icons'
import { 
    removeHomeAlternative, updateHomeAlternativeDefaultReps, 
    updateHomeAlternativeDefaultSets, updateHomeAlternativeDeloadingReps, 
    updateHomeAlternativeDeloadingSets, updateHomeAlternativeRestTrans, 
    updateHomeAlternativeTempo, updateHomeAlternativeWorkOn 
} from '../../features/Training/Workouts/exerciseDetailSlice'
import { HomeAlternativeCard, WHMButton } from '../atoms/atoms'
import { openModal } from '../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../assets/data/enums'

type HomeAlternativeDetailProps = {
    exerciseId:      string
    homeAlternative: ExerciseGroupInformation | null
}

const HomeAlternativeDetail:RFC<HomeAlternativeDetailProps> = ({
    exerciseId,
    homeAlternative,
}) => {
    const dispatch = useWHMDispatch()

    return (
        <div className='rounded-md px-2 py-3 mb-2 bg-WHMGreen/20'>
            <p className='font-bold text-WHMGreen'>
                Home Alternative
            </p>
            <p className='font-light text-[0.9em]'>
                Users who choose the home variant of a workout will see the home alternative exercise.
                In this section  choose an exercise that this user can do at home
            </p>
            <div className='flex items-center justify-center my-2'>
                {homeAlternative ? (
                    <div className='w-full'>
                        <div className='flex items-center mb-2'>
                            <div
                                className='flex items-center justify-between text-WHMWhite 
                                w-[95%] font-semibold bg-WHMGreen/80 rounded-[5px] px-3 py-2'
                            >
                                {homeAlternative?.exercise?.title}
                            </div>
                            <DraggableBinIcon
                                className='cursor-pointer'
                                onClick={() => dispatch(removeHomeAlternative(exerciseId))}
                            />
                        </div>
                        <HomeAlternativeCard
                            title='Default'
                            desc='Default or the original WHM recommended sets &amp; reps for Gym and Home. (Repetition: e.g. 10 or 8-10)'
                            values={homeAlternative}
                            isHomeAlternative={true}
                            onChangeDefaultSets={(text) => dispatch(updateHomeAlternativeDefaultSets({ value: text, exercise: homeAlternative }))}
                            onChangeDefaultReps={(text) => dispatch(updateHomeAlternativeDefaultReps({ value: text, exercise: homeAlternative }))}
                        />
                        <HomeAlternativeCard
                            title='Deloading'
                            desc='Deloading or Rest settings are set for when users turn on either R&amp;R mode or Deloading. (Repetition: e.g. 10 or 8-10)'
                            values={homeAlternative}
                            isHomeAlternative={true}
                            onChangeDeloadingSets={(text) => dispatch(updateHomeAlternativeDeloadingSets({ value: text, exercise: homeAlternative }))}
                            onChangeDeloadingReps={(text) => dispatch(updateHomeAlternativeDeloadingReps({ value: text, exercise: homeAlternative }))}
                        />
                        <HomeAlternativeCard
                            title='Work, Transition and Tempo'
                            desc='Set the work and transition time periods for this exercise. This is set in seconds.'
                            values={homeAlternative}
                            isHomeAlternative={true}
                            onChangeWork={(text) => dispatch(updateHomeAlternativeWorkOn({ value: text, exercise: homeAlternative }))}
                            onChangeRest={(text) => dispatch(updateHomeAlternativeRestTrans({ value: text, exercise: homeAlternative }))}
                            onChangeTempo={(text) => dispatch(updateHomeAlternativeTempo({ value: text, exercise: homeAlternative }))}
                        />
                    </div>
                ) : (
                    <WHMButton
                        text='Add Exercise Alternative'
                        className='home-alternative-button'
                        onSubmit={() => dispatch(openModal({
                            modalType: MODAL_TYPES.HOME_ALTERNATIVE,
                            itemId: exerciseId
                        }))}
                    />
                )}
            </div>
        </div>
    )
}

export default HomeAlternativeDetail