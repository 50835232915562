import { RFC } from '../../../types/propTypes'

type WHMEmptyDetailBoxProps = {
    title: string
}

const WHMEmptyDetailBox:RFC<WHMEmptyDetailBoxProps> = ({ title }) => {
    return (
        <div className='w-full h-full flex justify-center items-center text-lg text-WHMDarkGrey'>
            Select a {title} to see details
        </div>
    )
}

export default WHMEmptyDetailBox