import { ExerciseFocusTypes, UserTypes } from '../../../types/propTypes'

type CategoryTabOptions = ExerciseFocusTypes | UserTypes

type WHMCategoryTabProps<T extends CategoryTabOptions> = {
    selectedItem: T
    categories:   T[]
    onClick:      (category: T) => void
}

const WHMCategoryTab = <T extends CategoryTabOptions>({
    selectedItem,
    categories,
    onClick
}: WHMCategoryTabProps<T>) => {
    return (
        <div className='w-full overflow-x-auto overflow-y-hidden flex py-1 px-2'>
            {categories.map((category, index) => (
                <button
                    type='button'
                    key={index}
                    className={`text-WHMGold rounded-xl mx-1 px-3 py-2 hover:bg-WHMGold/25
                    ${selectedItem === category && 'bg-WHMGold/25'} cursor-pointer font-semibold
                    transition-all duration-200`}
                    onClick={() => onClick(category)}
                >
                    {category}
                </button>
            ))}
        </div>
    )
}

export default WHMCategoryTab