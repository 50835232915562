import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialUserFeedbacksState } from '../../../assets/data/InitialState'
import { UserFeedbacksData } from '../../../types/serviceTypes'

export type UserFeedbacksSliceType = {
    userFeedbacks: UserFeedbacksData
}

export const userFeedbacksSlice = createSlice({
    name: 'userFeedbacks',
    initialState: InitialUserFeedbacksState,
    reducers: {
        setInitialUserFeedbacks: (state, action:PayloadAction<UserFeedbacksSliceType>) => {
            state.userFeedbacks.data = action.payload.userFeedbacks.data
        },
        setUserFeedbacks: (state, action:PayloadAction<UserFeedbacksSliceType>) => {
            const { data: storedFeedbacks } = state.userFeedbacks
            const { data: newFeedbacks } = action.payload.userFeedbacks
            const feedbackIds = storedFeedbacks.map(feedback => feedback.userProgramId)
            const filteredDuplicates = newFeedbacks.filter(feedback => !feedbackIds.includes(feedback.userProgramId))
            filteredDuplicates.map(feedback => state.userFeedbacks.data.push(feedback))
        },
        clearFeedbacks: (state) => {
            state.userFeedbacks.data = []
        }
    }
})
export const userFeedbacksSelector = (state: RootState) => state.userFeedbacks
export const {
    setInitialUserFeedbacks,
    setUserFeedbacks,
    clearFeedbacks,
} = userFeedbacksSlice.actions
export default userFeedbacksSlice.reducer