const usersIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="account_circle_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 30 30"
        >
            <path id="Path_23" data-name="Path 23" d="M0,0H30V30H0Z" fill="none"/>
            <path id="Path_24" data-name="Path 24" d="M14,4A9.979,9.979,0,0,0,6.05,20.038c1.788-2.175,6.125-2.912,7.95-2.912s6.162.737,7.95,2.912A9.979,9.979,0,0,0,14,4Zm0,11.25a4.375,4.375,0,1,1,4.375-4.375A4.364,4.364,0,0,1,14,15.25Z" transform="translate(1 1)" fill="#d6a156" opacity="0.3"/>
            <path id="Path_25" data-name="Path 25" d="M14.5,2A12.5,12.5,0,1,0,27,14.5,12.5,12.5,0,0,0,14.5,2ZM8.338,22.35c.537-1.125,3.812-2.225,6.162-2.225s5.638,1.1,6.163,2.225a9.907,9.907,0,0,1-12.325,0ZM22.45,20.538c-1.788-2.175-6.125-2.913-7.95-2.913s-6.162.737-7.95,2.913a10,10,0,1,1,15.9,0ZM14.5,7a4.375,4.375,0,1,0,4.375,4.375A4.364,4.364,0,0,0,14.5,7Zm0,6.25a1.875,1.875,0,1,1,1.875-1.875A1.872,1.872,0,0,1,14.5,13.25Z" transform="translate(0.5 0.5)"/>
        </svg>
      </div>
    )
  }
  
  export default usersIcon