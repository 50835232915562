import { RFC } from '../../../types/propTypes'
import { motion } from 'framer-motion'

type WHMDetailTabsProps = {
    tabs:        string[]
    selectedTab: string
    isPhase?:    boolean
    onSelectTab: (tab: string) => void
}

const WHMDetailTabs:RFC<WHMDetailTabsProps> = ({
    tabs,
    selectedTab,
    isPhase,
    onSelectTab
}) => {
    return (
        <ul 
            className={`w-max flex mx-3 rounded-[10px] 
            ${isPhase ? 'bg-WHMGold/25' : 'bg-WHMLightPurple'}`}
        >
            {tabs.map((item, index) => (
            <li
                key={index}
                className={`relative px-16 py-[6px] text-xl cursor-pointer 
                list-none font-semibold italic transition-all duration-300
                ${isPhase ? 'text-WHMGold' : 'text-WHMDarkPurple'}`}
                onClick={() => onSelectTab(item)}
            >
                {item}
                {item === selectedTab ? (
                    <motion.div 
                        className={`absolute inset-0 rounded-[10px]
                        ${isPhase ? 'bg-WHMGold/25' : 'bg-WHMPurple/20'}`} 
                        layoutId='detail-tab-bg'
                    />
                ) : null}
            </li>
            ))} 
        </ul>
    )
}

export default WHMDetailTabs