import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialReportState } from '../../../assets/data/InitialState'
import { ReportData } from '../../../types/serviceTypes'

export type ReportSliceType = {
    id:     string
    report: ReportData
}

export const reportSlice = createSlice({
    name: 'report',
    initialState: InitialReportState,
    reducers: {
        selectReport: (state, action:PayloadAction<ReportSliceType>) => {
            state.id     = action.payload.id
            state.report = action.payload.report
        },
        clearReport: (state) => {
            state.id     = ''
            state.report = {
                postId: '',
                userId: '',
            }
        }
    }
})

export const reportSelector = (state: RootState) => state.report
export const {
    selectReport,
    clearReport,
} = reportSlice.actions
export default reportSlice.reducer