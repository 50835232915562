import { RFC } from '../../../types/propTypes'
import { motion } from 'framer-motion'
import { RightArror } from '../../../assets/icons/icons'

type MenuCardProps = {
    title:      string
    isSelected: boolean
    onClick:    () => void,
}

const MenuCard:RFC<MenuCardProps> = ({ 
    title, 
    isSelected,
    onClick 
}) => {

    return (
        <div
            className='w-full px-2 py-1 cursor-pointer'
            onClick={onClick}
        >
            <motion.div
                className={`flex justify-between items-center p-2 rounded-[5px] shadow-md
                hover:text-WHMWhite hover:bg-WHMDarkPurple/60 hover:fill-WHMWhite 
                ${isSelected 
                    ? 'text-WHMWhite bg-WHMDarkPurple/60 fill-WHMWhite' 
                    : 'text-WHMDarkPurple bg-WHMLightGrey fill-WHMDarkPurple'
                }`}
                initial={{ scale: 0.5, x: -200 }}
                animate={{ scale: 1, x: 0}}
                transition={{ 
                    type: 'spring', 
                    stiffness: 60,
                }}
            >
                <p className='font-semibold'>
                    {title}
                </p>
                <RightArror/>
            </motion.div>
        </div>
    )
}

export default MenuCard