import { useEffect, useState } from 'react'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearHelpTickets, setInitialUserHelpTickets, setUserHelpTickets, userHelpTicketsSelector } from '../../../features/Users/Users/userHelpTicketsSlice'
import { useLazyGetUserTicketsQuery } from '../../../services/UserService'
import { useReplyTicketMutation } from '../../../services/HelpTicketService'
import { userSelector } from '../../../features/Users/Users/userSlice'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { domAnimation, LazyMotion } from 'framer-motion'
import InfiniteScroll from 'react-infinite-scroller'
import { CardError, CardLoading, CardLogo, WHMDetailTitle } from '../../atoms/atoms'
import { SupportTicketField } from '../../molecules/molecules'
import asyncTimeout from '../../../utils/asyncTimeout'
import { openModal } from '../../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'

const TicketsTab = () => {
  const perPage = 10
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)

  const { id, user } = useWHMSelector(userSelector)
  const userHelpTicketsState = useWHMSelector(userHelpTicketsSelector)
  const dispatch = useWHMDispatch()
  const [ getUserHelpTickets, { isLoading, isError, isFetching }] = useLazyGetUserTicketsQuery()
  const [ replyTicket ] = useReplyTicketMutation()

  useEffect(() => {
    dispatch(clearHelpTickets())
    setCurrentPage(0)
    loadMoreItems(10, 0)
  },[id])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(perPage, currentPage)
  }

  const loadMoreItems = async (perPage: number, currentPage: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const helpTickets = await getUserHelpTickets({
        currentPage,
        perPage,
        userId: id
      }).unwrap()
  
      if (!helpTickets || !helpTickets.data.length) {
        setHasMore(false)
      } else {
        if (currentPage === 0) {
          dispatch(setInitialUserHelpTickets({ helpTickets: helpTickets }))
          setHasMore(true)
          setCurrentPage(1)
        } else if (helpTickets.pagination.lastPage === currentPage) {
          dispatch(setUserHelpTickets({ helpTickets: helpTickets }))
          setHasMore(false)
        } else {
          dispatch(setUserHelpTickets({ helpTickets: helpTickets }))
          setHasMore(true)
          setCurrentPage((prev) => prev + 1)
        }
      }
      setTimeout(() => {
        setIsLoadMore(false)
      },1000)
    } catch (error) {
      console.log({error})
    }
  }

  const submitReply = async (text: string) => {
    try {
      dispatch(openOverlay({ text: `Responding to ${user?.firstName} ${user?.lastName}'s question.`}))
      const [ data ] = await Promise.all([
        replyTicket({ userSupportId: id, reply: text }),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Message Sent',
        body: 'You have successfully sent a message.'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to send a message.',
        body: 'Please check your network and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const renderUserHelpTickets = () => {
    if (isLoading || isFetching) <CardLogo />
    if (isError)                 <CardError />
    if (!userHelpTicketsState.helpTickets.data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No Ticket
    </div>
    )

    return (
      <LazyMotion features={domAnimation} key='feedback_card'>
        <div className='grid grid-cols-2 gap-2'>
        {userHelpTicketsState.helpTickets.data.map((ticket, index) => (
          <SupportTicketField 
            sentOn={ticket.createdAt}
            toppic={ticket.topic}
            note={ticket.note}
            repliedOn={ticket.repliedAt}
            reply={ticket.reply}
            onSubmitReply={(text) => submitReply(text)}
            key={index}
          />
        ))}
        </div>
      </LazyMotion>
    )
  }

  return (
    <div className='w-full p-3'>
      <WHMDetailTitle 
        title='Tickets'
        details='View all of this clients tickets from the WHM help form.'
        margin='mb-1'
      />
      <div className='w-full h-screen overflow-y-auto overflow-x-hidden pb-[17em]'>
        <InfiniteScroll
          loadMore={fetchMoreData}
          hasMore={hasMore}
          useWindow={false}
          loader={<CardLoading key={0}/>}
          initialLoad={true}
          threshold={200}
        >
          { renderUserHelpTickets() }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default TicketsTab