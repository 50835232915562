const unmarkedStarIcon = ({
  width,
  height,
  color,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? '24'}
        height={height ?? '24'}
        viewBox="0 0 20.681 20.681"
      >
        <path
          id="Path_918"
          data-name="Path 918"
          d="M0,0H20.681V20.681H0Z"
          fill="none"
        />
        <path
          id="Path_919"
          data-name="Path 919"
          d="M15.7,10.318l-2.128-.181-1.034-.086-.405-.956L11.293,7.13l-.836,1.965-.405.956-1.034.086-2.128.181,1.62,1.4.784.681-.233,1.008L8.57,15.5l1.835-1.1.888-.543.888.543,1.835,1.1-.491-2.085L13.292,12.4l.784-.681Z"
          transform="translate(-0.953 -0.986)"
          fill="#d6a156"
          opacity="0.3"
        />
        <path
          id="Path_920"
          data-name="Path 920"
          d="M19.234,8.239l-6.2-.526L10.617,2,8.2,7.713,2,8.239l4.7,4.076L5.292,18.372l5.325-3.214,5.325,3.214-1.413-6.058ZM12.849,12.7l.483,2.077-1.827-1.1-.888-.534-.888.534L7.9,14.779,8.385,12.7l.233-1.017L7.834,11,6.214,9.6l2.128-.181,1.034-.086.4-.956.836-1.956.836,1.973.4.956,1.034.086,2.128.181-1.62,1.4-.784.681.233,1Z"
          transform="translate(-0.277 -0.277)"
          fill="#d6a156"
          opacity="0.3"
        />
      </svg>
    </div>
  )
}

export default unmarkedStarIcon