import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialProgramState } from '../../../assets/data/InitialState'
import { ProgramData } from '../../../types/serviceTypes'
import { ProgramPhaseWorkout, Workout } from '../../../types/stateTypes'
import { v4 as uuidv4 } from 'uuid'
import getPhaseTabIndex from '../../../utils/getPhaseTabIndex'

export type ProgramSliceType = {
    id:       string
    program:  ProgramData
    phaseTab: string
}

export const programSlice = createSlice({
    name: 'program',
    initialState: InitialProgramState,
    reducers: {
        selectProgramWithId: (state, action:PayloadAction<string>) => {
            state.id         = action.payload
            state.program.id = action.payload
        },
        selectProgram: (state, action:PayloadAction<ProgramSliceType>) => {
            state.id       = action.payload.id
            state.program  = action.payload.program
            state.phaseTab = 'Phase 1'
        },
        clearProgram: (state) => {
            state.id      = ''
            state.program = { 
                id: '',
                phase: [
                    { 
                        _count: { phaseWorkout: 0 },
                        id: '',
                        orderIndex: 1,
                        phaseWorkout: [],
                        programId: '',
                    },
                    { 
                        _count: { phaseWorkout: 0 },
                        id: '',
                        orderIndex: 2,
                        phaseWorkout: [],
                        programId: '',
                    },
                    { 
                        _count: { phaseWorkout: 0 },
                        id: '',
                        orderIndex: 3,
                        phaseWorkout: [],
                        programId: '',
                    },
                ],
                programSuggestGoal: [],
                programSuggestExperience: [],
                programSuggestTraining: []
            }
        },
        changePhaseTab: (state, action:PayloadAction<string>) => {
            state.phaseTab = action.payload
        },
        updateProgramActive: (state, action:PayloadAction<boolean>) => {
            state.program.isActive = action.payload
        },
        updateProgramTitle: (state, action:PayloadAction<string>) => {
            state.program.title = action.payload
        },
        updateProgramDescription: (state, action:PayloadAction<string>) => {
            state.program.description = action.payload
        },
        updateProgramImage: (state, action:PayloadAction<string>) => {
            state.program.image = action.payload
        },
        addProgramWorkouts: (state, action:PayloadAction<Workout[]>) => {
            const phaseIndex = getPhaseTabIndex(state.phaseTab)
            const workoutDetails = action.payload.map((workout, index) => {
                return {
                    orderIndex: index,
                    phaseId:    '',
                    workout:    workout,
                    workoutId:  workout.id
                }
            })
            if (!state.program.phase?.length) return
            if (state.program.phase[phaseIndex]._count.phaseWorkout <= 6) {
                state.program.phase[phaseIndex] = {
                    _count: {
                        phaseWorkout: state.program.phase[phaseIndex]._count.phaseWorkout + 1 ?? 1
                    },
                    id: state.program.phase[phaseIndex].id ?? uuidv4(),
                    orderIndex: state.program.phase[phaseIndex].orderIndex ?? 0,
                    phaseWorkout: [
                        ...state.program.phase[phaseIndex].phaseWorkout, 
                        ...workoutDetails
                    ],
                    programId: state.program.phase[phaseIndex].programId ?? ''
                }
            }
        },
        sortProgramWorkouts: (state, action:PayloadAction<ProgramPhaseWorkout[]>) => {
            console.log('trying to sort list here')
            // if (!state.program.phase?.length) return
            // state.program.phase[action.payload].phaseWorkout[0].workout = action.payload.workouts
        },
        removeProgramWorkout: (state, action:PayloadAction<Workout>) => {
            const phaseIndex = getPhaseTabIndex(state.phaseTab)
            if (!state.program.phase?.length) return
            const filteredPhase = state.program.phase[phaseIndex].phaseWorkout.filter(workout => 
                workout.workout.id !== action.payload.id    
            )
            state.program.phase[phaseIndex].phaseWorkout = filteredPhase
            state.program.phase[phaseIndex]._count.phaseWorkout = filteredPhase.length
        },
        updateProgramGoal: (state, action:PayloadAction<string>) => {
            const isSelected = state.program.programSuggestGoal?.find(goal => goal.suggestGoalName === action.payload)
            if (isSelected) {
                const filteredGoals = state.program.programSuggestGoal?.filter(goal => goal.suggestGoalName !== action.payload)
                state.program.programSuggestGoal = filteredGoals
            } else {
                if (!state.program.programSuggestGoal) {
                    state.program.programSuggestGoal = [{
                        programId:       state.program.id,
                        suggestGoalName: action.payload
                    }]
                } else {
                    state.program.programSuggestGoal = [
                        ...state.program.programSuggestGoal,
                        {
                            programId:       state.program.id,
                            suggestGoalName: action.payload
                        }
                    ]
                }
            }
        },
        updateProgramExperience: (state, action:PayloadAction<string>) => {
            const isSelected = state.program.programSuggestExperience?.find(experience => experience.suggestExperienceName === action.payload)
            if (isSelected) {
                const filteredExpriences = state.program.programSuggestExperience?.filter(experience => experience.suggestExperienceName !== action.payload)
                state.program.programSuggestExperience = filteredExpriences
            } else {
                if (!state.program.programSuggestExperience) {
                    state.program.programSuggestGoal = [{
                        programId:       state.program.id,
                        suggestGoalName: action.payload
                    }]
                } else {
                    state.program.programSuggestExperience = [
                        ...state.program.programSuggestExperience,
                        {
                            programId:             state.program.id,
                            suggestExperienceName: action.payload
                        }
                    ]
                }
            }
        },
        updateProgramTraining: (state, action:PayloadAction<string>) => {
            const isSelected = state.program.programSuggestTraining?.find(training => training.suggestTrainingName === action.payload)
            if (isSelected) {
                const filteredTrainings = state.program.programSuggestTraining?.filter(training => training.suggestTrainingName !== action.payload)
                state.program.programSuggestTraining = filteredTrainings
            } else {
                if (!state.program.programSuggestTraining) {
                    state.program.programSuggestTraining = [{
                        programId:           state.program.id,
                        suggestTrainingName: action.payload
                    }]
                } else {
                    state.program.programSuggestTraining = [
                        ...state.program.programSuggestTraining,
                        {
                            programId:           state.program.id,
                            suggestTrainingName: action.payload
                        }
                    ]
                }
            }
        },
    }
})

export const programSelector = (state: RootState) => state.program
export const {
    selectProgramWithId,
    selectProgram,
    clearProgram,
    changePhaseTab,
    updateProgramActive,
    updateProgramTitle,
    updateProgramDescription,
    updateProgramImage,
    addProgramWorkouts,
    sortProgramWorkouts,
    removeProgramWorkout,
    updateProgramGoal,
    updateProgramExperience,
    updateProgramTraining,
} = programSlice.actions
export default programSlice.reducer