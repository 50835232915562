const removeIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "24"} 
            height={height ?? "24"} 
            viewBox="0 0 24 24"
        >
        <path id="Path_54" data-name="Path 54" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_55" data-name="Path 55" d="M12,4a8,8,0,1,0,8,8A8.011,8.011,0,0,0,12,4Zm5,9H7V11H17Z" fill="rgba(214,161,86,0.6)"/>
        <path id="Path_56" data-name="Path 56" d="M7,11H17v2H7Zm5-9A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z" fill="#fff"/>
        </svg>
      </div>
    )
  }
  
  export default removeIcon