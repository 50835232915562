import { RFC } from '../../../types/propTypes'
import { AnimatePresence } from 'framer-motion'
import { ALL_TITLES } from '../../../assets/data/enums'
import { InfiniteScrollList } from '../../../components/organisms/organisms'

type UsersTitlesProps = {
    searchText:   string
    selectedType: string
}

const UsersTitles:RFC<UsersTitlesProps> = ({
    searchText,
    selectedType,
}) => {

  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
        <InfiniteScrollList 
          key='infinite_scroll'
          page={ALL_TITLES.ALL_USERS}
          searchText={searchText}
          category={selectedType}
          pb={true}
        />
      </AnimatePresence>
    </div>
  )
}

export default UsersTitles