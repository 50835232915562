import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialUserHelpTicketsState } from '../../../assets/data/InitialState'
import { UserTicketsData } from '../../../types/serviceTypes'

export type UserHelpTicketsSliceType = {
    helpTickets: UserTicketsData
}

export const userHelpTicketsSlice = createSlice({
    name: 'helpTickets',
    initialState: InitialUserHelpTicketsState,
    reducers: {
        setInitialUserHelpTickets: (state, action:PayloadAction<UserHelpTicketsSliceType>) => {
            state.helpTickets.data = action.payload.helpTickets.data
        },
        setUserHelpTickets: (state, action:PayloadAction<UserHelpTicketsSliceType>) => {
            const { data: storedTickets } = state.helpTickets
            const { data: newTickets } = action.payload.helpTickets
            const ticketIds = storedTickets.map(ticket => ticket.id)
            const filteredDuplicates = newTickets.filter(ticket => !ticketIds.includes(ticket.id))
            filteredDuplicates.map(ticket => state.helpTickets.data.push(ticket))
        },
        clearHelpTickets: (state) => {
            state.helpTickets.data = []
        }
    }
})

export const userHelpTicketsSelector = (state: RootState) => state.userHelpTickets
export const {
    setInitialUserHelpTickets,
    setUserHelpTickets,
    clearHelpTickets,
} = userHelpTicketsSlice.actions
export default userHelpTicketsSlice.reducer