import { useEffect, useMemo, useState } from 'react'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { debounce } from 'lodash'
import { setInitialWorkoutExercises, setWorkoutExercises, workoutExercisesSelector } from '../../../features/Training/Exercises/workoutExercisesSlice'
import { useLazyGetExercisesQuery } from '../../../services/ExerciseService'
import { CardError, CardLoading, CardLogo, ModalCard, WHMButton, WHMCategoryTab, WHMSearchbar } from '../../atoms/atoms'
import { Dialog } from '@headlessui/react'
import { closeModal } from '../../../features/Modal/modalSlice'
import { ArrowLeftIcon } from '../../../assets/icons/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { Exercise } from '../../../types/stateTypes'
import { addNewExercises } from '../../../features/Training/Workouts/exerciseDetailSlice'
import { exerciseFocusList } from '../../../assets/data/arrays'
import { ExerciseFocusTypes } from '../../../types/propTypes'

const AddExerciseModal = () => {
    const perPage = 10
    const [ searchText, setSearchText ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ focus, setFocus ] = useState<ExerciseFocusTypes>('All')
    const [ newExercises, setNewExercises ] = useState<Exercise[]>([])
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

    const workoutExercisesState = useWHMSelector(workoutExercisesSelector)
    const dispatch = useWHMDispatch()
    const [ getExercises, { isLoading, isError } ] = useLazyGetExercisesQuery()
    
    useEffect(() => {
        setCurrentPage(0)
        loadMoreItems(10, 0)
    },[searchText, focus])

    const fetchMoreData = () => {
        if (!isLoadMore) loadMoreItems(perPage, currentPage)
    }

    const loadMoreItems = async (perPage: number, currentPage: number) => {
        if (!isLoadMore) setIsLoadMore(true)
        try {
            const exercises = await getExercises({ 
                currentPage, 
                perPage, 
                filter: searchText, 
                filterByFocus: focus
            }).unwrap()
    
            if (!exercises || !exercises.data.length) {
                setHasMore(false)
            } else {
                if (currentPage === 0) {
                    dispatch(setInitialWorkoutExercises({ workoutExercises: exercises }))
                    setHasMore(true)
                    setCurrentPage(1)
                } else if (exercises.pagination.lastPage === currentPage) {
                    dispatch(setWorkoutExercises({ workoutExercises: exercises }))
                    setHasMore(false)
                } else {
                    dispatch(setWorkoutExercises({ workoutExercises: exercises }))
                    setHasMore(true)
                    setCurrentPage(prev => prev + 1)
                }
            }
            setTimeout(() => {
                setIsLoadMore(false)
            }, 1000)
        } catch (error) {
            console.log({error})
        }
    }

    const addNewWorkoutExercises = () => {
        dispatch(addNewExercises(newExercises))
        dispatch(closeModal())
    }

    const renderModalCards = () => {
        if (isLoading) return <CardLogo />
        if (isError)   return <CardError />
        if (!workoutExercisesState.workoutExercises.data.length) return (
            <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
                No Exercise
            </div>
        )

        return workoutExercisesState.workoutExercises.data.map((option, index) => {
            const checkedExercise = newExercises.find(exercise => exercise.id === option.id)
            return (
                <ModalCard 
                    key={index}
                    title={option.title}
                    checked={checkedExercise === undefined ? false : true}
                    addNewItem={() => setNewExercises(prev => [...prev, option])}
                    removeItem={() => {
                        const filteredExercises = newExercises.filter(exercise => exercise.id !== option.id)
                        setNewExercises(filteredExercises)
                    }}
                />
            )
        })
    }

    return (
        <div className='max-w-[500px]'>
            <Dialog.Title className='w-full flex items-center justify-between'>
                <div className='w-full flex items-center justify-center relative'>
                    <div
                        className='absolute left-0 cursor-pointer hover:scale-110 transition-all duration-200' 
                        onClick={() => dispatch(closeModal())}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <p className='w-full text-2xl font-bold text-center'>
                    Add Exercise
                    </p>
                </div>
            </Dialog.Title>
            <hr className='whm-divider'/>
            <Dialog.Description className='my-3'>
                <span className='text-lg m-3'>
                Select what exercise you would like to add to this workout.
                </span>
            </Dialog.Description>
            <WHMSearchbar onChange={onChangeText} style={'py-3'}/>
            <WHMCategoryTab 
                selectedItem={focus}
                categories={exerciseFocusList}
                onClick={(focus) => setFocus(focus)}
            />
            <div className='w-full h-[45vh] overflow-y-auto overflow-x-hidden'>
                <InfiniteScroll
                    loadMore={fetchMoreData}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<CardLoading key={0}/>}
                    initialLoad={true}
                    threshold={200}
                >
                    { renderModalCards() }
                </InfiniteScroll>
            </div>
            <hr className='whm-divider my-4'/>
            <WHMButton 
                 text='Add Exercises'
                 className='golden-button'
                 onSubmit={addNewWorkoutExercises}
            />
        </div>
    )
}

export default AddExerciseModal
