import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialExercisesState } from '../../../assets/data/InitialState'
import { ExercisesData } from '../../../types/serviceTypes'
import { Exercise } from '../../../types/stateTypes'

export type ExercisesSliceType = {
    exercises: ExercisesData
}

export const exercisesSlice = createSlice({
    name: 'exercises',
    initialState: InitialExercisesState,
    reducers: {
        clearExercises: (state) => {
            state.exercises.data = []
        },
        setInitialExercises: (state, action:PayloadAction<ExercisesSliceType>) => {
            state.exercises.data = action.payload.exercises.data
        },
        setExercises: (state, action:PayloadAction<ExercisesSliceType>) => {
            const { data: storedExercises } = state.exercises
            const { data: newExercises } = action.payload.exercises
            const exerciseIds = storedExercises.map(exercise => exercise.id)
            const filteredDuplicates = newExercises.filter(exercise => !exerciseIds.includes(exercise.id))
            filteredDuplicates.map(exercise => state.exercises.data.push(exercise))
        },
        addExercise: (state, action:PayloadAction<Exercise>) => {
            state.exercises.data.unshift(action.payload)
        },
        updateExerciseInfo: (state, action:PayloadAction<Exercise>) => {
            const filteredExercises = state.exercises.data.filter(exercise => exercise.id !== action.payload.id)
            state.exercises.data = [ action.payload, ...filteredExercises ]
        },
        deleteExerciseWithId: (state, action:PayloadAction<string>) => {
            const filteredExercises = state.exercises.data.filter(exercise => exercise.id !== action.payload)
            state.exercises.data = filteredExercises
        }
    }
})

export const exercisesSelector = (state: RootState) => state.exercises
export const {
    clearExercises,
    setInitialExercises,
    setExercises,
    addExercise,
    updateExerciseInfo,
    deleteExerciseWithId,
} = exercisesSlice.actions
export default exercisesSlice.reducer