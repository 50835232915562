import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialFeedbackState } from '../../../assets/data/InitialState'
import { ProgramFeedbackData } from '../../../types/serviceTypes'

export type FeedbackSliceType = {
    id: string
    feedback: ProgramFeedbackData
}


export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: InitialFeedbackState,
    reducers: {
        selectFeedback: (state, action:PayloadAction<FeedbackSliceType>) => {
            state.id       = action.payload.id
            state.feedback = action.payload.feedback
        },
        markAsSeen: (state, action:PayloadAction<number>) => {
            state.feedback.isActive = action.payload
        }
    }
})

export const feedbackSelector = (state: RootState) => state.feedback
export const {
    selectFeedback,
    markAsSeen,
} = feedbackSlice.actions
export default feedbackSlice.reducer