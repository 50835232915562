import { WHMApi } from '../app/api'
import { ProgramsData, PaginationPayload, ProgramData, ProgramStatisticData, ProgramStatisticArgs, ProgramCreateArgs, ProgramUpdateArgs } from '../types/serviceTypes'
import { PhaseWorkoutIds, PhaseWorkoutUpdateIds, Program, Programs } from '../types/stateTypes'

export const programApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getPrograms: builder.query<ProgramsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/programs',
                params: { currentPage, perPage, filter, orderUpdatedAt: 'desc' }
            }),
            providesTags: ['Program']
        }),
        getProgram: builder.query<ProgramData, string>({
            query: (programId) => `/programs/${programId}`,
            providesTags: ['Program']
        }),
        getProgramStatistic: builder.query<ProgramStatisticData, ProgramStatisticArgs>({
            query: ({ programId }) => `/admin/programs/${programId}/statics`,
            providesTags: ['Program']
        }),
        createProgram: builder.mutation<Programs, ProgramCreateArgs>({
            query: ({ 
                title, description, image, phaseList,
                goalSuggest, experienceSuggest, trainingSuggest
            }) => {
                let workoutLists:PhaseWorkoutIds[] = []
                phaseList.forEach(phase => {
                    const workoutIds = phase.phaseWorkout.map(workout => workout.workoutId)
                    workoutLists = [
                        ...workoutLists,
                        {
                            workoutIdList: workoutIds
                        }
                    ]
                })
                const programSuggestGoals = goalSuggest?.map(goal => goal.suggestGoalName)
                const programSuggestExperiences = experienceSuggest?.map(experience => experience.suggestExperienceName)
                const programSuggestTrainings = trainingSuggest?.map(training => training.suggestTrainingName)
                return ({
                    url: '/admin/programs',
                    method: 'POST',
                    body: {
                        title,
                        description,
                        image,
                        phaseList: workoutLists,
                        goalSuggest: programSuggestGoals,
                        experienceSuggest: programSuggestExperiences,
                        trainingSuggest: programSuggestTrainings,
                    }
                })
            }
        }),
        updateProgram: builder.mutation<Programs, ProgramUpdateArgs>({
            query: ({
                id, title, description, image, isActive, phaseList, 
                goalSuggest, experienceSuggest, trainingSuggest
            }) => {
                let workoutLists: PhaseWorkoutUpdateIds[] = []
                phaseList?.forEach(phase => {
                    const workoutIds = phase.phaseWorkout.map(workout => workout.workoutId)
                    workoutLists = [
                        ...workoutLists,
                        {
                            id: phase.id,
                            workoutIdList: workoutIds
                        }
                    ]
                })
                const programSuggestGoals = goalSuggest?.map(goal => goal.suggestGoalName)
                const programSuggestExperiences = experienceSuggest?.map(experience => experience.suggestExperienceName)
                const programSuggestTrainings = trainingSuggest?.map(training => training.suggestTrainingName)
                return ({
                    url: `/admin/programs/${id}`,
                    method: 'PATCH',
                    body: {
                        title,
                        description,
                        image,
                        isActive,
                        phaseList: workoutLists,
                        goalSuggest: programSuggestGoals,
                        experienceSuggest: programSuggestExperiences,
                        trainingSuggest: programSuggestTrainings,
                    }
                })
            }
        }),
        deleteProgram: builder.mutation<Program, string>({
            query: (programId) => ({
                url: `/admin/programs/${programId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Program']
        })
    })
})

export const {
    useLazyGetProgramsQuery,
    useLazyGetProgramQuery,
    useGetProgramStatisticQuery,
    useCreateProgramMutation,
    useUpdateProgramMutation,
    useDeleteProgramMutation,
} = programApi