import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearCourse, courseSelector } from './courseSlice'
import { AnimatePresence } from 'framer-motion'
import { NewCard, WHMStatus } from '../../../components/atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'

type CoursesTitlesProps = {
  isNewCourse: boolean
  searchText:  string
  onRemoveNew: () => void
}

const CoursesTitles:RFC<CoursesTitlesProps> = ({ 
  isNewCourse, 
  searchText, 
  onRemoveNew 
}) => {
  const courseState = useWHMSelector(courseSelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    dispatch(clearCourse())
  },[isNewCourse])

  const renderAddNewCard = () => {
    return (
      <NewCard>
        <div className='flex items-center justify-between'>
          <div className='max-w-[90%]'>
            <p className='truncate text-2xl font-bold'>{courseState.title ?? 'New Course'}</p>
            <div className='flex items-center'>
              <WHMStatus status={false}/>
              <p className='truncate '>{courseState.focus ?? 'NA'}</p>
            </div>
          </div>
          <ArrowRightIcon 
            width={18}
            height={18}
          />
        </div>
      </NewCard>
    )
  }
  
  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
      { isNewCourse && renderAddNewCard() }
      <InfiniteScrollList 
        key='infinite_scroll'
        page={ALL_TITLES.COURSES}
        searchText={searchText}
        onRemoveNew={onRemoveNew}
      />
      </AnimatePresence>
    </div>
  )
}

export default CoursesTitles