import { PurpleLogo } from '../../../assets/images/images'

const CardLogo = () => {
  return (
    <div className='w-full p-14'>
        <img src={PurpleLogo} alt='card-logo'/>
    </div>
  )
}

export default CardLogo