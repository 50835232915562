import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { WHMFilterDropdown, WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import { workoutFocusList } from '../../../assets/data/arrays'
import WorkoutsTitles from '../../../features/Training/Workouts/WorkoutsTitles'

type WorkoutsListProps = {
  isNewWorkout: boolean
  setIsNewWorkout: (isOpen: boolean) => void
}

const WorkoutsList:RFC<WorkoutsListProps> = ({
  isNewWorkout,
  setIsNewWorkout
}) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const [ filter, setFilter ] = useState('')

  return (
    <>
      <WHMTitleBlock 
        title='Workouts'
        btnTitle='New'
        onClick={() => setIsNewWorkout(!isNewWorkout)}
      />
      <WHMSearchbar onChange={onChangeText}/>
      <WHMFilterDropdown 
        value={filter === '' ? 'Select' : filter}
        list={workoutFocusList}
        onClick={(option) => setFilter(option)}
      />
      <WorkoutsTitles 
        isNewWorkout={isNewWorkout}
        searchText={searchText}
        selectedFilter={filter}
        onRemoveNew={() => setIsNewWorkout(false)}
      />
    </>
  )
}

export default WorkoutsList