import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearLesson, lessonSelector } from './lessonSlice'
import { NewCard } from '../../../components/atoms/atoms'
import { AnimatePresence } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'

type LessonsTitlesProps = {
  isNewLesson: boolean
  searchText:  string
  onRemoveNew: () => void
}

const LessonsTitles:RFC<LessonsTitlesProps> = ({
  isNewLesson,
  searchText,
  onRemoveNew
}) => {
  const lessonState = useWHMSelector(lessonSelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    dispatch(clearLesson())
  },[isNewLesson])

  const renderAddNewCard = () => {
    return (
      <NewCard>
        <div className='flex items-center justify-between'>
          <div className='max-w-[90%]'>
            <div className='flex'>
              <p>Assigned</p>
              <p className='mx-1'>●</p>
              <p>NA</p>
            </div>
            <p className='truncate text-2xl font-bold'>
              {lessonState.title ?? 'New Lesson'}
            </p>
          </div>
          <ArrowRightIcon 
            width={18}
            height={18}
          />
        </div>
      </NewCard>
    )
  }

  return (
    <div className="w-full mt-4">
      <AnimatePresence>
        { isNewLesson && renderAddNewCard() }
        <InfiniteScrollList
          key='infinite_scroll'
          page={ALL_TITLES.LESSONS}
          searchText={searchText}
          onRemoveNew={onRemoveNew}
        />
      </AnimatePresence>
    </div>
  )
}

export default LessonsTitles