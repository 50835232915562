import { WHMApi } from '../app/api'
import { LessonCreateArgs, LessonData, LessonsData, LessonUpdateArgs, PaginationPayload } from '../types/serviceTypes'
import { Lesson } from '../types/stateTypes'

export const lessonApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getLessons: builder.query<LessonsData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/lessons',
                params: { currentPage, perPage, filter, orderUpdatedAt: 'desc' }
            }),
            providesTags: ['Lesson']
        }),
        getLesson: builder.query<LessonData, string>({
            query: (lessonId) => `/lessons/${lessonId}`,
            providesTags: ['Lesson']
        }),
        createLesson: builder.mutation<Lesson, LessonCreateArgs>({
            query: ({ title, video, image, overview }) => ({
                url: '/admin/lessons',
                method: 'POST',
                body: {
                    title,
                    video,
                    image,
                    overview
                }
            }),
            invalidatesTags: ['Lesson']
        }),
        updateLesson: builder.mutation<Lesson, LessonUpdateArgs>({
            query: ({ id, title, video, image, overview }) => ({
                url: `/admin/lessons/${id}`,
                method: 'PATCH',
                body: {
                    title,
                    video,
                    image,
                    overview,
                }
            }),
            invalidatesTags: ['Lesson']
        }),
        deleteLesson: builder.mutation<Lesson, string>({
            query: (lessonId) => ({
                url: `/admin/lessons/${lessonId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Lesson']
        })
    })
})

export const {
    useLazyGetLessonsQuery,
    useLazyGetLessonQuery,
    useCreateLessonMutation,
    useUpdateLessonMutation,
    useDeleteLessonMutation,
} = lessonApi