import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../app/store'
import { InitialHelpTicketsState } from '../../../assets/data/InitialState'
import { HelpTicketsData } from '../../../types/serviceTypes'

export type HelpTicketsSliceType = {
    helpTickets: HelpTicketsData
}

export const helpTicketsSlice = createSlice({
    name: 'helpTickets',
    initialState: InitialHelpTicketsState,
    reducers: {
        clearHelpTickets: (state) => {
            state.helpTickets.data = []
        },
        setInitialHelpTickets: (state, action:PayloadAction<HelpTicketsSliceType>) => {
            const createdOrders = action.payload.helpTickets.data.slice().sort((a, b) =>
                parseInt(moment(b.createdAt).format('X')) - parseInt(moment(a.createdAt).format('X'))
            )
            state.helpTickets.data = createdOrders
        },
        setHelpTickets: (state, action:PayloadAction<HelpTicketsSliceType>) => {
            const { data: storedHelpTickets } = state.helpTickets
            const { data: newHelpTickets } = action.payload.helpTickets
            const helpTicketIds = storedHelpTickets.map(ticket => ticket.id)
            const filteredDuplicates = newHelpTickets.filter(ticket => !helpTicketIds .includes(ticket.id))
            const createdOrders = filteredDuplicates.slice().sort((a, b) =>
                parseInt(moment(b.createdAt).format('X')) - parseInt(moment(a.createdAt).format('X'))
            )
            createdOrders.map(ticket => state.helpTickets.data.push(ticket))
        },
        deleteTicketWithId: (state, action:PayloadAction<string>) => {
            const filteredTickets = state.helpTickets.data.filter(ticket => ticket.id !== action.payload)
            state.helpTickets.data = filteredTickets
        }
    }
})

export const helpTicketsSelector = (state: RootState) => state.helpTickets
export const {
    clearHelpTickets,
    setInitialHelpTickets,
    setHelpTickets,
    deleteTicketWithId,
} = helpTicketsSlice.actions
export default helpTicketsSlice.reducer