import { RFC } from '../../../types/propTypes'
import { CheckedIcon } from '../../../assets/icons/icons'

type ModalCardProps = {
    title:       string
    checked:     boolean
    isNotAssigned?: boolean
    addNewItem:  () => void
    removeItem:  () => void
}

const ModalCard:RFC<ModalCardProps> = ({ 
    title, 
    checked,
    isNotAssigned,
    addNewItem, 
    removeItem 
}) => {
    return (
        <div 
            className={`flex items-center justify-between 
            mx-2 p-2 rounded-[5px] cursor-pointer my-2 
            hover:text-WHMWhite hover:bg-WHMDarkPurple/60
            text-lg font-semibold transition-all duration-200
            ${checked ? 'bg-WHMDarkPurple/60' : 'bg-WHMLightGrey'}
            ${checked ? 'text-WHMWhite' : 'text-WHMDarkPurple'}`}
            onClick={checked ? removeItem : addNewItem}
        >
            <div className='w-full flex truncate'>
                <p className='truncate'>
                    {title}
                </p>
                {isNotAssigned &&
                <p className='pl-5 text-WHMGreen text-xl'>●</p>
                }
            </div>

            {checked ? (
                <CheckedIcon width={'24'} height={'24'}/>
            ) : (
                <div 
                    className='w-6 h-6 rounded-[50%] border-WHMDarkPurple border-[2px] border-solid'
                ></div>
            )}
        </div>
    )
}

export default ModalCard