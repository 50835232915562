import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { WHMCategoryTab, WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import { userTypeList } from '../../../assets/data/arrays'
import UsersTitles from '../../../features/Users/Users/UsersTitles'
import { UserTypes } from '../../../types/propTypes'

const AllUsersList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const [ userType, setUserType ] = useState<UserTypes>('All')

  return (
    <>
      <WHMTitleBlock title='All Users' />
      <WHMSearchbar onChange={onChangeText}/>
      <WHMCategoryTab 
        selectedItem={userType}
        categories={userTypeList}
        onClick={(type) => setUserType(type)}
      />
      <UsersTitles 
        searchText={searchText}
        selectedType={userType}
      />
    </>
  )
}

export default AllUsersList