import { useState } from 'react'
import { useWHMSelector } from '../../app/hooks'
import { exerciseSelector } from '../../features/Training/Exercises/exerciseSlice'
import { TRAINING_BOARD_TITLES } from '../../assets/data/enums'
import { trainingBoardTitles } from '../../assets/data/arrays'
import { ExerciseDetails, ExercisesList, ProgramDetails, ProgramsList, WorkoutDetails, WorkoutsList } from '../../components/organisms/organisms'
import { DashboardLayout, DashboardTitles } from '../../components/templates/templates'
import { WHMEmptyDetailBox } from '../../components/atoms/atoms'
import { workoutSelector } from '../../features/Training/Workouts/workoutSlice'
import { programSelector } from '../../features/Training/Programs/programSlice'

const Training = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(TRAINING_BOARD_TITLES.PROGRAM)
  const programState = useWHMSelector(programSelector)
  const workoutState = useWHMSelector(workoutSelector)
  const exerciseState = useWHMSelector(exerciseSelector)
  const [ isNewProgram, setIsNewProgram ] = useState(false)
  const [ isNewWorkout, setIsNewWorkout ] = useState(false)
  const [ isNewExercise, setIsNewExercise ] = useState(false)

  const renderList = () => {
    switch (currentPage) {
      case TRAINING_BOARD_TITLES.PROGRAM:
        return <ProgramsList 
          isNewProgram={isNewProgram}
          setIsNewProgram={setIsNewProgram}
        />
      case TRAINING_BOARD_TITLES.WORKOUTS:
        return <WorkoutsList 
          isNewWorkout={isNewWorkout}
          setIsNewWorkout={setIsNewWorkout}
        />
      case TRAINING_BOARD_TITLES.EXERCISES:
        return <ExercisesList 
          isNewExercise={isNewExercise}
          setIsNewExercise={setIsNewExercise}
        />
      default:
        return
    }
  }

  const renderDetails = () => {
    switch (currentPage) {
      case TRAINING_BOARD_TITLES.PROGRAM:
        if (isNewProgram)
          return <ProgramDetails 
            isNewProgram={isNewProgram}
            setIsNewProgram={setIsNewProgram}
          />
        if (!programState.id)
          return <WHMEmptyDetailBox title='program'/>
        else
          return <ProgramDetails />
      case TRAINING_BOARD_TITLES.WORKOUTS:
        if (isNewWorkout)
          return <WorkoutDetails 
            isNewWorkout={isNewWorkout}
            setIsNewWorkout={setIsNewWorkout}
          />
        if (!workoutState.id)
          return <WHMEmptyDetailBox title='workout'/>
        else 
          return <WorkoutDetails />
      case TRAINING_BOARD_TITLES.EXERCISES:
        if (isNewExercise)
          return <ExerciseDetails 
            isNewExercise={isNewExercise}
            setIsNewExercise={setIsNewExercise}
          />
        if (!exerciseState.id)
          return <WHMEmptyDetailBox title='exercise' />
        else 
          return <ExerciseDetails />
      default:
        return
    }
  }

  return (
    <DashboardLayout page='training'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Training'
            menu={trainingBoardTitles}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
        <div className='dashboard-title-box'>
          { renderList() }
        </div>
        <div className='dashboard-detail-box'>
          { renderDetails() }
        </div>
      </>
    </DashboardLayout>
  )
}

export default Training