import { RFC } from '../../../types/propTypes'
import { AnimatePresence, motion } from 'framer-motion'
import { WHMDetailTitle, WHMSuggestList } from '../../atoms/atoms'
import { DashboardDetails } from '../../templates/templates'
import { programExperiences, programGoals, programTrainings } from '../../../assets/data/arrays'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { programSelector, updateProgramExperience, updateProgramGoal, updateProgramTraining } from '../../../features/Training/Programs/programSlice'
import { SuggestSkeleton } from '../../molecules/molecules'

type SuggestTabProps = {
  isNewProgram: boolean | undefined
  isLoading:    boolean
}

const SuggestTab:RFC<SuggestTabProps> = ({
  isNewProgram,
  isLoading
}) => {
  const { programSuggestGoal, programSuggestExperience, programSuggestTraining } = useWHMSelector(programSelector).program
  const dispatch = useWHMDispatch()

  return (
    <AnimatePresence>
      <motion.div
        key='program_suggest'
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className='px-3 py-2 max-w-[40em]'>
          <WHMDetailTitle 
            title='Program Suggestion Calibration'
            details='Set what parameters must be met in order for this program to be suggested to a user after completing their program quiz.'
            margin='mb-1'
          />
        </div>
        <DashboardDetails template='grid-cols-3sm'>
        {isLoading ? (<SuggestSkeleton />) : (
          <>
            <WHMSuggestList 
              title='Goal'
              list={programGoals}
              isRequired={isNewProgram ? true : false}
              selectedList={programSuggestGoal}
              updateItem={(text) => dispatch(updateProgramGoal(text))}
            />
            <WHMSuggestList 
              title='Experience'
              list={programExperiences}
              isRequired={isNewProgram ? true : false}
              selectedList={programSuggestExperience}
              updateItem={(text) => dispatch(updateProgramExperience(text))}
            />
            <WHMSuggestList 
              title='Training'
              list={programTrainings}
              isRequired={isNewProgram ? true : false}
              selectedList={programSuggestTraining}
              updateItem={(text) => dispatch(updateProgramTraining(text))}
            />
          </>
        )}
        </DashboardDetails>
      </motion.div>
    </AnimatePresence>
  )
}

export default SuggestTab