import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialAuthState } from '../../assets/data/InitialState'
import { AuthData } from '../../types/serviceTypes'

const authSlice = createSlice({
    name: 'auth',
    initialState: InitialAuthState,
    reducers: {
        setCredentials: (state, action:PayloadAction<AuthData>) => {
            const { accessToken, user } = action.payload
            state.accessToken = accessToken
            state.user        = user
        },
        logout: (state) => {
            state.accessToken = {
                token:     null,
                expiresIn: null
            }
            state.user        = null
        } 
    }
})

export const authSelector = (state:RootState) => state.auth
export const { 
    setCredentials,
    logout
} = authSlice.actions
export default authSlice.reducer