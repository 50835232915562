import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useWHMSelector } from '../app/hooks'
import { authSelector } from '../features/Auth/authSlice'

const useAuth = () => {
    // const authState = useWHMSelector(authSelector)
    // const token = authState.accessToken?.token
    const token = localStorage.getItem('WHMAdminToken')
    if (token) return true
    return false
}

export const AuthRequiredRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to='/login' />
}

export const NotAuthRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Navigate to='/notice-board' /> : <Outlet/>
}