import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { ALL_TITLES } from '../../../assets/data/enums'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { NewCard } from '../../../components/atoms/atoms'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { RFC } from '../../../types/propTypes'
import { clearExerciseList } from './exerciseDetailSlice'
import { clearWorkout, workoutSelector } from './workoutSlice'

type WorkoutsTitlesProps = {
  isNewWorkout:   boolean
  searchText:     string
  selectedFilter: string
  onRemoveNew:    () => void
}

const WorkoutsTitles:RFC<WorkoutsTitlesProps> = ({
  isNewWorkout,
  searchText,
  selectedFilter,
  onRemoveNew
}) => {
  const workoutState = useWHMSelector(workoutSelector)
  const dispatch = useWHMDispatch()

  useEffect(() => {
    dispatch(clearWorkout())
    dispatch(clearExerciseList())
  },[isNewWorkout])

  const renderAddNewCard = () => {
    return (
      <NewCard>
        <div className='flex items-center justify-between'>
          <div className='max-w-[90%]'>
            <div className='flex'>
              <p>Program</p>
              <p className='mx-1'>●</p>
              <p>none</p>
            </div>
            <p className='truncate text-2xl font-bold'>
              {workoutState.workout.title ?? 'New Workout'}
            </p>
            <div className='flex'>
              <p>
                {workoutState.workout.focus ?? 'Focus'}
              </p>
              <p className='mx-1'>●</p>
              <p>
                {workoutState.workout.exerciseCount ?? 0}&nbsp;
                {workoutState.workout.exerciseCount === undefined ||
                 workoutState.workout.exerciseCount > 1
                 ? 'Exercises' : 'Exercise'} 
              </p>
            </div>
          </div>
          <ArrowRightIcon 
            width={18}
            height={18}
          />
        </div>
      </NewCard>
    )
  }

  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
        { isNewWorkout && renderAddNewCard() }
        <InfiniteScrollList 
          key='infinite_scroll'
          page={ALL_TITLES.WORKOUTS}
          searchText={searchText}
          category={selectedFilter}
          pb={true}
          onRemoveNew={onRemoveNew}
        />
      </AnimatePresence>
    </div>
  )
}

export default WorkoutsTitles