const arrowLeftIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '30'}
            height={height ?? '30'}
            viewBox="0 0 30 30"
        >
            <path id="Path_41" data-name="Path 41" d="M0,0H30V30H0Z" fill="none"/>
            <path id="Path_42" data-name="Path 42" d="M26,13.625H9.266l7.686-7.686L15,4,4,15,15,26l1.939-1.939L9.266,16.375H26Z" fill={color ?? '#5e257d'}/>
        </svg>
      </div>
    )
  }
  
  export default arrowLeftIcon