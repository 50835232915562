import { RFC } from '../../../types/propTypes'

type WHMDetailInputProps = {
    placeholder: string
    value:       string
    onChange:    (text: string) => void
}

const WHMDetailInput:RFC<WHMDetailInputProps> = ({
    placeholder,
    value,
    onChange,
}) => {
  return (
    <div className='w-full'>
        <input 
            type='text'
            placeholder={placeholder}
            value={value}
            className='w-full text-WHMDarkPurple rounded-lg 
            bg-WHMDarkWhite px-3 py-[6px] outline-none border-WHMBorder
            border-[1px] border-solid shadow-sm'
            onChange={(e) => onChange(e.target.value)}
        />
    </div>
  )
}

export default WHMDetailInput