import { RFC } from '../../../types/propTypes'
import { AnimatePresence } from 'framer-motion'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'
import { CloseIcon } from '../../../assets/icons/icons'
import { useEffect } from 'react'
import { useWHMDispatch } from '../../../app/hooks'
import { clearPosts } from './groupSlice'

type PostsTitlesProps = {
    groupName: string
}

const PostsTitles:RFC<PostsTitlesProps> = ({ groupName }) => {
    const dispatch = useWHMDispatch()

    useEffect(() => {
        dispatch(clearPosts())
    },[groupName])

    if (!groupName) return (
        <div className='w-full px-3'>
            <div 
                className='w-[90%] py-3 px-2 border-solid border-[1px] border-WHMBorder
                rounded-xl flex flex-col items-center justify-center text-center'
            >
                <CloseIcon />
                <p className='text-WHMDarkPurple text-2xl font-semibold'>
                   No Posts Here Yet! 
                </p>
                <p className='my-2'>
                    No one has made a post in this group yet.<br/> 
                    When a user makes a post they will appear here.
                </p>
            </div>
        </div>
    )
    
    return (
        <div className='w-full px-3'>
            <div className='font-semibold text-lg'>
                Group Posts
            </div>
            <AnimatePresence>
                <InfiniteScrollList 
                    key='infinite-scroll'
                    page={ALL_TITLES.GROUP_POSTS}
                    searchText={groupName}
                />
            </AnimatePresence>
        </div>
    )
}

export default PostsTitles