import WHMLoading from "../WHMLoading"

const CardLoading = () => {
    return (
        <div
            className='felx justify-center items-center text-center mx-auto w-full p-5 mt-8'
        >
            <WHMLoading shape='bars'/>
            <p className='mt-4 text-WHMDarkPurple font-bold'>
            ... Loading
            </p>
        </div>
    )
}

export default CardLoading