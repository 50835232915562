import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { WHMApi } from '../app/api'
import { CourseStatistic, ProgramStatisctics, UserStatistics } from '../types/serviceTypes'

type StatisticsData = {
    userStats:    UserStatistics
    programStats: ProgramStatisctics
    courseStats:  CourseStatistic
}

export const statisticService = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getGlobalWHMStatistics: builder.query<StatisticsData, void>({
            async queryFn(arg, api, extraOptions, baseQuery) {
                const userStatsData     = await baseQuery('/admin/users/statistics/global')
                const programStatsData  = await baseQuery('/admin/programs/statistics/global')
                const courseStatsData   = await baseQuery('/admin/courses/statistics/global')

                if (userStatsData.error)    return { error: userStatsData.error as FetchBaseQueryError }
                if (programStatsData.error) return { error: programStatsData.error as FetchBaseQueryError }
                if (courseStatsData.error)  return { error: courseStatsData.error as FetchBaseQueryError }

                const result: StatisticsData = {
                    userStats:    userStatsData.data as UserStatistics,
                    programStats: programStatsData.data as ProgramStatisctics,
                    courseStats:  courseStatsData.data as CourseStatistic
                }

                return  result 
                ? { data: result }
                : { error: { status: 500, statusText: 'Internal Server Error', data: 'no data!' } }
            },
        })
    })
})

export const {
    useGetGlobalWHMStatisticsQuery
} = statisticService