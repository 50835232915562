import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { openModal } from '../../../features/Modal/modalSlice'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { motion, AnimatePresence } from 'framer-motion'
import { WHMButton, WHMDetailTitle } from '../../atoms/atoms'
import { WorkoutExerciseTitles, WorkoutExerciseDetail, DnDListSkeleton } from '../../molecules/molecules'
import { DashboardDetails } from '../../templates/templates'
import { exerciseDetailSelector } from '../../../features/Training/Workouts/exerciseDetailSlice'

type ExercisesTabProps = {
  isLoading:    boolean
}

const ExercisesTab:RFC<ExercisesTabProps> = ({ isLoading }) => {
  const { exerciseGroups, exerciseGroup } = useWHMSelector(exerciseDetailSelector)
  const dispatch = useWHMDispatch()

  const renderWorkoutExercises = () => {
    if (isLoading) return <DnDListSkeleton />
    return (
      <div className="w-full h-screen overflow-y-auto overflow-x-hidden px-2 pb-[17em]">
        {exerciseGroups && (
          exerciseGroups.map((group, index) => (
            <WorkoutExerciseTitles
              key={index}
              orderIndex={group.orderIndex}
              exercises={group}
              isSelected={exerciseGroup.id === group.id}
            />
          ))
        )}
        <div className='w-full flex justify-center items-center'>
          <WHMButton 
            text='Add Exercise'
            className='workout-exercise-button'
            onSubmit={() => dispatch(openModal({
              modalType: MODAL_TYPES.ADD_EXERCISES,
            }))}
          />
        </div>
      </div>
    )
  }

  const renderWorkoutExerciseDetails = () => {
    if (exerciseGroup.exerciseGroupInformation.length) return (
      <motion.div 
        key='exercise_detail'
        className='w-full px-2'
        initial={{ x: -150, opacity: 0, scale: 0 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        exit={{ x: 150, opacity: 0 }}
        transition={{ stiffness: 80 }}
      >
        <WorkoutExerciseDetail exerciseGroup={exerciseGroup}/>
      </motion.div>
    )
  }

  return (
    <AnimatePresence>
      <motion.div
        key='exercises'
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className='px-3 py-2 max-w-[40em]'>
          <WHMDetailTitle 
            title='Create Workout'
            details='Add exercises to a workout to build out the routine. Create the default and home workout inside the default tab, and create a Deloading version in the Deloading tab.'
            margin='mb-1'
          />
        </div>
        <DashboardDetails template='grid-cols-2lg'>
          <>
            { renderWorkoutExercises() }
            { renderWorkoutExerciseDetails() }
          </>
        </DashboardDetails>
      </motion.div>
    </AnimatePresence>
  )
}

export default ExercisesTab