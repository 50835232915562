import { RFC } from '../../../types/propTypes'
import { ExerciseGroup, ExerciseGroupInformation } from '../../../types/stateTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { alphabet } from '../../../assets/data/arrays'
import { DraggableBinIcon } from '../../../assets/icons/icons'
import { exerciseDetailSelector, removeExerciseWithId, removeNewExercise, selectExerciseGroup } from '../../../features/Training/Workouts/exerciseDetailSlice'

type WorkoutExerciseTitlesProps = {
    orderIndex: number
    exercises:  ExerciseGroup
    isSelected: boolean
}

const WorkoutExerciseTitles:RFC<WorkoutExerciseTitlesProps> = ({ 
    orderIndex,
    exercises,
    isSelected
}) => {
    const { exerciseGroup } = useWHMSelector(exerciseDetailSelector)
    const dispatch = useWHMDispatch()

    const deleteWorkoutExercise = (exercise: ExerciseGroupInformation) => {
        dispatch(removeNewExercise(exercise.id))
        const isSelected = exerciseGroup.exerciseGroupInformation.find(exercise => exercise.id === exercise.id)
        if (!isSelected) return
        dispatch(removeExerciseWithId(exercise.id))
    }

    return (
        <div 
            className={`w-full rounded-[10px] mb-3 cursor-pointer
            ${exercises.exerciseGroupInformation.length !== 0 && !isSelected &&
            'border-WHMBorder border-solid border-[1px]'}
             ${isSelected && 'bg-[#EEE8F2]'}`}
        >
            {exercises.exerciseGroupInformation.map((exercise, index) => (
            <div key={index}>
                <div 
                    className={`flex items-center justify-between px-3 py-4 rounded-[10px]
                    ${isSelected ? 'hover:bg-[#EEE8F2]' : 'hover:bg-WHMLi'}`}
                >
                    <div 
                        key={index}
                        className='w-full h-full flex items-center'
                        onClick={() => dispatch(selectExerciseGroup(exercises))}
                    >
                        <p className='w-10 flex justify-center items-center text-2xl font-bold text-WHMDarkPurple '>
                            {alphabet[orderIndex]}
                            {exercises.exerciseGroupInformation.length > 1 ? exercise.orderIndex + 1 : ''}
                        </p>
                        <div>
                            <p className='pl-4 text-2xl text-WHMDarkPurple font-semibold'>
                                {exercise.exercise.title}
                            </p>
                                <div className='pl-4 flex items-center'>
                                <p className='text-WHMDark/80 mr-2'>
                                    Home Alternative:
                                </p> 
                                <p className='text-WHMPurple font-semibold'>
                                {exercise.exerciseHomeAlternative?.exercise?.title ?? 'none'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <DraggableBinIcon 
                        onClick={() => deleteWorkoutExercise(exercise)}
                    />
                </div>
                {((exercises.exerciseGroupInformation.length !== 1) &&
                 (exercises.exerciseGroupInformation.length - 1 !== index)) &&
                 <hr className='w-4/5 h-[2px] bg-WHMLightPurple mx-auto'/>
                }
            </div>
            ))}
        </div>
    )
}

export default WorkoutExerciseTitles
