import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialLessonsState } from '../../../assets/data/InitialState'
import { LessonsData } from '../../../types/serviceTypes'
import { Lesson } from '../../../types/stateTypes'

export type LessonsSliceType = {
    lessons: LessonsData
}

export const lessonsSlice = createSlice({
    name: 'lessons',
    initialState: InitialLessonsState,
    reducers: {
        clearLessons: (state) => {
            state.lessons.data = []
        },
        setInitialLessons: (state, action:PayloadAction<LessonsSliceType>) => {
            state.lessons.data = action.payload.lessons.data
        },
        setLessons: (state, action:PayloadAction<LessonsSliceType>) => {
            const { data: storedLessons } = state.lessons
            const { data: newLessons } = action.payload.lessons
            const lessonIds = storedLessons.map(lesson => lesson.id)
            const filteredDuplicates = newLessons.filter(lesson => !lessonIds.includes(lesson.id)) 
            filteredDuplicates.map(lesson => state.lessons.data.push(lesson))
        },
        addLesson: (state, action:PayloadAction<Lesson>) => {
            state.lessons.data.unshift(action.payload)
        },
        updateLessonInfo: (state, action:PayloadAction<Lesson>) => {
            const filteredLessons = state.lessons.data.filter(lesson => lesson.id !== action.payload.id)
            state.lessons.data = [ action.payload, ...filteredLessons ]
        },
        deleteLessonWithId: (state, action:PayloadAction<string>) => {
            const filteredLessons = state.lessons.data.filter(lesson => lesson.id !== action.payload)
            state.lessons.data = filteredLessons
        }
    }
})

export const lessonsSelector = (state: RootState) => state.lessons
export const {
    clearLessons,
    setInitialLessons,
    setLessons,
    addLesson,
    updateLessonInfo,
    deleteLessonWithId,
} = lessonsSlice.actions
export default lessonsSlice.reducer