import { domAnimation, LazyMotion } from 'framer-motion'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { clearFeedbacks, setInitialUserFeedbacks, setUserFeedbacks, userFeedbacksSelector } from '../../../features/Users/Users/userFeedbacksSlice'
import { userSelector } from '../../../features/Users/Users/userSlice'
import { useLazyGetUserFeedbacksQuery } from '../../../services/UserService'
import { CardError, CardLoading, CardLogo, WHMDetailTitle } from '../../atoms/atoms'
import { FeedbackField } from '../../molecules/molecules'

const FeedbackTab = () => {
  const perPage = 10
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ isLoadMore, setIsLoadMore ] = useState(false)
  const [ hasMore, setHasMore ] = useState(true)

  const { id } = useWHMSelector(userSelector)
  const feedbacksState = useWHMSelector(userFeedbacksSelector)
  const dispatch = useWHMDispatch()
  const [ getUserFeedbacks, { isLoading, isError, isFetching } ] = useLazyGetUserFeedbacksQuery()

  useEffect(() => {
    dispatch(clearFeedbacks())
    setCurrentPage(0)
    loadMoreItems(10, 0)
  },[id])

  const fetchMoreData = () => {
    if (!isLoadMore) loadMoreItems(perPage, currentPage)
  }

  const loadMoreItems = async (perPage: number, currentPage: number) => {
    if (!isLoadMore) setIsLoadMore(true)
    try {
      const feedbacks = await getUserFeedbacks({
        currentPage,
        perPage,
        userId: id
      }).unwrap()
  
      if (!feedbacks || !feedbacks.data.length) {
        setHasMore(false)
      } else {
        if (currentPage === 0) {
          dispatch(setInitialUserFeedbacks({ userFeedbacks: feedbacks }))
          setHasMore(true)
          setCurrentPage(1)
        } else if (feedbacks.pagination.lastPage === currentPage) {
          dispatch(setUserFeedbacks({ userFeedbacks: feedbacks }))
          setHasMore(false)
        } else {
          dispatch(setUserFeedbacks({ userFeedbacks: feedbacks }))
          setHasMore(true)
          setCurrentPage(prev => prev + 1)
        }
      }
      setTimeout(() => {
        setIsLoadMore(false)
      },1000)
    } catch (error) {
      console.log({error})
    }
  }

  const renderUserFeedbacks = () => {
    if (isLoading || isFetching) <CardLogo />
    if (isError)                 <CardError />
    if (!feedbacksState.userFeedbacks.data.length) return (
    <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
      No Feedback
    </div>
    )

    return (
      <LazyMotion features={domAnimation} key='feedback_card'>
        <div className='grid grid-cols-2 gap-2'>
        {feedbacksState.userFeedbacks.data.map((feedback, index) => (
          <FeedbackField 
            title={feedback.userProgram.program.title}
            completedAt={feedback.userProgram.finishDate}
            programRate={feedback.programRate}
            programFeedback={feedback.programFeedback}
            consistentRate={feedback.consistentRate}
            challengingFeedback={feedback.challengingFeedback}
            trainingFeedback={feedback.trainingFeedback}
            key={index}
          />
        ))}
        </div>
      </LazyMotion>
    )
  }

  return (
    <div className='w-full p-3'>
      <WHMDetailTitle 
        title='Program Feedback'
        details="View all feedback from this user's program experience."
        margin='mb-1'
      />
      <div className='w-full h-screen overflow-y-auto overflow-x-hidden pb-[17em]'>
        <InfiniteScroll
          loadMore={fetchMoreData}
          hasMore={hasMore}
          useWindow={false}
          loader={<CardLoading key={0}/>}
          initialLoad={true}
          threshold={200}
        >
          { renderUserFeedbacks() }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default FeedbackTab