import { useMemo, useState } from 'react'
import { WHMSearchbar, WHMTitleBlock } from '../../atoms/atoms'
import { debounce } from 'lodash'
import ReportsTitles from '../../../features/Community/Reports/ReportsTitles'

const ReportsList = () => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])

  return (
    <>
      <WHMTitleBlock title='Reports' />
      <WHMSearchbar onChange={onChangeText}/>
      <ReportsTitles searchText={searchText}/>
    </>
  )
}

export default ReportsList