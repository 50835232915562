import { ReactElement } from 'react'
import { RFC } from '../../../types/propTypes'
import { WHMLoading } from '../atoms'

type ButtonProp = {
    text: string
    leftIcon?: ReactElement
    rightIcon?: ReactElement
    disabled?: boolean
    isLoading?: boolean
    className?: string
    onSubmit: () => void
}

const WHMButton:RFC<ButtonProp> = ({
    text,
    leftIcon,
    rightIcon,
    disabled,
    isLoading,
    className,
    onSubmit
}) => {
    return (
        <>
          <button 
            type="button" 
            data-mdb-ripple="true" 
            data-mdb-ripple-color="light" 
            data-mdb-ripple-duration="1s"
            className={className}
            disabled={disabled}
            onClick={onSubmit}
          >
            {isLoading ? (
              <div
                className='flex justify-center items-center w-full text-xl px-4 py-2'>
                <WHMLoading shape='dots'/>
              </div>
            ) : (
            <>
              { leftIcon }
              { text }
              {rightIcon && (
                <div className='ml-1'>
                  { rightIcon }
                </div>
              )}
            </>
            )}
          </button>
        </>
    )
}

export default WHMButton