import { WHMApi } from '../app/api'
import { PaginationPayload, ProgramFeedbackData, ProgramFeedbacksData } from '../types/serviceTypes'

export const feedbackApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        getFeedbacks: builder.query<ProgramFeedbacksData, PaginationPayload>({
            query: ({ currentPage, perPage, filter }) => ({
                url: '/admin/users/programs/feedbacks',
                params: { currentPage, perPage, filter }
            }),
            providesTags: ['Feedback']
        }),
        getFeedback: builder.query<ProgramFeedbackData, string>({
            query: (userProgramId) => `/admin/users/programs/feedbacks/${userProgramId}`,
            providesTags: ['Feedback']
        })
    })
})

export const {
    useLazyGetFeedbacksQuery,
    useLazyGetFeedbackQuery,
} = feedbackApi