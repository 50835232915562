const binIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="delete_black_24dp_1_" 
            data-name="delete_black_24dp (1)" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '16'}
            height={height ?? '16'}
            viewBox="0 0 16 16"
        >
            <path id="Path_105" data-name="Path 105" d="M0,0H16V16H0Z" fill="none"/>
            <path id="Path_106" data-name="Path 106" d="M8,9h6v7.5H8Z" transform="translate(-3 -3.536)" fill="#d6a156" opacity="0"/>
            <path id="Path_107" data-name="Path 107" d="M11.375,3.639,10.768,3H7.732l-.607.639H5V4.917h8.5V3.639ZM5.607,13.222A1.251,1.251,0,0,0,6.821,14.5h4.857a1.251,1.251,0,0,0,1.214-1.278V5.556H5.607ZM6.821,6.833h4.857v6.389H6.821Z" transform="translate(-1.25 -0.75)" fill="#fff"/>
        </svg>
      </div>
    )
  }
  
  export default binIcon