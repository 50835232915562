import { useEffect } from 'react'
import classes from '../../assets/styles/snackbar.module.css'
import { RFC } from '../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../app/hooks'
import { closeSnackbar, snackbarSelector } from '../../features/Snackbar/snackbarSlice'
import { SNACKBAR_TYPES } from '../../assets/data/enums'
import { IoClose as CloseIcon } from 'react-icons/io5'
import { BiErrorCircle as SnackbarError } from 'react-icons/bi'
import { 
    AiOutlineWarning as SnackbarWarning,
    AiOutlineInfoCircle as SnackbarInfo, 
    AiOutlineCheckCircle as SnackbarSuccess,
} from 'react-icons/ai'

type WHMSnackbarProps = {
    position: 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right'
}

const WHMSnackbar:RFC<WHMSnackbarProps> = ({ position }) => {
    const snackbarState = useWHMSelector(snackbarSelector)
    const dispatch = useWHMDispatch()
    const {
        isDisplayed, snackbarType, snackbarMessage,
        snackbarTimeout, snackbarBtnText, snackbarBtnFunc
    } = snackbarState
    const TIME = `${(snackbarTimeout - 500) / 1000}s`

    useEffect(() => {
        if (isDisplayed) {
            const TIMER = setTimeout(() => {
                dispatch(closeSnackbar())
            }, snackbarTimeout)
            return () => clearTimeout(TIMER) 
        }
    },[isDisplayed])

    const getSnackbarIcons = () => {
        switch (snackbarType) {
            case SNACKBAR_TYPES.ERROR:
                return <SnackbarError className='min-w-[1.6em] h-full mr-4 text-white' />
            case SNACKBAR_TYPES.WARNING:
                return <SnackbarWarning className='min-w-[1.6em] h-full mr-4 text-white' />
            case SNACKBAR_TYPES.INFO:
                return <SnackbarInfo className='min-w-[1.6em] h-full mr-4 text-white' />
            case SNACKBAR_TYPES.SUCCESS:
                return <SnackbarSuccess className='min-w-[1.6em] h-full mr-4 text-white' />
            default:
                return
        }
    }

    return (
        <div
        className={`${classes.snackbar_container} ${isDisplayed && classes.snackbar_show} ${classes[position]} ${classes[snackbarType]} sm:w-[400px]`}
        style={{ animationDelay: `0s, ${TIME}` }}
        >
        {getSnackbarIcons()}
        <p className='pr-3 text-base text-white'>
            {snackbarMessage}
        </p>
        <button
            data-mdb-ripple='true'
            data-mdb-ripple-color='light'
            className='px-4 py-2.5 text-sm bg-WHMRed flex justify-center items-center  
            text-WHMWhite rounded-[6px] hover:bg-[#E33B45]/60 font-bold'
            onClick={snackbarBtnFunc}
        >
            {snackbarBtnText}
        </button>
        <CloseIcon
            className='p-2 rounded-[50%] min-w-[40px] min-h-[40px] ml-4 fill-white cursor-pointer hover:bg-WHMRed'
            onClick={() => dispatch(closeSnackbar())}
        />
        </div>
    )
}

export default WHMSnackbar