import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { programSelector, selectProgramWithId } from '../../../features/Training/Programs/programSlice'
import { programsSelector } from '../../../features/Training/Programs/programsSlice'
import { Programs } from '../../../types/stateTypes'
import { CardError, CardLogo, TitleCard, WHMStatus } from '../../atoms/atoms'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'

type ProgramTitlesProps = {
    isLoading: boolean
    isError:   boolean
    onRemoveNew: () => void
}

const ProgramTitles:RFC<ProgramTitlesProps> = ({
    isLoading,
    isError,
    onRemoveNew
}) => {
    const programState = useWHMSelector(programSelector)
    const programsState = useWHMSelector(programsSelector)
    const dispatch = useWHMDispatch()

    const handleClickCard = (item: Programs) => {
        onRemoveNew()
        dispatch(selectProgramWithId(item.id))
    }

    if (isLoading) return <CardLogo />
    if (isError)   return <CardError />
    if (!programsState.programs.data.length) return (
        <div className='w-full h-[5em] text-WHMDark text-lg flex justify-center items-center px-7'>
            No Program
        </div>
    )

    return (
        <LazyMotion features={domAnimation} key='program_card'>
            {programsState.programs.data.map((item, index) => (
                <TitleCard
                    key={index}
                    isSelected={programState.program.id === item.id}
                    onClick={() => handleClickCard(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                            <p className='truncate text-2xl font-bold'>{item.title}</p>
                            <div className='flex items-center'>
                                <WHMStatus 
                                    status={
                                        item.isActive === 1 ||
                                        item.isActive === true
                                    ? true 
                                    : false
                                    } 
                                />
                                {!item?.programSuggestGoal?.length ? (<p>{'none'}</p>) : (
                                    item.programSuggestGoal.map((goal, index) => (
                                        <p 
                                            key={index}
                                            className='truncate'
                                        >
                                            {goal.suggestGoalName}
                                        </p>
                                    ))
                                )}
                            </div>
                        </div>
                        <ArrowRightIcon 
                            width={18}
                            height={18}
                        />
                    </div>
                </TitleCard>
            ))}
        </LazyMotion>
    )
}

export default ProgramTitles