import { WHMApi } from '../app/api'
import { LoginArgs, LoginData } from '../types/serviceTypes'

export const adminApi = WHMApi.injectEndpoints({
    endpoints: (builder) => ({
        adminLogin: builder.mutation<LoginData, LoginArgs>({
            query: ({ email, password }) => ({
                url: '/auth/login/email',
                method: 'POST',
                body: {
                    email,
                    password
                },
            })
        }),
        getMe: builder.query<LoginData, void> ({
            query: () => '/users'
        })
    })
})

export const { useAdminLoginMutation, useLazyGetMeQuery } = adminApi