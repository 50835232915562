import { useState } from 'react'
import { RFC } from '../../types/propTypes'
import moment from 'moment'
import { WHMReplyInput } from '../atoms/atoms'

type SupportTicketFieldProps = {
    sentOn:    Date   | undefined
    toppic:    string | undefined
    note:      string | undefined
    repliedOn: Date   | undefined
    reply:     string | undefined
    onSubmitReply?: (text: string) => void
}

const SupportTicketField:RFC<SupportTicketFieldProps> = ({
    sentOn,
    toppic,
    note,
    repliedOn,
    reply,
    onSubmitReply,
}) => {
    const [ replyText, setReplyText ] = useState('')
    
    const formatDate = (date: Date | undefined) => {
        if (!date) return 'NA'
        const formattedDate = moment(date).format('DD-MM-YYYY')
        return formattedDate
    }

    const renderReplyButton = () => {
        if (!onSubmitReply) return
        return (<WHMReplyInput 
            replyText={replyText}
            setReplyText={setReplyText}
            onSubmit={(text) => onSubmitReply(text)}
        />)
    }

    const renderReplyDetails = () => {
        return (
            <div className='w-full border-t-[1px] border-solid border-WHMBorder pt-3'>
                <div className='w-full flex items-center'>
                    <p className='font-bold mr-2'>
                        Reply
                    </p>
                    <p>
                        Sent on: {formatDate(repliedOn)}
                    </p>
                </div>
                <div className='w-full my-2'>
                    <p className='text-WHMDark/80 text-lg leading-snug'>
                        {reply}
                    </p>
                </div>
                <div className='w-max py-2 px-3 bg-WHMBlue/25 rounded-md flex items-center'>
                    <p className='text-WHMBlue text-lg font-bold'>
                        Completed &nbsp;
                    </p>
                    <p>
                        {formatDate(repliedOn)}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div
            className='w-[27em] border-[1px] border-solid border-WHMBorder
            rounded-[10px] py-2 px-3'
        >
            <div className='w-full flex items-center'>
                <p className='font-bold mr-2'>
                    Original Ticket
                </p>
                <p>
                    Sent on: {formatDate(sentOn)}
                </p>
            </div>
            <div className='w-full my-2'>
                <p className='text-WHMPurple/80 text-2xl font-bold'>
                    {toppic}
                </p>
                <p className='text-WHMDark/80 text-lg leading-snug'>
                    {note}
                </p>
            </div>
            {(repliedOn && reply)
                ? renderReplyDetails() 
                : renderReplyButton()
            }
        </div>
    )
}

export default SupportTicketField