import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { 
  clearLesson, lessonSelector, refreshLessonMedia, selectLesson, 
  updateLessonImage, updateLessonOverview, updateLessonTitle, updateLessonVideo 
} from '../../../features/Resources/Lessons/lessonSlice'
import { useCreateLessonMutation, useDeleteLessonMutation, useLazyGetLessonQuery, useUpdateLessonMutation } from '../../../services/LessonService'
import { FOLDER_TYPE, MODAL_TYPES } from '../../../assets/data/enums'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import { addLesson, deleteLessonWithId, updateLessonInfo } from '../../../features/Resources/Lessons/lessonsSlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { WHMDetailInput, WHMDetailTitle, WHMTextArea, WHMTitleBlock, WHMUploadInput } from '../../atoms/atoms'
import { ImageSkeleton, InputSkeleton, StatusButtons, TextAreaSkeleton } from '../../molecules/molecules'
import DashboardDetails from '../../templates/DashboardDetails'

type LessonDetailsProps = {
  isNewLesson?: boolean
  setIsNewLesson?: (close: boolean) => void
}

const LessonDetails:RFC<LessonDetailsProps> = ({
  isNewLesson,
  setIsNewLesson
}) => {
  // Global States
  const { id, course, title, video, image, overview } = useWHMSelector(lessonSelector)
  const dispatch = useWHMDispatch()

  // Services
  const [ getLesson, { data: lessonData, isLoading: lessonLoading } ] = useLazyGetLessonQuery() 
  const [ createLesson ] = useCreateLessonMutation()
  const [ updateLesson ] = useUpdateLessonMutation()
  const [ deleteLesson ] = useDeleteLessonMutation()

  useEffect(() => {
    if (id) {
      dispatch(refreshLessonMedia())
      getLesson(id)
    }
  },[id])

  useEffect(() => {
    lessonData && dispatch(selectLesson({ id: lessonData.id, lesson: lessonData }))
  },[lessonData])

  const createNewLesson = async () => {
    const createLessonObj = {
      title: title ?? '',
      video: video ?? '',
      image: image ?? '',
      overview: overview ?? ''
    }

    try {
      dispatch(openOverlay({ text: 'Creating a new lesson' }))
      const [ data ] = await Promise.all([
        createLesson(createLessonObj).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data || !setIsNewLesson) throw Error
      setIsNewLesson(false)
      dispatch(addLesson(data))
      dispatch(selectLesson({ id: data.id, lesson: data }))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Lesson Created',
        body: 'You have successfully created a new lesson!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to create lesson',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }
  
  const saveLesson = async () => {
    try {
      dispatch(openOverlay({ text: 'Saving lesson details'}))
      const [ data ] = await Promise.all([
        updateLesson({ id, title, video, image, overview }).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(updateLessonInfo(data))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'Lesson Updated',
        body: 'Your changes have been successfully saved!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to update lesson',
        body: 'Please check your input details and try again.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  const clearNewLesson = () => {
    if (!setIsNewLesson) return
    setIsNewLesson(false)
  }

  const removeLesson = async (lessonId: string) => {
    try {
      dispatch(closeModal())
      dispatch(openOverlay({ text: 'Deleting this lesson'}))
      const [ data ] = await Promise.all([
        deleteLesson(lessonId).unwrap(),
        asyncTimeout(2000)
      ])
      if (!data) throw Error
      dispatch(clearLesson())
      dispatch(deleteLessonWithId(id))
      dispatch(openModal({
        modalType: MODAL_TYPES.SUCCESS,
        title: 'LESSON DELETED',
        body: 'You have successfully deleted the lesson!'
      }))
    } catch (error) {
      dispatch(openModal({
        modalType: MODAL_TYPES.FAIL,
        title: 'Failed to delete lesson',
        body: 'Something went wrong. Please try again later.'
      }))
    } finally {
      dispatch(closeOverlay())
    }
  }

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <WHMTitleBlock title={isNewLesson ? 'New Lesson' : `${title}`}/>
        {lessonLoading ? (<InputSkeleton />) : (
          <StatusButtons 
            type='ct-d-s'
            page='Course'
            title={course?.title}
            onSave={isNewLesson ? createNewLesson : saveLesson}
            onDelete={isNewLesson 
              ? clearNewLesson 
              : () => dispatch(openModal({
                modalType: MODAL_TYPES.DELETE,
                deleteId: id,
                deleteFn: removeLesson
              }))
            }
          />
        )}
      </div>

      <DashboardDetails template='grid-cols-2sm'>
      <>
          {/* Lesson Detail Left Side */}
          <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>
            
            <div className='pb-4'>
              <WHMDetailTitle 
                title='Title'
                details='Add a title that describes your lesson.'
                margin='mb-1'
                isRequired={isNewLesson ? true : false}
              />
              {lessonLoading ? (<InputSkeleton />) : (
                <WHMDetailInput 
                  placeholder='Title'
                  value={title ?? ''}
                  onChange={(text) => dispatch(updateLessonTitle(text))}
                />
              )}
            </div>

            <div className='pb-4'>
              <WHMDetailTitle 
                title='Media'
                details='Upload a video or image for this lesson. The video will automatically become the lessons thumbnail.'
              />
              {lessonLoading ? (<ImageSkeleton />) : (
                <WHMUploadInput 
                  type='video'
                  source={video ?? ''}
                  folder={FOLDER_TYPE.LESSON_MEDIA}
                  onUpload={(fileName) => dispatch(updateLessonVideo(fileName ?? ''))}
                />
              )}
            </div>

            <div className='pb-4'>
              <WHMDetailTitle 
                title='Image'
                details='Upload an image for this lesson’s thumbnail card.'
              />
              {lessonLoading ? (<ImageSkeleton />) : (
                <WHMUploadInput 
                  type='image'
                  source={image ?? ''}
                  folder={FOLDER_TYPE.LESSON_MEDIA}
                  onUpload={(fileName) => dispatch(updateLessonImage(fileName ?? ''))}
                />
              )}
            </div>
          </div>

          {/* Lesson Detail Right Side */}
          <div className='pl-2 pr-4'>
            <WHMDetailTitle 
              title='Lesson Overview'
              details='Create a lesson overview. Add in as much information as you would like in the overview. The more information, the more a user will understand a particular concept.'
              isRequired={isNewLesson ? true : false}
            />
            {lessonLoading ? (<TextAreaSkeleton />) : (
              <WHMTextArea 
                text={overview ?? ''}
                onChange={(text) => dispatch(updateLessonOverview(text))}
              />
            )}
          </div>
      </>
      </DashboardDetails>
    </>
  )
}

export default LessonDetails