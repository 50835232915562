import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { FOLDER_TYPE, MODAL_TYPES } from '../../../assets/data/enums'
import { exerciseFocusList } from '../../../assets/data/arrays'
import { 
    useCreateExerciseMutation, useDeleteExerciseMutation, 
    useLazyGetExerciseQuery, useUpdateExerciseMutation 
} from '../../../services/ExerciseService'
import { 
    addNewExerciseCue, addNewExerciseEquipment, exerciseSelector, 
    removeExerciseCue, removeExerciseEquipment, selectExercise, 
    selectExerciseFocus, updateExerciseTitle, updateExerciseVideo,
    clearExercise, removeAlternativeExercise, updateExerciseImage, refreshExerciseMedia
} from '../../../features/Training/Exercises/exerciseSlice'
import { closeModal, openModal } from '../../../features/Modal/modalSlice'
import { WHMDetailInput, WHMDetailTitle, WHMExerciseInput, WHMFocusButtons, WHMTitleBlock, WHMUploadInput } from '../../atoms/atoms'
import { DnDListSkeleton, ImageSkeleton, InputSkeleton, StatusButtons, TextAreaSkeleton } from '../../molecules/molecules'
import DashboardDetails from '../../templates/DashboardDetails'
import NonDndListField from '../../molecules/NonDndListField'
import { closeOverlay, openOverlay } from '../../../features/Modal/overlaySlice'
import asyncTimeout from '../../../utils/asyncTimeout'
import { addExercise, deleteExerciseWithId, updateExerciseInfo } from '../../../features/Training/Exercises/exercisesSlice'

type ExerciseDetailsProps = {
    isNewExercise?: boolean
    setIsNewExercise?: (close: boolean) => void
}

const ExerciseDetails:RFC<ExerciseDetailsProps> = ({
    isNewExercise,
    setIsNewExercise
}) => {

    // Global State
    const exerciseState = useWHMSelector(exerciseSelector)
    const { id, title, video, image, exerciseAlternatives } = exerciseState.exercise
    const dispatch = useWHMDispatch()

    // Services
    const [ getExercise, { data: exerciseData, isLoading: exerciseLoading } ] = useLazyGetExerciseQuery()
    const [ createExercise ] = useCreateExerciseMutation()
    const [ updateExercise ] = useUpdateExerciseMutation()
    const [ deleteExercise ] = useDeleteExerciseMutation()

    useEffect(() => {
        if (id) {
            dispatch(refreshExerciseMedia())
            getExercise(id)
        }
    },[id])

    useEffect(() => {
        exerciseData && dispatch(selectExercise({ id: exerciseData.id, exercise: exerciseData }))
    },[exerciseData])

    const createNewExercise = async () => {
        try {
            dispatch(openOverlay({ text: 'Creating a new exercise' }))
            const [ data ] = await Promise.all([
                createExercise(exerciseState).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data || !setIsNewExercise) throw Error
            setIsNewExercise(false)
            dispatch(addExercise(data))
            dispatch(selectExercise({ id: data.id, exercise: data }))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Exercise Created',
                body: 'You have successfully created a new exercise!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to create exercise',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const saveExercise = async () => {
        try {
            dispatch(openOverlay({ text: 'Saving exercise details' }))
            const [ data ] = await Promise.all([
                updateExercise(exerciseState).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(updateExerciseInfo(data))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'Exercise Updated',
                body: 'Your changes have been successfully saved!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to update exercise',
                body: 'Please check your input details and try again.'
            }))
        } finally {
            dispatch(closeOverlay())
        }
    }

    const clearNewExercise = () => {
        if (!setIsNewExercise) return
        setIsNewExercise(false)
    }

    const removeExercise = async (exerciseId: string) => {
        try {
            dispatch(closeModal())
            dispatch(openOverlay({ text: 'Deleting this exercise' }))
            const [ data ] = await Promise.all([
                deleteExercise(exerciseId).unwrap(),
                asyncTimeout(2000)
            ])
            if (!data) throw Error
            dispatch(clearExercise())
            dispatch(deleteExerciseWithId(id))
            dispatch(openModal({
                modalType: MODAL_TYPES.SUCCESS,
                title: 'EXERCISE DELETED',
                body: 'You have successfully deleted the exercise!'
            }))
        } catch (error) {
            dispatch(openModal({
                modalType: MODAL_TYPES.FAIL,
                title: 'Failed to delete exercise',
                body: 'Something went wrong. Please try again later.'
            }))   
        } finally {
            dispatch(closeOverlay())
        }
    }

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <WHMTitleBlock title={isNewExercise ? 'New Exercise' : `${title}`}/>
                {exerciseLoading ? (<InputSkeleton />) : (
                    <StatusButtons 
                        type='d-s'
                        onSave={isNewExercise ? createNewExercise : saveExercise}
                        onDelete={isNewExercise 
                            ? clearNewExercise 
                            : () => dispatch(openModal({
                                modalType: MODAL_TYPES.DELETE,
                                deleteId: id,
                                deleteFn: removeExercise
                            }))
                        }
                    />
                )}
            </div>
            <DashboardDetails template='grid-cols-3sm'>
            <>
                {/* Exercise Detail Left Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Title'
                            details='Add a title that describes your exercise.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<InputSkeleton />) : (
                            <WHMDetailInput 
                                placeholder='Title'
                                value={title ?? ''}
                                onChange={(text) => dispatch(updateExerciseTitle(text))}
                            />
                        )}
                    </div>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Video'
                            details='Upload a video of the exercise to help demonstrate the action to users.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<ImageSkeleton />) : (
                            <WHMUploadInput 
                                type='video'
                                source={video ?? ''}
                                folder={FOLDER_TYPE.EXERCISE_VIDEO}
                                onUpload={(fileName) => dispatch(updateExerciseVideo(fileName ?? ''))}
                            />
                        )}
                    </div>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Image'
                            details='Upload an image for this exercise’s thumbnail card.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<ImageSkeleton />) : (
                            <WHMUploadInput 
                                type='image'
                                source={image ?? ''}
                                folder={FOLDER_TYPE.EXERCISE_VIDEO}
                                onUpload={(fileName) => dispatch(updateExerciseImage(fileName ?? ''))}
                            />
                        )}
                    </div>
                </div>

                {/* Exercise Detail Middle Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Equipment'
                            details='Add in pieces of equipment that users might need for the exercise.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<InputSkeleton />) : (
                            <WHMExerciseInput 
                                type='equipment'
                                list={exerciseState.equipment}
                                placeholder='Type to Add Equipment'
                                onAddNewItem={(name) => dispatch(addNewExerciseEquipment(name))}
                                onRemoveItem={(name) => dispatch(removeExerciseEquipment(name))}
                            />
                        )}
                    </div>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Cues'
                            details='Add a description to this program to help users understand what this program will achieve.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<InputSkeleton />) : (
                            <WHMExerciseInput 
                                type='cue'
                                list={exerciseState.cues}
                                placeholder='Type to Add Cue …'
                                onAddNewItem={(description) => dispatch(addNewExerciseCue(description))}
                                onRemoveItem={(description) => dispatch(removeExerciseCue(description))}
                            />
                        )}
                    </div>
                </div>

                {/* Exercise Detail Right Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Focus'
                            details='Add in the muscle focusses for this exercise.'
                            margin='mb-1'
                            isRequired={isNewExercise ? true : false}
                        />
                        {exerciseLoading ? (<TextAreaSkeleton />) : (
                            <WHMFocusButtons 
                                list={exerciseFocusList}
                                selectedList={exerciseState.focus}
                                onSelect={(focus) => dispatch(selectExerciseFocus(focus))}
                            />
                        )}
                    </div>
                    <div className='pb-4'>
                        <WHMDetailTitle 
                            title='Alternatives'
                            details='Add in exercise alternatives. This allows the user to swap this exercise out for a similar exercise.'
                            margin='mb-1'
                        />
                        {exerciseLoading ? (<DnDListSkeleton />) : (
                            <NonDndListField 
                                list={exerciseAlternatives}
                                removeItem={(exerciseId) => dispatch(removeAlternativeExercise(exerciseId))}
                                openModal={() => dispatch(openModal({ 
                                    modalType: MODAL_TYPES.ALTERNATIVES 
                                }))}
                            />
                        )}
                    </div>
                </div>
            </>
            </DashboardDetails>
        </>
    )
}

export default ExerciseDetails