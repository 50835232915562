import { useNavigate } from 'react-router-dom'
import { useWHMDispatch, useWHMSelector } from '../../../app/hooks'
import { feedbackSelector, markAsSeen } from '../../../features/Notice/ProgramFeedback/feedbackSlice'
import { jumpToUserFeedBack } from '../../../features/Users/Users/userSlice'
import { WHMDetailTitle, UserCard, WHMButton } from '../../atoms/atoms'
import { FeedbackField } from '../../molecules/molecules'

const FeedbackDetails = () => {
    const navigate = useNavigate()
    const feedbackState = useWHMSelector(feedbackSelector)
    const dispatch = useWHMDispatch()
    const { 
        userProgram, programRate, programFeedback, 
        consistentRate, challengingFeedback, 
        trainingFeedback, isActive
    } = feedbackState.feedback

    const visitUserProfile = (userId: string | undefined) => {
      if (!userId) return
      navigate('/users-board')
      dispatch(jumpToUserFeedBack(userId))
    }

    const updateReadFeedback = () => {
        dispatch(markAsSeen(isActive === 1 ? 0 : 1))
    }

    return (
      <>
        <div className="w-full flex items-center">
          <div className="text-WHMDarkPurple font-bold text-[1.6em] max-w-max mr-4 px-3 py-2">
            {`${userProgram?.user.firstName} ${userProgram?.user.lastName}`}
          </div>
          <div className="text-WHMPurple font-medium text-[1.6em] max-w-max truncate px-3 py-2">
            {userProgram?.program.title}
          </div>
        </div>
        <UserCard
          avatar={userProgram?.user.avatar}
          firstName={userProgram?.user.firstName}
          lastName={userProgram?.user.lastName}
          btnFn={() => visitUserProfile(userProgram?.userId)}
        />
        <div className="p-3">
          <WHMDetailTitle
            title="Program Feedback"
            details="View feedback from this user's program experience."
            margin="mb-1"
          />
          <div className="w-[27em] h-screen overflow-y-auto overflow-x-hidden pb-[18em]">
            <FeedbackField
              title={userProgram?.program.title}
              completedAt={userProgram?.finishDate}
              programRate={programRate}
              programFeedback={programFeedback}
              consistentRate={consistentRate}
              challengingFeedback={challengingFeedback}
              trainingFeedback={trainingFeedback}
            />
            <WHMButton
                text={isActive === 1 ? "Mark As Unseen" : "Mark As Seen"}
                className='mark-button'
                onSubmit={updateReadFeedback}
            />
          </div>
        </div>
      </>
    )
}

export default FeedbackDetails