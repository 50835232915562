import { useState } from 'react'
import { useWHMSelector } from '../../app/hooks'
import { usersBoardTitles } from '../../assets/data/arrays'
import { USERS_BOARD_TITLES } from '../../assets/data/enums'
import { WHMEmptyDetailBox } from '../../components/atoms/atoms'
import { AllUsersList, UserDetails, UserStatisticsDetails } from '../../components/organisms/organisms'
import { DashboardLayout, DashboardTitles } from '../../components/templates/templates'
import { userSelector } from '../../features/Users/Users/userSlice'

const Users = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(USERS_BOARD_TITLES.ALL_USERS)
  const userState = useWHMSelector(userSelector)

  return (
    <DashboardLayout page='users'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Users'
            menu={usersBoardTitles}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
        {currentPage === USERS_BOARD_TITLES.USER_STATISTICS ? (
          <UserStatisticsDetails />
        ) : (
        <>
          <div className='dashboard-title-box'>
            <AllUsersList />
          </div>
          <div className='dashboard-detail-box'>
          {!userState.id 
          ? (<WHMEmptyDetailBox title='user' />) 
          : (<UserDetails />)}
          </div>
        </>
        )}
      </>
    </DashboardLayout>
  )
}

export default Users