import { useState } from 'react'
import { useWHMSelector } from '../../app/hooks'
import { courseIdSelector } from '../../features/Resources/Courses/courseSlice'
import { lessonIdSelector } from '../../features/Resources/Lessons/lessonSlice'
import { RESOURCES_BOARD_TITLES } from '../../assets/data/enums'
import { resourcesBoardTitles } from '../../assets/data/arrays'
import { CoursesList, LessonsList, LessonDetails, CourseDetails } from '../../components/organisms/organisms'
import { WHMEmptyDetailBox } from '../../components/atoms/atoms'
import { DashboardLayout, DashboardTitles} from '../../components/templates/templates'

const Resources = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(RESOURCES_BOARD_TITLES.COURSES)
  const courseId = useWHMSelector(courseIdSelector)
  const lessonId = useWHMSelector(lessonIdSelector)
  const [ isNewCourse, setIsNewCourse ] = useState(false)
  const [ isNewLesson, setIsNewLesson ] = useState(false)

  const renderList = () => {
    switch (currentPage) {
      case RESOURCES_BOARD_TITLES.COURSES:
        return <CoursesList 
          isNewCourse={isNewCourse}
          setIsNewCourse={setIsNewCourse}
        />
      case RESOURCES_BOARD_TITLES.LESSONS:
        return <LessonsList 
          isNewLesson={isNewLesson}
          setIsNewLesson={setIsNewLesson}
        />
      default:
        return
    }
  }

  const renderDetails = () => {
    switch (currentPage) {
      case RESOURCES_BOARD_TITLES.COURSES: 
        if (isNewCourse) 
          return <CourseDetails 
            isNewCourse={isNewCourse}
            setIsNewCourse={setIsNewCourse}/>
        if (!courseId) 
          return <WHMEmptyDetailBox title='course'/>
        else
          return <CourseDetails />
      case RESOURCES_BOARD_TITLES.LESSONS:
        if (isNewLesson) 
          return <LessonDetails 
            isNewLesson={isNewLesson}
            setIsNewLesson={setIsNewLesson}/>
        if (!lessonId)
          return <WHMEmptyDetailBox title='lesson'/>
        else 
          return <LessonDetails />
      default:
        return
    }
  }

  return (
    <DashboardLayout page='resources'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Resources'
            menu={resourcesBoardTitles}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
        <div className='dashboard-title-box'>
          { renderList() }
        </div>
        <div className='dashboard-detail-box'>
          { renderDetails() }
        </div>
      </>
    </DashboardLayout>
  )
}

export default Resources