import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialFeedbacksState } from '../../../assets/data/InitialState'
import { ProgramFeedbacksData } from '../../../types/serviceTypes'

export type FeedbacksSliceType = {
    feedbacks: ProgramFeedbacksData
}

export const feedbacksSlice = createSlice({
    name: 'feedbacks',
    initialState: InitialFeedbacksState,
    reducers: {
        clearFeedbacks: (state) => {
            state.feedbacks.data = []
        },
        setInitialFeedbacks: (state, action:PayloadAction<FeedbacksSliceType>) => {
            state.feedbacks.data = action.payload.feedbacks.data
        },
        setFeedbacks: (state, action:PayloadAction<FeedbacksSliceType>) => {
            const { data: storedFeedbacks } = state.feedbacks
            const { data: newFeedbacks } = action.payload.feedbacks
            const feedbackIds = storedFeedbacks.map(feedback => feedback.userProgramId)
            const filteredDuplicates = newFeedbacks.filter(feedback => !feedbackIds.includes(feedback.userProgramId))
            filteredDuplicates.map(feedback => state.feedbacks.data.push(feedback))
        }
    }
})

export const feedbacksSelector = (state: RootState) => state.feedbacks
export const {
    clearFeedbacks,
    setInitialFeedbacks,
    setFeedbacks,
} = feedbacksSlice.actions
export default feedbacksSlice.reducer