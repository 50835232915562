const draggableIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
        >
            <g id="drag_indicator_black_24dp" transform="translate(24 24) rotate(180)">
                <path id="Path_31" data-name="Path 31" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_32" data-name="Path 32" d="M4,14a2,2,0,1,1-2-2A2.006,2.006,0,0,1,4,14ZM2,6A2,2,0,1,0,4,8,2.006,2.006,0,0,0,2,6ZM2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0ZM8,4A2,2,0,1,0,6,2,2.006,2.006,0,0,0,8,4ZM8,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,8,6Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,8,12Z" transform="translate(7 4)"/>
            </g>
        </svg>
      </div>
    )
}
  
export default draggableIcon